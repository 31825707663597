import React, { useState, useEffect } from 'react'
import NavigationBar from './NavigationBar'
import { getCurrentUser , getUserInfo } from '../../services/userService'
import { Redirect } from 'react-router-dom'
import Workspace from './Workspace'
 

const Home = () => {
    const hour = new Date().getHours();
    const [firstname, setFirstname] = useState('') ; 

    

    if (!getCurrentUser().userId  ) return <Redirect to="/" /> ; 

    return (
        <div className="home-block">
            {/* <Sidepanel isOpenSidePanel={isOpenSidePanel}/> */}
            <div className="home-content">
                <NavigationBar 
                    head={`Good ${hour < 12 ? "Morning" : hour < 18 ? "Afternoon" : "Evening"}${firstname?`, ${firstname} `: ''}`} 
                    desc = {`Welcome to your AI-powered guide to Pfizer's medication portfolio, providing swift and accurate answers to drug-related queries`}
                />
                <div className="home-right">
                    <Workspace />
                </div>
            </div>
        </div>  
    )
}

export default Home
