import React, { useState } from 'react';
import './payment.css'; // Importing the CSS for styling

const PaymentForm = () => {
  const [formData, setFormData] = useState({
    amount: '',
    description: '',
    cardNumber: '',
    expMonth: '',
    expYear: '',
    cvc: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    })); 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data Submitted:', formData);
    // Here you would typically handle the form submission, like calling an API
  };

  return (
    <div className="payment-form-container">
      <form onSubmit={handleSubmit} className="payment-form">
        <label>
          Amount (EUR):
          <input
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Description:
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </label>
        <label>
          Card Number:
          <input
            type="text"
            name="cardNumber"
            maxLength="16"
            value={formData.cardNumber}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Expiration Month (MM):
          <input
            type="text"
            name="expMonth"
            maxLength="2"
            value={formData.expMonth}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Expiration Year (YY):
          <input
            type="text"
            name="expYear"
            maxLength="2"
            value={formData.expYear}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          CVC:
          <input
            type="text"
            name="cvc"
            maxLength="3"
            value={formData.cvc}
            onChange={handleChange}
            required
          />
        </label>
        <button type="submit">Submit Payment</button>
      </form>
    </div>
  );
};

export default PaymentForm;
