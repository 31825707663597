import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../asset/img/logo.svg";
import inputIcon from "../../asset/img/input.png";
import finetuneIcon from "../../asset/img/finetune.png";
import outputIcon from "../../asset/img/output.png";
import AppSetting from "../../asset/img/appsetting.svg";

import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ActionaBleIcon from "../../asset/img/actionableIcon.svg"
import BugIcon from "../../asset/img/bug.svg"
import UsrGuideIcon from "../../asset/img/userGuideIcon.svg"
import OutPutIcon from "../../asset/img/outPutIcon.svg"
import { ReactComponent as UploadDownIcon} from "../../asset/img/upload-down.svg"
import { ReactComponent as ParametersIcon } from "../../asset/img/parameters.svg"
import { ReactComponent as UploadIcon } from "../../asset/img/upload.svg"
import { ReactComponent as SettingsIcon } from "../../asset/img/settings.svg"

import { ReactComponent as SucceededIcon } from "../../asset/img/Succeeded.svg"
import { ReactComponent as FailedIconIcon } from "../../asset/img/FailedIcon.svg"
import { ReactComponent as InProgressIcon } from "../../asset/img/InProgress.svg"
import { ReactComponent as PlayIcon } from "../../asset/img/play.svg"
import PlusSmallIcon from "../../asset/img/plus-small-icon.svg"
import { Tooltip } from '@mui/material';

function DraggableMenuItem({ to, children , detail }) {

    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'submenuItem',
      item: { to , detail },
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    const draggingStyle = isDragging ? {
      transform: 'scale(1.2)',
      opacity: 0.7,
      cursor: 'grabbing',
      zIndex: 1000
    } : {};

    return (
      <div to={to} detail={detail} ref={drag} className="submenu-item" style={{ ...draggingStyle }}>
        {children}

      </div>
    );
}

const SideBar = ({ selected , showStatus, setShowStatus }) => {
    const [firstname, setFirstname] = useState('');
    const [activeMenu, setActiveMenu] = useState('');

  
    useEffect(() => {
        // Simulated user info fetching
        setFirstname('M');
    }, []);

    const handleClickOutside = () => {
      setActiveMenu('');
    }

    useEffect(() => {
      document.querySelector('.react-flow').addEventListener('click', handleClickOutside);
    }, [])

    return (
        <DndProvider backend={HTML5Backend} showStatus= { showStatus } setShowStatus= { setShowStatus } >
          {/* <div className='topBtn'>
            <button className='btn saveBtn'>Save</button>
            <button className='btn runBtn'><PlayIcon /> Run</button>
          </div> */}
          <ul className='m-3 p-1 menuItems'>
              <li className={`item ${activeMenu === "Input" ? 'active' : null}`} onClick={() => setActiveMenu('Input')}>
                <Tooltip title="Input" placement="right" arrow>
                  <div className='menuIcon'>
                    <UploadDownIcon />
                  </div>
                </Tooltip>
                {
                  activeMenu === "Input" && <div className='innerItems'>
                  <li className='Additem'>
                    <Link to={'/loop/input'} target="_blank">
                      <img src={PlusSmallIcon} alt="PlusSmallIcon" />
                      <span>Add</span>
                    </Link>
                  </li>
                  <div className='inMenu'>
                    <DraggableMenuItem
                      detail={{
                        id: '1',
                        type: 'input',
                        data: {
                          title : 'https://yourstore.com/', 
                          label: 
                            <div className='itemStatus'>
                              <div className='inneritem input-connector uploadDownIcon'>
                                <UploadDownIcon />
                                <span className=""> Rest API</span>
                                <span class="menu-item-subtitle" style={{paddingLeft: '40px'}}> https://api.texti.ai/ </span>   
                              </div>
                              {/* { showStatus === !true &&  <div className='status succeeded'><SucceededIcon /> Succeeded</div> }        */}
                            </div>,
                          name: 'https://api.texti.ai/', 
                          status: "initialize", 
                          classes: "input-connector"
                        },
                        position: { x: 500, y: 100 },
                    }}>
                      <div className='inneritem input-connector'>
                          Rest API <br />
                          <span class="menu-item-subtitle"> https://api.texti.ai/  </span>
                      </div>
                    </DraggableMenuItem>
                    <DraggableMenuItem
                       detail={{
                         id: '15',
                         type: 'input',
                         data: {
                            label: 
                            <div className='itemStatus'>
                            <div className='inneritem input-connector uploadDownIcon'>
                              <UploadDownIcon />
                              <span className=""> Webhook </span>
                              <span class="menu-item-subtitle" style={{paddingLeft: '40px'}}> Typeforms Webhook </span>   
                            </div>
                            { showStatus === true &&  <div className='status succeeded'><SucceededIcon /> Succeeded</div> }       
                          </div>,
                         name: "Typeforms Webhook",
                         classes: "finetunes-available"
                         },
                         position: { x: 600, y: 200 },
                       }}
                    >
                      <div className='inneritem input-connector'>
                           Webhook <br />
                          <span class="menu-item-subtitle"> Typeforms Webhook </span>
                      </div>
                    </DraggableMenuItem>
                    <DraggableMenuItem
                      // detail={{
                      //   id: '16',
                      //   type: 'input',
                      //   data: {
                      //     label: 
                      //       <div className='inneritem output-connector uploadDownIcon'>
                      //         <UploadDownIcon />
                      //         <span className="mt-2">output-connector </span>
                      //       </div>
                      //   name: "output-connector",
                      //   classes: "output-connector"
                      //   },
                      //   position: { x: 700, y: 300 },
                      // }}
                    >
                      <div className='inneritem input-connector'>
                         Custom Input
                         <span class="menu-item-subtitle"> Salesforce Marketing Cloud </span> 
                      </div>
                    </DraggableMenuItem>
                  </div>
                </div>
                }
              </li>
              <li className={`item ${activeMenu === "fineTunes" ? 'active' : null}`} onClick={() => setActiveMenu('fineTunes')}>
                <Tooltip title="Fine-tunes" placement="right" arrow>
                  <div className='menuIcon'>
                    <ParametersIcon />
                  </div>
                </Tooltip>
                {
                  activeMenu === "fineTunes" && <div className='innerItems'>
                  <li className='Additem'>
                    <Link to={'/loop/finetune'} target="_blank">
                      <img src={PlusSmallIcon} alt="PlusSmallIcon" />
                      <span>Add</span>
                    </Link>
                  </li>
                  <div className='inMenu'>
                    <DraggableMenuItem
                      detail= {{
                            id: '25',
                            type: 'default',
                            data: {
                              label: 
                              <div className='inneritem finetunes-available parametersIcon'>
                                <ParametersIcon />
                                <span className="mt-2"> Fine-tune </span>
                                <span class="menu-item-subtitle" style={{paddingLeft: '40px'}}> Feedback Classification </span>  
                              </div>,
                              name: "Classifying Feedback",
                              classes: "finetunes-available"
                            },
                            position: { x: 500, y: 400},
                      }}>
                      <div className='inneritem finetunes-available'>
                          Fine-tune
                         <span class="menu-item-subtitle"> Feedback Classification </span> 
                      </div>
                    </DraggableMenuItem>
                    <DraggableMenuItem
                      detail= {{
                            id: '20',
                            type: 'default',
                            data: {
                              label: 
                              <div className='inneritem finetunes-available parametersIcon'>
                                <ParametersIcon /> 
                                <span className="mt-2"> Fine-tune </span>
                                <span class="menu-item-subtitle" style={{paddingLeft: '40px'}}> Sentiment Classification </span>  
                                
                              </div>,
                              name: "Classifying Sentiment",
                              classes: "finetunes-available"
                            },
                            position: { x: 600, y: 500},
                      }}>
                      <div className='inneritem finetunes-available'>
                          Fine-tune
                         <span class="menu-item-subtitle"> Sentiment Classification </span> 
                      </div>
                    </DraggableMenuItem>
                    <DraggableMenuItem
                      detail= {{
                        id: '2',
                        type: 'default',
                        data: {
                          label: 
                          <div className='inneritem finetunes-available parametersIcon'>
                            <ParametersIcon />
                            <span className="mt-2"> Fine-tune  </span>
                            <span class="menu-item-subtitle" style={{paddingLeft: '40px'}}> Legal Q&A </span>
                          </div>,
                          name: "Answering Legal Questions",
                          classes: "finetunes-available"
                        },
                        position: { x: 700, y: 600 },
                      }}
                      >
                      <div className='inneritem finetunes-available'>
                         AI Copilot
                         <span class="menu-item-subtitle"> Legal Q&A </span> 
                      </div>
                    </DraggableMenuItem>
                  </div>
                </div>
                }
              </li>
              <li className={`item ${activeMenu === "Output" ? 'active' : null}`} onClick={() => setActiveMenu('Output')}>
                <Tooltip title="Output" placement="right" arrow>
                  <div className='menuIcon'>
                    <UploadIcon />
                  </div>
                </Tooltip>
                {
                  activeMenu === "Output" && <div className='innerItems'>
                  <li className='Additem'>
                    <Link to={'/loop/output'} target="_blank">
                      <img src={PlusSmallIcon} alt="PlusSmallIcon" />
                      <span>Add</span>
                    </Link>
                  </li>
                  <div className='inMenu'>

                    <DraggableMenuItem to={"/loop/output/email"}
                      detail= {{
                        id: '3',
                        type: 'output',
                        data: {
                          label: 
                          <div className='inneritem output-connector uploadIcon'>
                            <UploadIcon />
                            <span className="mt-2">Email</span>
                            <span class="menu-item-subtitle" style={{paddingLeft: '40px'}}> john@yourstore.com </span>
                          </div>,
                          name: "Email",
                          classes: "output-connector"
                        },
                        position: { x: 500, y: 600 },
                      }}  
                    >    
                        <div className='inneritem output-connector'>
                          Email 
                         <span class="menu-item-subtitle"> john@yourstore.com </span> 
                        </div>

                      </DraggableMenuItem>
                      <DraggableMenuItem to={"/loop/output/whatsApp"}
                        detail= {{
                          id: '27',
                          type: 'output',
                          data: {
                            label: 
                            <div className='inneritem output-connector uploadIcon'>
                              <UploadIcon />
                              <span className="mt-2">WhatsApp</span>
                              <span class="menu-item-subtitle" style={{paddingLeft: '40px'}}> Twilio +49 176***09143 </span>
                            </div>,
                            name: "WhatsApp",
                            classes: "output-connector"
                          },
                          position: { x: 600, y: 500 },
                        }}  
                      >
                        <div className='inneritem output-connector'>
                          WhatsApp
                          <span class="menu-item-subtitle"> Twilio +49 176***09143 </span> 
                        </div>
                      </DraggableMenuItem>
                      <DraggableMenuItem to={"/loop/output/webhooks"}
                        detail= {{
                          id: '29',
                          type: 'output',
                          data: {
                            label: 
                            <div className='inneritem output-connector uploadIcon'>
                              <UploadIcon />
                              <span className="mt-2"> Database </span>
                              <span class="menu-item-subtitle" style={{paddingLeft: '40px'}}> MS SQL Server </span>
                            </div>,
                            name: "Updating MS SQL Server",
                            classes: "output-connector"
                          },
                          position: { x: 700, y: 500 },
                        }}  
                      >
                        <div className='inneritem output-connector'>
                           Database
                          <span class="menu-item-subtitle"> MS SQL Server  </span> 
                          
                        </div>
                      </DraggableMenuItem>
                      <DraggableMenuItem to={"/loop/output/webhooks"}
                        detail= {{
                          id: '29',
                          type: 'output',
                          data: {
                            label: 
                            <div className='inneritem output-connector uploadIcon'>
                              <UploadIcon />
                              <span className="mt-2">Custom Output </span>
                              <span class="menu-item-subtitle" style={{paddingLeft: '40px'}}> MS Teams </span>
                            </div>,
                            name: "MS Teams",
                            classes: "output-connector"
                          },
                          position: { x: 300, y: 500 },
                        }}  
                      >
                        <div className='inneritem output-connector'>
                          Custom Output
                          <span class="menu-item-subtitle"> MS Teams </span> 
                        </div>
                      </DraggableMenuItem>
                  </div>
                </div>
                }
              </li>
              <li className='item'>
                <Tooltip title="App Settings" placement="right" arrow>
                  <Link to={''}>
                    <div className='menuIcon setting'>
                      <SettingsIcon />
                    </div>
                  </Link>
                </Tooltip>
              </li>
          </ul>
        </DndProvider>
    );
}

export default SideBar;
