import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import WorkOrderStepper from './stepper/workOrderStepper';
import { getOffer } from "../../../../services/staffingService";
import './worflowOfferStatus.css';  // Assuming CSS is external
import JobStreetView from "./jobStreetView"
import { AvatarGroup, Avatar , Badge , Button } from 'rsuite';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import DistanceMap from "../../staff/dashboard/DistanceMap"; 
// import DistanceMap from "./DistanceMapbox" ; 

function WorkOrderStatus() {
    const { offerId } = useParams();
    const [offer, setOffer] = useState({});
    const [negotiations, setNegotiations] = useState({});
    const [job, setJob] = useState({});
    const [allOffers, setAllOffers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [lastUpdated, setLastUpdated] = useState('');

    const [staff, setStaff] = useState({});

    useEffect(() => {
        fetchOfferData();
    }, [offerId]);

    async function fetchOfferData() {
        setLoading(true);
        try {
            const { data } = await getOffer(offerId);
            setOffer(data.offer);
            setNegotiations(data.offer.negotiations);
            setLastUpdated(new Date().toLocaleString());
            setJob(data.job)
            setAllOffers([...data.all_offers])
            setStaff(data.staff)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching offer data:', error);
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    function formatJobDateTime(job) {
        const date = new Date(job.customFields.workOrderDate);
        const friendlyDate = date.toLocaleDateString('en-US', {
            weekday: 'long', 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
        const [startTime, endTime] = job.customFields.shiftHours.split('-').map(time => `${parseInt(time)} Hrs`);
        return `${job.customFields.totalStaffsRequired} Staffs required on ${ friendlyDate } between ${startTime} to ${endTime}`;
    }

    return (
    <>
        {loading && <div className="offer-progress-loading">Loading...</div>}
        {error && <div className="offer-progress-error">{error}</div>}
        {Object.keys(offer).length > 0 && Object.keys(negotiations).length > 0 &&
            <div className="offer-staffing-app-container d-flex">
            <div className="offer-staffing-progress-container">
                <h1 className="offer-staffing-progress-title">Offer Progress</h1>
                <hr />
                <div className="offer-staffing-progress-details">
                    <Avatar circle bordered size="xs"  alt="Active Staff 1" src={`https://i.pravatar.cc/150?u=2`} className="mr-1" /> 
                    <p> { staff && `${staff.firstName} ${staff.lastName}`} </p>
                    <p>Offer ID: {offerId}</p>
                    <p>Updated: {lastUpdated}</p>
                </div>
                <WorkOrderStepper
                    offer={offer}
                    negotiations={negotiations}
                    setNegotiations={setNegotiations}
                    setOffer={setOffer}
                />
            </div>
            {
                Object.keys(job).length > 0 && Object.keys(staff).length > 0 &&
                <div className="offer-staffing-street-view-container">
                    {/* <JobStreetView job={job} /> */}
                    <DistanceMap source={staff.location} destination={job.location} />
                    <div className="staff-order-header d-flex justify-content-between mt-4">
                            <div className="staff-order-name"> { job.customFields.serviceType.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') } </div>
                            <div className="staff-order-id">#{job.customFields.orderId} </div>
                    </div>
                    <div className="staff-order-address"> { job.formatted_location } </div>
                    <div className="staff-order-total"> { job.customFields.customerName } </div>
                    <div className="staff-order-total">  { formatJobDateTime(job) } </div>
                    
                    <div className="staff-order-total"> 
                            <Link to={`/staffing/staff-allocation/dashboard/assign/${job.customFields.orderId}?view=recommended`} style={{color: '#db2777'}}>
                                Invite 4 more 
                            </Link>
                            — 56% acceptance rate in this area.
                    </div>
                    <hr />
                    {
                        allOffers.length >= 1 &&
                        <div className="offer-staffing-status mt-2">
                            <div className="offer-staffing-status-item">
                                <Badge content={ allOffers.length } color="green"><span className="pr-2">Active offers sent to other security staffs nearby </span></Badge>
                                <AvatarGroup className="mt-2" spacing={6}>
                                    {
                                        allOffers.map((offer, index) => {
                                            return <Avatar  style={offer._id === offerId ? { border: '2px solid green' } : {}} key={index} circle src={`https://i.pravatar.cc/150?u=${index}`} alt="Active Staff 3" />
                                            }   
                                        )
                                    }
                                </AvatarGroup>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
        
        }  
    </>
    );
}

export default WorkOrderStatus;
