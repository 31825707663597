import React, { useState, useEffect } from 'react'
import Sidebar from "./Sidebar/SideBar"
import Diagrams from './DragAndDrop/Diagrams';
import NavigationBar from '../homeLoggedIn/NavigationBar';


const Flow = () => {
    const hour = new Date().getHours();
    const [firstname, setFirstname] = useState('');
    const [showStatus, setShowStatus] = useState(false) ;
    const [ showLogs, setShowLogs ] = useState(false) ; 

    useEffect(() => {

        const userInfo = async () => {
            // let resp = await getUserInfo();
            // setFirstname(resp.data.firstname);
            // if DETAILS is not set to true then redirect to details page. 
            setFirstname('John')
        }
        userInfo();
    })

    return (
        <>
            <div className="home-content">
                <NavigationBar
                    head={`Good ${hour < 12 ? "Morning" : hour < 18 ? "Afternoon" : "Evening"}${firstname ? `, ${firstname} ` : ''}`}
                    desc='Welcome to Texti&apos;s AI Agents, automate any business process effortlessly.'
                />
                    <div className="d-flex">
                    { !showLogs && <Sidebar selected={'flow'} showStatus={showStatus} setShowStatus={setShowStatus}/>  }
                    <Diagrams showStatus={showStatus} setShowStatus={setShowStatus} showLogs={showLogs} setShowLogs={setShowLogs}/>
                </div>

            </div>
        </>
    )
}

export default Flow ; 