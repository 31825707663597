import http from "./http";

import apis  from "../config/apis.json";
const api = process.env.REACT_APP_ENVIRON === "development" 
               ? apis.dev
               : apis.prod 



export function addEntry() {

    const date= new Date(); 
    const today = date.setHours(0,0,0,0) ; 
    var entry = JSON.parse(localStorage.getItem("entry"));
    var newentry = { "ts" : today , val: 1 } ;

    if (entry == null || entry["ts"] !== today  ) {
        localStorage.setItem("entry", JSON.stringify(newentry));
        return 1; 
    } else {
        var append = { "ts" : entry["ts"] , val: entry["val"]+1 } ; 
        localStorage.setItem("entry", JSON.stringify(append));
        return entry["val"]+1 ; 
    }
};


export function getCopilotCompletion( prompt ){
    return http.post(`${api}/pfizer/copilot/completion`, {
        prompt: `${prompt}`,
        entry : addEntry() 
    });
}


// http://localhost:3000/demo/pfizer/copilot/completion

export async function getCopilotCompletionStream( prompt ){
    const headers = {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "x-auth-refineai": localStorage.getItem('x-auth-refineai'),
        "access-control-expose-headers": "x-auth-refineai"
    };

    console.log('api url completion +++++++', `${api}/pfizer/copilot/completion`)

    const response = await fetch(`${api}/pfizer/copilot/completion`, {
        method: "post",
        headers: headers,
        body: JSON.stringify({ prompt: prompt , entry : addEntry() }),
    });

    return response;
}

/*
export function getCopilotSuggestion(name, copilot_name){
    return http.post(`${api}/copilot/suggestions`, {
        data_name: name, 
        copilot_name: copilot_name
    });
}



export function getCopilotConversationHistory (data_name, copilot_name){
    return http.post(`${api}/copilot/conversation/history `, {
        data_name: data_name, 
        copilot_name: copilot_name 
    });
}
*/


