import React , { useEffect , useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom';
import FormControl from 'rsuite/esm/FormControl';
import RightArrow from '../asset/auth-icons/icons8-right-arrow-50 (2).png'
import Success from "../common/widgets/success-check"
import { useLocation , useHistory , Redirect } from "react-router-dom";
import { getVerifyConfirmation , updatePwd , loginWithJwt, login } from '../../services/userService';
import { Loader } from 'rsuite';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import NavBarAuth from "./NavbarAuth"


const schema = yup.object({
    password: yup
      .string()
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{12,}$/,
        'Password should be 12+ characters with at least one uppercase, one lowercase, one digit, and one special character.'
      )
      .required('Required')
}).required();

const Confirm = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({resolver: yupResolver(schema)});
    const [loading, setLoading] = useState(true)
    const [linkValid, setLinkValid ] = useState(false)
    const [ userDt, setUserDt ] = useState({
        email:"", 
        id:""
    })
    const { search } = useLocation() ; 
    const history = useHistory();
    const searchParams = new URLSearchParams( search ) ; 
    const token = searchParams.get("token") ;

    useEffect(() => {

        const confirmVerify = async () => {
            try {
                const resp = await getVerifyConfirmation(token)
                setLoading(true)
                setLinkValid(true)
                setUserDt({
                    email: resp.data.email , 
                    id: resp.data._id
                })
                setLoading(false)
                
            } catch (error) {
                setLoading(false)
            } 
        }

        confirmVerify() ;  

    } , [] );

    const onSubmit = async(data) => {

        try {
            setLoading(true)
            await updatePwd( userDt.id, userDt.email, data.password) ;
            await login(userDt.email, data.password)
            // loginWithJwt(resp.headers["x-auth-pers"]) 
            setTimeout(() => {
                //history.push({ pathname: '/user/form' })
                window.location.href = `/user/form`
            }, 1500);
            
        } catch (error) {
            setLoading(false)
        }
    };

    return (
        <>
            <NavBarAuth />
            <div id='signIn' className="signIn-Page">
                {
                    loading && <Loader />
                }
            {
                linkValid && !loading &&
                    <div className="signin-inner">
                        <Success />
                        <h5 className="mt-2 text-center"> Please set your pasword </h5>
                        <form className="mt-0" onSubmit={handleSubmit(onSubmit)}>
                            <div className='password-submit-block'>
                                <div className="input-icons mt-3">
                                <i className="fa fa-key icon"></i>
                                
                                    <input className="input-field password" 
                                    type="password"
                                        {...register("password", { required: true })}  placeholder='Password' 
                                    />
                                    {errors.password && <span className='errorTextForm'> {errors.password.message} </span>}
                                </div>
                                <button type="submit" className='submitbtn mt-3'>
                                    <i class="fa fa-arrow-right"></i>
                                </button>
                            </div>
                        </form>
                    </div>
            } 
            {
                !linkValid && !loading &&
                    
                    <div className="signin-inner">
                        <h3 className='mt-2 text-center expired-token'>
                            The link is expired. 
                        </h3>
                        <div className='sign-In-link-block text-center mt-5'>
                            <Link className="ml-5" to='/confirm/resend'> Resend email confirmation link ?</Link> <br />
                            {/* Forgot Your Password? <Link to='/forgotpassword'>Forgot Your Password?</Link> */}
                        </div>   
                    </div>
            }
            </div>
        </>
    )
}

export default Confirm ; 
