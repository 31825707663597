import React from 'react';
import MapSegments from "./mapSegments";
import { Link } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '@mui/icons-material/Home';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';

const VisitSiteCard = ({ segmentData }) => {
    return (
        <div className="location-planning-card mt-3">
            {/* <span className="location-planning-subtext">
                {`${segmentData.zipCount} ZIP Codes in the Segment of ${segmentData.description_persona.Title}`}
            </span> */}

            <div className="d-flex " style={{marginRight: '15px'}} >
                <span> 
                    <SettingsAccessibilityIcon style={{fontSize:'20px' , marginRight:'3px' , color: '#db2777'}} /> 
                </span>  
                 {`${segmentData.description_persona.Title} (${segmentData.zipCount} ZIP Codes)`} 
            </div> 


            {/* Uncomment and use below if there's a button you need to include later
            <a href="#" className="location-planning-button" target="_blank" rel="noopener noreferrer">
                {storeInfo.actionText}
            </a>
            */}

            
         
         </div>
    );
}


const ZipcodesResults = ({ zipcodeAreas, segmentData , activeZipcode, setActiveZipcode }) => {

    const handleZipcodeSelected = (area) => {
        setActiveZipcode(area)
      };

    return (
        <>
            <VisitSiteCard  segmentData={segmentData} />
            <div className="p-3">
                <input type="text" className="form-control location-planning-search-input" placeholder="Search" />
            </div>

            {/* add this button later  */}
            <hr />

            <div className="list-group list-group-flush location-planning-list-group">
                {zipcodeAreas.map(area => (
                    <div className="list-group-item location-planning-list-group-item" key={area.plz} onClick={() => handleZipcodeSelected(area)}  style={{ backgroundColor: activeZipcode.plz === area.plz ? '#e8f5e9' : '' }} >
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1 location-planning-store-name">{area.plz}, {area.city}  </h5>
                            <small className="text-muted location-planning-store-status"> { area.recommended === 'top' ? "Top Recommendation" : "" } </small>
                        </div>
                        <p className="mb-1 location-planning-store-address"> {area.state} </p>
                        <div className="d-flex mb-1 align-items-center">
                            <small className="store-services location-planning-store-services">  <PeopleIcon style={{fontSize: '16px'}}/> {(area.population).toLocaleString()} </small>
                            <small className="store-services location-planning-store-services ml-3"> <HomeIcon style={{fontSize: '16px'}}/> {(area.households.toLocaleString())} </small>
                            <small className="store-services location-planning-store-services ml-3" > <a style={{color:'#566492'}} href={`https://www.zipscore.ai/us/${area.plz}/zip-code/${area.lng}/${area.lat}?segment`} target="_blank" rel="noreferrer"> View Demographics </a> </small>
                        </div>
                        {/* Rating */}
                        <div className="store-rating location-planning-store-rating mt-3">
                            <Link to={`/zipscore/lookup/us/${area.plz}/${area.lat}/${area.lng}/places?segment=${segmentData.description_persona.Title}`}>
                                <span className="location-planning-store-rating-badge">  Locate Top Businesses </span>
                            </Link>
                            
                            {/* <span className="text-muted location-planning-store-rating-count">(24)</span> */}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

const AreasComponent = ({ areas, country , zipcodeAreas , segmentData , activeZipcode, setActiveZipcode }) => {

    return (
        <>
            {
                zipcodeAreas.length > 0 && Object.keys(segmentData).length >= 1 && <div className="container-fluid" style={{height: '100vh', overflow: 'hidden'}}>
                <div className='row h-100'>
                    
                    {/* Sidebar for List of Zipcodes */}
                    <div className="col-md-4 overflow-auto" >
                        <div className="list-group" style={{width: '100%'}}>
                            {/* Here goes the list of zipcodes. Use list-group-item for each entry */}
                            {/* Example */}
                            {/* <a href="#" className="list-group-item list-group-item-action">Zipcode 1</a> */}
                            {/* Populate list items here based on your data */}
                            <ZipcodesResults 
                                zipcodeAreas={zipcodeAreas} 
                                segmentData={segmentData} 
                                activeZipcode={activeZipcode}
                                setActiveZipcode={setActiveZipcode}
                            />
                        </div>
                    </div>

                    <div className="col-md-8 h-100">
                        <MapSegments 
                         areas={areas} 
                         country={country} 
                         activeZipcode={activeZipcode}
                         setActiveZipcode={setActiveZipcode}
                        />
                    </div>
                </div>
            </div>
            }
        </>
    )
}


export default AreasComponent;
