import React, { useState , useEffect } from 'react'
import NavBar from "./NavigationBar"
import { getCurrentUser } from "../../services/userService";
import { Avatar, Loader  } from 'rsuite';
// import { getAllUsers } from '../../services/clientService';
// import { createCheckoutSession } from '../../services/checkoutService';
import { useForm } from 'react-hook-form' ; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import warningIcon from "../asset/img/warning.svg"
import { getClientInfo, revokeProviderKey, updateProvider } from '../../services/clientService';
import ReactModal from "react-modal" ; 
import styles1 from "./styles/modal.module.css"
import toast from 'react-hot-toast';

const schema = yup.object({
    apikey: yup.string().required('API Key is required').min(32, 'API Key must be at least 32 characters').max(120, 'API Key cannot exceed 120 characters'),
    orgId: yup.string().required('Organization ID is required').min(20, 'Organization ID must be at least 20 characters').max(120, 'Organization ID cannot exceed 120 characters')
}).required();

const Account = () => {

const [user, setUser] = useState({ username: "", email: "", isAdmin: true }); 
const [apiKey, setApiKey] = useState(false); 
const [open, setOpen] = React.useState(false);
const [loading, setLoading] = useState(true);
const [err, setErr]= useState('') ; 

const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
});

useEffect( () => {

    async function getUser() {
        const dt = await getCurrentUser()
        setUser({ ...dt})
        const resp = await getClientInfo()
        if (resp.data === 'exists'){
            setApiKey(true)
        }
        setLoading(false)
    }

    getUser();
    
}, [] );

/*
const handleCheckoutSession = async() => {
    const returnUrl = window.location.href ; 
    const res = await createCheckoutSession(returnUrl) ;
    const sessionUrl = res.data.url ; 

    window.location.href= sessionUrl ;
}
*/

const onSubmit = async (data)=> {

    try {
        const resp= await updateProvider('openai', data.apikey, data.orgId )
        if (resp.status === 200) {
          setApiKey(true) 
        }
        return ; 
        
    } catch (ex) {
        if (ex.response && ex.response.status === 404){
            setErr(ex.response.data)
            toast.error(ex.response.data)
        }
        return;    
    }

  }

  const handleRevoke = async () => {

    const resp= await revokeProviderKey('openai'); 
    if (resp.status===200){
        setApiKey(false)
        setOpen(false)
    }
    return; 

  };
  const handleOpen = () => setOpen(true);
  const handleClose = () =>setOpen(false) ;

  return (
        <>
            {
                !apiKey && 
                <div className="no-api-keys-sticky-warning-message text-center">
                <div className="alert alert-info" role="alert" style={{ marginBottom: 0 }}>
                    <span className="">
                        👉 &nbsp;&nbsp;&nbsp;
                        <strong>
                            <a href="https://docs.texti.ai/how-to-get-an-openai-api-key-and-organization-id" target="_blank" className="underline-link" rel="noreferrer">
                                &nbsp;Read here:&nbsp;
                                &nbsp;How to Get an OpenAI API Key & Organization ID?
                            </a>
                        </strong>
                    </span>
                </div>
                </div>
            }

            <NavBar 
                head={'Account Settings'}
                desc={'Manage your subscription and API keys.'}
            />

            <div className="container-fluid">
                
                <div className="row">
                    <div className="col-md-3 mt-5">
                    <div className="text-center align-items-center mt-5">
                        <div className="avatar-wrapper">
                        <Avatar circle style={{ background: '#004299' }} size="lg">
                            {user.email.slice(0, 2).toUpperCase()}
                        </Avatar>
                        </div>
                        <div className="mt-2">
                        {user.firstname} {user?.lastname} <br />
                        {user.email}
                        </div>
                        <div className="text-center align-items-center mt-4">
                        {/* <div>
                            <span
                            className="billing-section"
                            style={{ paddingBottom: '5px', borderBottom: '2px solid #0477F5', fontSize: '16px', cursor: 'pointer' }}
                            >
                            Billing
                            </span>
                        </div> */}
                        </div>
                    </div>
                    </div>
                    <div className="col-md-9 mt-5">

                        <form action="" onSubmit={handleSubmit(onSubmit)} >                    
                            <div className="api-keys-section">
                                <div className="api-section mt-3" >
                                    <div className="api-section-title">Provider</div>
                                    <input type="text" /*id="provider-input"*/ className="api-input-filled" value="Open AI" readOnly />
                                </div>
                                <div className="api-section">
                                    <div className="api-section-title mt-3">Organization ID</div>
                                    {  apiKey && ( <input className="api-input-filled masked-input "value="***********************************" readOnly style={{width:'50%'}} />)}
                                    { !apiKey && <input {...register("orgId" )} type="text" className="api-input-unfilled" style={{width:'50%'}} />}
                                    <div className=""> {errors.orgId && <span className='errorTextForm'> {errors.orgId.message} </span>} </div> 
                                </div>
                                <div className="api-section mt-3">

                                    <div className="api-section-title">API Key</div>
                                    <div className="input-container">
                                        {  apiKey && ( <input className="api-input-filled masked-input "value="***********************************" readOnly style={{width:'50%'}} />)}
                                        { !apiKey && <input {...register("apikey" )} type="password" className="api-input-unfilled" style={{width:'50%'}} />}
                                        <div className=""> {errors.apikey && <span className='errorTextForm'> {errors.apikey.message} </span>} </div> 
                                    </div>
                                    <div className="api-section mt-4">
                                        {  apiKey && <button  onClick={handleOpen} type="button" className="revoke-button ">Revoke</button>}
                                        { !apiKey && <button className="save-button " type= "submit" >Save</button>}
                                        { !apiKey && err && <span className="ml-2 errorTextForm"> { err } </span> }
                                    </div>

                                </div>
                                    
                            </div> 
                        </form>
                        <div className="ml-3 mt-4" style={{fontStyle: "12px"}}>
                            <strong> ** Rest assured, when you add your API key to your account, it is <br/> securely stored and encrypted for maximum protection. </strong>
                        </div>
                    </div>
                </div>
                
            </div>
            { open && 
                <ReactModal
                isOpen={open}
                className={styles1.Modal}
                overlayClassName={styles1.Overlay}
                onRequestClose={handleClose}
                ariaHideApp={false}
                >
                    <div className="revoke-modal-container p-3">
                        <div className="revoke-modal">
                            <div className="revoke-modal-header">
                                <h3 className="revoke-modal-title text-left">Confirm Revocation</h3>
                            </div>
                            <div className="revoke-modal-body">
                                <p>
                                    This API key will immediately be disabled. 
                                    Revoking the API key may lead to the termination or failure of the ongoing fine-tuning process. 
                                    Please carefully consider the consequences before proceeding with the revocation.
                                </p>
                            </div>
                            <div className="revoke-modal-footer mt-4">
                                <button onClick={handleRevoke} className="revoke-modal-button revoke-modal-confirm">
                                    Yes, revoke
                                </button>
                                <button onClick={handleClose} className="revoke-modal-button revoke-modal-cancel">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>        
                </ReactModal>
            }
            { loading && 
                <Loader 
                    backdrop 
                    center 
                    size="lg" 
                    //content={ loadingText } 
                    vertical 
                />
            }
        </>
    )
}

export default Account ;