import React, { useState , useEffect } from 'react';
import { useForm } from 'react-hook-form'; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Drawer , Button, Loader, Message } from 'rsuite';
import { useHistory, useParams } from 'react-router-dom';

import Editor from "@monaco-editor/react";

// import CheckCircleIcon from "../asset/img/Editicon.png"

const schema = yup.object({
    retries: yup.number().required('Maximum retries is required').default(2).positive().integer(),
    prompt_instruction: yup.string().required('Prompt instruction is required').default(''),
    validate_output: yup.string().required('Output validation selection is required'),
  }).required();


const Copilot = ({openDrawer, setOpenDrawer }) => {

  const { register, handleSubmit, watch, setValue,  formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [loading, setLoading]= useState(false);  
  const history= useHistory(); 
  const { name, task }= useParams(); 
  const [err, setErr]= useState(''); 
  const [errType , setErrType]= useState('')

  const [editorLanguage, setEditorLanguage] = useState("json");
  const [editorValue, setEditorValue] = useState("// Enter your code.");

  // When the component mounts, initialize the editorValue with the value from the form
useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'validate_output') {
        setEditorValue(value.validate_output || '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  

  const handleEditorChange = (value) => {
    setEditorValue(value);
    setValue('validate_output', value); // Update form state with the new editor value
  };

  const onSubmit = async (data) => {

      console.log('++++ set node setings such as max attempts, output schema, node name, output node etc. ++++++ ', data)
   /*
      try {
        
        setLoading(true)
        const settings= {...data }; 
     } catch (ex) {
         setLoading(false)
         if (ex.response && ex.response.status === 404){
          setErr(ex.response.data)
          setErrType('advance')
        }
    } 
        */
  };

  return <>
    <Drawer style={{width:'400px'}} id="docsDrawer" className='configure-sidebar justify-content-center' /*backdrop={true}*/ open={openDrawer} onClose={() => setOpenDrawer(false)}>
      { loading && 
        <Loader  
          style={{zIndex : 10 , marginBottom: "45px" }} 
          backdrop = {true}
          size="lg" 
          content={ 'Please wait. it can take few minutes to configure the finetuning' } 
          vertical 
        />
      }

     <Drawer.Body className='d-flex align-items-center'>

    { !loading &&  
        <div className='mb-5 w-100'>

        <form onSubmit={handleSubmit(onSubmit)} className='configure-form'>
        <div className='advanced-setting mt-5'>
          <label className='title mt-2'>Settings </label>
          {/* <div style={{fontSize:'12px'}}> <a href={`/finetune/training/cost/${name}/${task}`} target='_blank' rel="noreferrer" style={{textDecoration:'underline'}}> Fine-tuning Cost Calculator</a> </div>  */}
          <div className="" style={{fontSize:'12px'}}> <a href="https://docs.texti.ai/in-context-learning/hyperparameters" target='_blank' rel="noreferrer" style={{textDecoration:'underline'}}> Read more on Node types & settings.</a> </div>
            <div className="advanced-tab mt-4">
            </div>

            <div className='advanced-setting epochs'>
                    <div className="form-geoups">
                        <div className="form-group" style={{ width: '100%' }} >
                            <label className='title'>Maximum Retries </label>
                                <select
                                 name="retries"
                                id="retries"
                                {...register("retries")}
                                className='form-control'
                                defaultValue={"2"}
                                style={{fontSize: '8px !important'}}
                                >
                                <option value="2" > 2</option>
                                <option value="3" > 3</option>
                                <option value="4" > 4</option>
                                
                                </select>
                                {errors.batch_size && (
                                <span className='errorTextForm'>
                                    {errors.retries.message}
                                </span>
                                )}
                        </div>
                    </div>

                    <div className="form-geoups">                        
                        <div className="form-group" style={{ width: '100%' }}>
                                <label className='title'>Prompt Instruction</label>
                                <textarea
                                    {...register("prompt_instruction")}
                                    className='form-control'
                                    placeholder="Your special prompt instruction + The delivery was much slower than promised, and the packaging was slightly damaged."
                                />
                                {errors.prompt_instruction && (
                                    <span className='errorTextForm'>
                                    {errors.prompt_instruction.message}
                                    </span>
                                )}
                        </div>
                    </div>

                    
                    {/*  DATA TYPE VALIDATION OR TEXT VALIDATION ????  */}

                    <div className="form-group" style={{ width: '100%' }}>
                        <label className='title mb-3'>Output Validation Schema</label>
                        <select 
                            onChange={(e) => setEditorLanguage(e.target.value)} 
                            className='language-dropdown'
                        >
                            <option value="json">JSON</option>
                            <option value="javascript">JavaScript</option>
                            <option value="sql">SQL</option>
                            <option value="markdown">Markdown</option>
                            <option value="plaintext">Plain Text</option>
                            {/* add other options as needed */}
                        </select>
                        <Editor
                            height="200px"
                            width='340px'
                            language={editorLanguage}
                            value={editorValue}
                            onChange={handleEditorChange}
                            theme="vs-dark"
                            options={{
                            minimap: { enabled: false },
                            lineNumbers: "off",
                            roundedSelection: true,
                            scrollBeyondLastLine: false,
                            readOnly: false,
                            fontSize: 14
                            // Add more options if needed
                            }}
                            wrapperClassName="editor-wrapper"
                        />
                        {errors.validate_output && (
                            <span className='errorTextForm'>
                            {errors.validate_output.message}
                            </span>
                        )}
                    </div>

                    {/* <div className="form-geoups">
                        <div className="form-group" style={{ width: '100%' }}>
                            <label className='title'>Output Validation Schema </label>
                                <textarea
                                    {...register("validate_output")}
                                    className='form-control'
                                    placeholder="Enter validation schema in JSON format"
                                    style={{ height: '40px' }} // Adjust height as needed
                                />
                                {errors.validate_output && (
                                    <span className='errorTextForm'>
                                    {errors.validate_output.message}
                                    </span>
                                )}
                        </div>
                    </div> */}

                </div>
            </div>
        
            <div className="footer mt-5">
                { !err && <>
                <span className='btn cancel-btn' onClick={() => setOpenDrawer(false)}>cancel</span>
                <button type="submit" onClick={handleSubmit(onSubmit)} className='btn confirm-btn'>confirm</button>
            </>} 
            {err && errType==='advance' && <Message showIcon type="error" className="errorTextForm text-center"> <span dangerouslySetInnerHTML={{ __html: err }}/></Message>} 
            
            </div>
      </form>
      </div>
    }
      </Drawer.Body>
    </Drawer>
  
  
  
  </>
  
}

export default Copilot ; 