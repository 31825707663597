import React from 'react'
// import logo from "../asset/img/logo.svg" ; 

const NavigationBar = ({setisOpenSidePanel, isOpenSidePanel , head, desc, className , showSupportForm}) => {

    const showSupport = showSupportForm === false ? showSupportForm : true ;

    return (
        <>
        <div className={`navigation-block ${className}`} style={{ zIndex: 10}}>      
            <div className="navigation-inner">
                <div className="logo">
                        {/* <svg width="34" height="44" viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.4366 30.4958L11.394 30.5301L12.4688 20.6965L17.0426 20.685C20.3586 20.6736 22.211 22.1029 22.4397 25.0416C22.737 28.8493 20.5187 30.4615 16.4366 30.4958ZM33.737 39.3004C33.737 40.1123 33.0852 40.764 32.2734 40.764H12.0686L10.0218 44L10.4906 39.3805L10.9366 34.7838L17.054 34.7266C23.6975 34.658 27.5624 31.5593 27.5281 25.1102C27.5281 19.6902 23.8004 16.2713 17.7744 16.2713H7.76922L5.32223 40.764H5.3108L5.0478 43.2568L4.23595 40.764H1.45737C1.04573 40.764 0.656953 40.5925 0.382525 40.2838C0.108096 39.9751 -0.0291233 39.5748 0.0051802 39.1632L2.49791 11.4345C2.56652 10.6798 3.19541 10.1081 3.95009 10.1081H9.07275V7.79834C9.07275 3.49896 12.5717 0 16.8711 0C21.1705 0 24.6694 3.49896 24.6694 7.79834V10.1081H29.7806C30.5353 10.1081 31.1642 10.6913 31.2328 11.4345L33.7141 39.0489C33.737 39.1403 33.737 39.2204 33.737 39.3004ZM21.7422 7.80977C21.7422 5.12266 19.5582 2.93867 16.8711 2.93867C14.184 2.93867 12 5.12266 12 7.80977V10.1195H21.7422V7.80977Z" fill="#CB5B75"/>
                        </svg> */}
                        <h2> RefineAI </h2>
                </div>
                <div className="navigation-left" >
                     {/* <div className="mobile-logo">
                        <svg width="34" height="44" viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.4366 30.4958L11.394 30.5301L12.4688 20.6965L17.0426 20.685C20.3586 20.6736 22.211 22.1029 22.4397 25.0416C22.737 28.8493 20.5187 30.4615 16.4366 30.4958ZM33.737 39.3004C33.737 40.1123 33.0852 40.764 32.2734 40.764H12.0686L10.0218 44L10.4906 39.3805L10.9366 34.7838L17.054 34.7266C23.6975 34.658 27.5624 31.5593 27.5281 25.1102C27.5281 19.6902 23.8004 16.2713 17.7744 16.2713H7.76922L5.32223 40.764H5.3108L5.0478 43.2568L4.23595 40.764H1.45737C1.04573 40.764 0.656953 40.5925 0.382525 40.2838C0.108096 39.9751 -0.0291233 39.5748 0.0051802 39.1632L2.49791 11.4345C2.56652 10.6798 3.19541 10.1081 3.95009 10.1081H9.07275V7.79834C9.07275 3.49896 12.5717 0 16.8711 0C21.1705 0 24.6694 3.49896 24.6694 7.79834V10.1081H29.7806C30.5353 10.1081 31.1642 10.6913 31.2328 11.4345L33.7141 39.0489C33.737 39.1403 33.737 39.2204 33.737 39.3004ZM21.7422 7.80977C21.7422 5.12266 19.5582 2.93867 16.8711 2.93867C14.184 2.93867 12 5.12266 12 7.80977V10.1195H21.7422V7.80977Z" fill="#CB5B75"></path></svg>
                    </div>  */}
                    <div className="navigation-content" >
                        <h2>{head}</h2>
                        <p>{desc}</p>
                        {/* <p dangerouslySetInnerHTML={desc} /> */}
                    </div>
                </div>
                <div className="navigation-right">
                    <div className="toggle-menu" onClick={() => setisOpenSidePanel(!isOpenSidePanel)}>
                        <div className="toggle-menu-inner">
                            {/* <img src={logo} alt="" /> */}
                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"/></svg> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
       { showSupport &&      
        <div class="custom-support-section">
            <a style={{color:'#ffffff'}} href="https://drive.google.com/drive/folders/1t1I_dgQ4cHTXc5AcdMak_1GXm3Z6yYbl?usp=drive_link" target='_blank' rel="noreferrer">
                Starting a New Project? <span style={{textDecoration: 'underline'}}> Add Your Google Drive Files Here! </span> 
            </a> 
        </div>
       }
        </>
    )
}

export default NavigationBar
