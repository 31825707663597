import React, { useState , useEffect } from 'react'
// import ListingDetails from '../components/ListingDetails/ListingDetails'
import DetailsMap from './detailmap' ; 
import ViewListingDetails from '../listingdetail/detail'
import '../../../styles/listingdetailpage.css'

// import Header from "../../../../look-up/datas/us/Header/" ;
// import { getBillboardByRef } from "../../../../../services/billboardService" ; 
import { useParams , Redirect , useHistory } from "react-router-dom";
// import Limitwall from "../../../../look-up/datas/us/Authwall/indexlimit"; 
// import LoadingPage from "../../../../common/tools-ui/loadingPage" ;


import jobData from "../job";
import ListingDetailsMap from '../listingmap';

export default function ListingDetailPage() {
    const { job } = jobData;
    const [billboard, setBillboard]= useState({}); 
    const [limitExceeded, setLimitExceeded] = useState(false);
    const [places, setPlaces]= useState({}) ;
    const [flow, setFlow]= useState({}) ;
    const [loading, setLoading] = useState(!true) ; 
    const [exception, setException] = useState(false) ; 
    const [referenceId, setReferenceId ] = useState(useParams().ref);

    /*
    const DtsBillboardsByRef= [{
        "billboard": {
            "_id": "63dabdf937a5b26c6547bc82",
            "ref": "110019",
            "plz": "10019",
            "lat": 40.761,
            "lng": -73.984,
            "address": "750 7th Ave, 750 7th Ave, New York, New York 10019, United States",
            "specificationUrl": "https://doamanager.com/generateSpecSheets.php?billboardId=25027",
            "type": "Wallscape",
            "shortAddress": "750 7th Av Broadway ES 5ft S/O 50th St F/N - 7",
            "description": "Visible to vehicular and pedestrian traffic heading south on Broadway. Proximity to NY's Advertising Agencies and many Financial Services Firms. Located in the Broadway Theater District in New York City",
            "size": "480 x 600",
            "face": "North",
            "imageUrl": "https://doamanager.com/documents/2022/11/21/637bd3c2e0699.png",
            "impressions": 0,
            "price": 0,
            "ziprank": 1,
            "cityrank": 28,
            "countyrank": 28,
            "staterank": 28,
            "city": "New York",
            "county": "New York County",
            "state": "New York",
            "city_key": "new-york",
            "county_key": "new-york-county",
            "state_key": "new-york",
            "__v": 0
        },
        "places": {
            "Lodging": 19,
            "Tourist Attraction": 1
        }
    }]

    useEffect(() => {
        getBillboard()
    }, [])

    const getBillboard = async() => {
 
        try {
            // const resp = await getBillboardByRef("us", referenceId )
            const resp = {data : DtsBillboardsByRef[0]}
 
            setBillboard({...resp.data.billboard})
            setPlaces({...resp.data.places})
            // setFlow({...resp.data.flow})
            setLoading(false)
 
            
            // setState(resp.data[0].all[0].state)
            // setCities([...resp.data[0].all])
            // setPopulated([...resp.data[0].toppopulated])
            // setYoungest([...resp.data[0].topyoungest])
            // setOldest([...resp.data[0].topoldest])
            
        } catch (error) {
            setLoading(false)
            if (error.response.status == 429) {
                setLimitExceeded(true)
            } else {
                setException(true)
            }
        }
 
    }
    */

    const staff = {
        "id": "staff1",
        "name": "Alice",
        "lastName": "Brown",
        "policeDeptState": "California",
        "address": "789 Safe St, PeaceTown, CA",
        "coordinates": {"lat": 40.77, "long": -74.0},
        "distanceToJob": 8, 
        "totalJobsDone": 20,
        "hourlyCharge": 42 ,
        "averageRating": 4.5,
        "services": ["Standing Post", "Emergency Security"]
    }


    if (exception) return <Redirect to="/404"/> ;

    return (
        <>  
            {/* <Header /> */}

            
            {
               !loading && 
               <div id="listing-billboard-detail" className='listing-section p-4' style={{ display: 'flex' }}>
               
                   <ViewListingDetails staff={staff} flow={flow} />
                   <ListingDetailsMap staffs={[staff]} job={job} hoveredStaff={staff} />
               
           </div>
           
            }
            { loading && <div> loading </div>}
        </>
    )
}