import React, { useRef, useEffect } from 'react';
import "pikaday/css/pikaday.css";
import "./database.css";
import { HotTable, HotColumn } from "@handsontable/react";
// import { data } from "./constants.js";
import { addClassesToRows, alignHeaders } from "./hooksCallbacks.ts";
import "handsontable/dist/handsontable.min.css";
import { getSecurityStaffsAll } from "../../../../../services/staffingService";


const Database = () => {
  const hotTableRef = React.useRef(null);
  const [ securityStaffs, setSecurityStaffs ] = React.useState([]) ;
  const [changes, setChanges] = React.useState([]);

  useEffect(() => {
    if (hotTableRef.current) {
      const hotInstance = hotTableRef.current.hotInstance;
      hotInstance.scrollViewportTo(0, 0);
    }

    fetchSecurityStaffs();

  }, []);

  const saveChanges = () => {
    // Function to process and send changes to the server
    console.log('Changed data id ', changes);
  };

  const fetchSecurityStaffs = async () => {
    const { data:staffData } = await getSecurityStaffsAll();
    const transformedData = staffData.map(staff => [
      staff.firstName,
      staff.lastName,
      staff.email,
      staff.street,
      staff.city,
      staff.zipcode,
      staff.state,
      staff.services,
      staff.vendor,
      staff.id, 
      staff._id
    ]);
    setSecurityStaffs([...transformedData]);
  }

  /*
  const handleAfterChange = (changes, source) => {    
    if (source !== 'loadData' && changes) {
      setChanges(prevChanges => [...prevChanges, ...changes]);
    }
  };
  */ 


  // to notice the changes in the table
  const rowToObject = (rowData) => ({
    firstName: rowData[0],
    lastName: rowData[1],
    email: rowData[2],
    street: rowData[3],
    city: rowData[4],
    zipcode: rowData[5],
    state: rowData[6],
    services: rowData[7],
    vendor: rowData[8],
    price: rowData[9],
    _id: rowData[10]
});


const handleAfterChange = (changes, source) => {
  if (source !== 'loadData' && changes) {
      setChanges(prevChanges => {
          const newChanges = new Map(prevChanges);

          changes.forEach(([row, col, oldValue, newValue]) => {
              if (oldValue !== newValue) {
                  // Safety check to ensure row data exists
                  if (securityStaffs[row]) {
                      const updatedRow = rowToObject(securityStaffs[row]); // Convert the entire row to an object
                      newChanges.set(row, updatedRow); // Ensure the updated row is set correctly in the Map
                  } else {
                      console.error(`Row data is undefined at index ${row}`);
                  }
              }
          });

          return Array.from(newChanges.values()); // Convert only if newChanges has valid entries
      });
  }
};


  return (
    <div style={{

      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '50px' 
    }}>
      <div style={{
        width: '100%',
        zIndex: 1  
      }}>
        <HotTable
          data={securityStaffs}
          //colWidths={[170, 156, 222, 130, 130, 120, 120]}
          colHeaders={[
            "First Name",
            "Last Name",
            "Email",
            "Street",
            "City",
            "Zipcode",
            "State",
            "Services",
            "Vendor" , 
            "Price per Hr($)"
          ]}
          dropdownMenu={true}
          hiddenColumns={{
            indicators: true,
          }}
          contextMenu={true}
          multiColumnSorting={true}
          filters={true}
          rowHeaders={true}
          autoWrapCol={true}
          autoWrapRow={true}
          afterGetColHeader={alignHeaders}
          afterChange={handleAfterChange}
          beforeRenderer={addClassesToRows}
          manualRowMove={false}
          licenseKey="non-commercial-and-evaluation"
          stretchH="all" 
        >
          <HotColumn data={0} />
          <HotColumn data={1} />
          <HotColumn data={2} />
          <HotColumn data={3} />
          <HotColumn data={4} /> 
          <HotColumn data={5} />
          <HotColumn data={6} />
          <HotColumn data={7} />
          <HotColumn data={8} /> 
          <HotColumn data={9} type="numeric" /> 
        </HotTable>
      </div>
      <div style={{
        width: '100%',
        height: '80px',
        position: 'fixed',
        bottom: 0,
        background: '#F8F9FD',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2
      }}>
        <button 
        style={{
          background: '#3e4784',
          borderRadius: '8px',
          padding: '10px 16px',
          color: 'white',
          cursor: 'pointer',
          border: 'none'
        }}
        onClick={saveChanges} > Update Staff Data </button>
      </div>
    </div>
  );
};

export default Database;