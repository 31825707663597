import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { colorsMap, mapboxStyle , mapboxToken } from "../../../global" ;
// import Switch from "../../common/tools-ui/switch" ; 
// import Logo from "../../landing-page/assets/images/logo.png";
import { Link } from "react-router-dom";
import { dropzoneCountries } from "../../../global.js"


class Map extends Component {
    state = {
        lng: 10.492188 ,
        lat: 50.690426 ,
        zoom: 5.3,
        plzSelections: [],
        plzTooltip : true, 
        selectedPopulation: 0 , 
        selectedHouseholds: 0 , 
        download: [], 
        threeD: false,        
        instanceLayers: [] , 
        instanceSources: [], 
        showInstances: true
    };

    addLayer = (tileset) => { 
        this.map.addSource('postleitzahlen', {
            type: 'vector',
            url: tileset
        });
        
        this.map.addLayer(
            {
                'id': 'zipcode',
                'type': 'fill',
                'source': 'postleitzahlen', 
                'source-layer': 'postleitzahlen',
                'paint': {
                    'fill-opacity': 0.3,
                    'fill-color': '#8FB5F8',
                },
            },
            'waterway-label'
        );

        this.map.addLayer(
            {
                'id': 'zipcode-boundary',
                'type': 'line',
                'source': 'postleitzahlen', 
                'source-layer': 'postleitzahlen',
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-opacity': 0.2,
                    'line-color': 'grey',
                    'line-width': 0.5,
                }
            },
            'waterway-label'
        )
        /*
        this.map.addSource('marker-source', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [this.props.lat, this.props.lng]
                        }, 
                        'properties': {
                            icon: 'mapbox-marker-icon-blue'
                        }
                    }
                ]
            }
        });
        */


    }

    componentDidMount() {

        /*
        mapboxgl.accessToken = mapboxToken ; 
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: mapboxStyle,
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
            attributionControl: false, 
        });

        */

       const mapAttri = dropzoneCountries.filter((o)=> {
           return o.value === 'us'
       })
    //    const mapAttri = dropzoneCountries.filter((o)=> {
    //        return o.value === this.props.country
    //    })


        mapboxgl.accessToken = mapboxToken ; 
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: mapboxStyle,
            center: [mapAttri[0].lng, mapAttri[0].lat],
            zoom: mapAttri[0].zoom , 
            attributionControl: false
        });


        const thiss = this;

        this.map.on('load', () => {
            // this.addLayer() ;
            this.addLayer(mapAttri[0].tileset)  
            this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
            this.map.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');
            this.map.setFilter('zipcode', ['in', 'postcode', ...this.props.areas ]) ;
            this.map.setPaintProperty("zipcode", 'fill-color', "#8FB5F8");


            this.map.setFilter('zipcode-boundary', ['in', 'postcode', ...this.props.areas ]) ;
            this.map.setPaintProperty("zipcode-boundary", 'line-color', "#F75E04");
            this.map.setPaintProperty("zipcode-boundary", 'line-width', 1);
            this.map.setPaintProperty("zipcode-boundary", 'line-opacity', 0.8);
        });

        let popup = new mapboxgl.Popup({
            closeButton: false,
        });
        /*

        // display popup zip code and sales value
        thiss.map.on('mousemove', 'zipcode', function (e) {
            // this.map.getCanvas().style.cursor = 'pointer';
            if (thiss.state.plzTooltip === false) return; 
            popup.setLngLat(e.lngLat).setHTML(
                "<div style=color:grey;text-align:center;>" +
                "<div>" + e.features[0].properties.postcode + "</div>" +
                "<div>" + e.features[0].properties.locality + "</div>" +
                "</div>"
            ).addTo(thiss.map);
        });

        thiss.map.on('mouseleave', 'zipcode-boundary', function () {
            popup.remove();
        });
        */ 

        this.map.flyTo({
            center:  [mapAttri[0].lng, mapAttri[0].lat] ,
            zoom: mapAttri[0].zoom+0.1,
            bearing: 0,
            speed: 0.3, // make the flying slow
            curve: 1, // change the speed at which it zooms out
            easing: (t) => t,
            essential: true
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {  


        if (this.props.activeZipcode!== prevProps.activeZipcode) {
            
            /*
            if (this.props.activeZipcode && this.props.activeZipcode.lat && this.props.activeZipcode.lng) {
                this.updateLineStart(this.props.hoveredStaff.coordinates);
            }
            */ 

            this.map.easeTo({
                center: [this.props.activeZipcode.lng, this.props.activeZipcode.lat],
                zoom: 11.5,
                // duration: 1000,
                easing: (t) => t,
                essential: true
            });
        }

       
    }


    componentWillUnmount() {
        this.map.remove();
    }

    setThreeD = (val) => {
        setTimeout(()=>{ 
            const pitch = !val ? 90 : 0
            const bearing = !val ? 0 : 0
            
            this.map.setPitch(pitch)
            this.map.setBearing(bearing)
            this.setState({threeD : !val})
        } , 500)       
    }

    render() {

        const { threeD } = this.state ; 

        return (
            <>          
                <div
                    style={{width: '100%', height: '97.5vh'}}
                    ref={el =>  this.mapContainer = el}
                >
                    {/* <div onClick={ ()=>{ this.setThreeD(threeD)} }>
                        <Switch on={'2D'} off={'3D'} bottom={'29.5vh'} />
                    </div> */}
                </div> 
            </>
         );
    }
}

export default Map;