import React, { Component } from 'react';
// import './svgs.css'

class Addplus extends React.Component {
    render() {
        return (
            <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.666748" y="0.5" width="40" height="40" rx="20" fill="#0071BD" />
                <path d="M26.8333 19.3333H22.1667V14.6667C22.1667 14.3572 22.0437 14.0605 21.825 13.8417C21.6062 13.6229 21.3094 13.5 21 13.5C20.6906 13.5 20.3938 13.6229 20.175 13.8417C19.9562 14.0605 19.8333 14.3572 19.8333 14.6667V19.3333H15.1667C14.8572 19.3333 14.5605 19.4562 14.3417 19.675C14.1229 19.8938 14 20.1906 14 20.5C14 20.8094 14.1229 21.1062 14.3417 21.325C14.5605 21.5437 14.8572 21.6667 15.1667 21.6667H19.8333V26.3333C19.8333 26.6428 19.9562 26.9395 20.175 27.1583C20.3938 27.3771 20.6906 27.5 21 27.5C21.3094 27.5 21.6062 27.3771 21.825 27.1583C22.0437 26.9395 22.1667 26.6428 22.1667 26.3333V21.6667H26.8333C27.1428 21.6667 27.4395 21.5437 27.6583 21.325C27.8771 21.1062 28 20.8094 28 20.5C28 20.1906 27.8771 19.8938 27.6583 19.675C27.4395 19.4562 27.1428 19.3333 26.8333 19.3333Z" fill="white" />
            </svg>

        )
    }
}

export default Addplus; 