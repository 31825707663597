import React, { useState } from 'react'
import '../../../styles/detail.css' ; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddCardIcon from '@mui/icons-material/AddCard';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ForestIcon from '@mui/icons-material/Forest';
import { ReactComponent as Report } from './report.svg'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import TrainIcon from '@mui/icons-material/Train';
import SpeedIcon from '@mui/icons-material/Speed';
// import { useNavigate } from 'react-router-dom';

import policeman from "../../../icons/policeman-small.png" ; 

import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';
import Scheduler from "../../dashboard/scheduler" ; 



export default function ViewListingDetails({ staff }) {
    // let navigate = useNavigate();

    const [modalIsOpen, setIsOpen] = useState(false) ;

    function openModal() {
        setIsOpen(true);
      }
      
      function closeModal() {
        setIsOpen(false);
      }


    /*
    const { plz, lat, lng, address, type, face, 
        description, size, impressions, price, city, county, state, city_key, county_key, state_key } = billboard ; 
    */ 
    let poiclasses= ['point_restaurant', 'point_lodge', 'point_shop', 'point_park' ]
    let adjustedPoi = Object.keys(staff.services).map((_, i) => poiclasses[i % poiclasses.length])

    return (
        <div className='viewListingDetails_section mt-2'>
            <div className="view_details_box">
                {/* <div className='back_link' onClick={() => navigate('/listing')}><ChevronLeftOutlinedIcon />Back to listing</div> */}
                <div className="view_box">
                    <div className="title">
                        {`${staff.firstName} ${staff.lastName}`}
                        <div className="available_box">
                            <FormControlLabel control={<Checkbox className='available_check' checkedIcon={<CheckCircleOutlineIcon />} icon={<CheckCircleIcon />} />} label='Available' />
                            {/* <Checkbox className='favorite' icon={<FavoriteBorder />} checkedIcon={<Favorite />} /> */}
                        </div>
                    </div>
                    <div className="title">
                        {/* <div className='link'>Browse demographics of <a href="">{plz}</a>, <a href="">{city}</a>, <a href="">{state}</a> </div> */}
                      
                    </div>
                    <div className="">
                        <div className="address">
                            <img width="16px" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675175047/free-tools/billboard-marker.svg" alt="" />
                            {`${staff.street} ${staff.zipcode} ${staff.state}`} {`${staff.distanceMiles} 3 miles away`}
                        </div>
                        <div className="daily_update mt-2">
                            <div className="daily_impre">
                                <img width="16px" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675174406/free-tools/immpressions.svg" alt="" /> 
                                 { 20 } jobs in 3 months
                            </div>
                            {/* <div className="daily_approx">
                                <img width="16px" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675174757/free-tools/prices.svg" alt="" /> 
                                 ${staff.hourlyCharge} per hour
                            </div> */}
                        </div>
                        <div className="daily_update mt-2">
                            <div className="daily_approx">
                                <img width="16px" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675174757/free-tools/prices.svg" alt="" /> 
                                 ${staff.price} per hour
                            </div>
                        </div>

                    </div>
                    <hr className='hr' />
                        <p className='detail mt-2 mb-2'> 
                            {staff.vendor} <br/>
                            
                        </p>
                    <hr className='hr' />
                    <div className="point_section">
                        <div className="title">
                            {/* <h2>Places nearby </h2> */}
                            <h2> Services </h2>
                        </div>
                        <div className='point'>

                            {
                                Object.entries(staff.services).map(([key,value], index)=> (
                                    <>
                                        <span className={`point_tag ${adjustedPoi[index]} mt-2`}> {value}</span>
                                    </>
                                ))
                            }

                            {/* <span className='point_tag point_restaurant'> {JSON.stringify(places)} </span> */}

                            {/* <span className='point_tag point_restaurant'><ApartmentOutlinedIcon />43 Restaurants</span>
                            <span className='point_tag point_lodge'><HomeIcon />12 Lodges</span>
                            <span className='point_tag point_shop'><StorefrontIcon />02 Grocery Shops</span>
                            <span className='point_tag point_park'><ForestIcon />04 Parks</span> */}
                        </div>
                        {/* <div className="view_detail">Check Footfall Traffic <ChevronRightOutlinedIcon /></div> */}
                    </div>
                    
                        {/* {

                            /* ====== ACTIVATE LATER =================
                            /* =======================================
                            
                        } */}

                        <hr className='hr' />
                        <div className="driving_section">
                                <div className="title">
                                    <h2> Performance </h2>
                                </div>
                                <div className="driving_items">
                                    <div className="driving_item speed">
                                        <h2><SpeedIcon />CSAT </h2>
                                        <p> 3.1/5 </p>
                                        
                                    </div>
                                    <div className="driving_item uncapped">
                                        <h2><SpeedIcon />NPS</h2>
                                        <p> 3.5/5 </p>
                                    </div>

                                    <div className="driving_item uncapped">
                                        <h2><SpeedIcon />Service Quality</h2>
                                        <p>4.2/5 </p>
                                    </div>
                                    {/* <div className="driving_item jam">
                                        <h2><DirectionsCarIcon />Jam Factor</h2>
                                        <p>1.2 </p>
                                    </div>
                                    <div className="driving_item congestion">
                                        <h2><PowerOutlinedIcon />Jam Factor</h2>
                                        <p>1.2</p>
                                    </div>  */}
                                </div>
                            </div>
                        
                </div>

                <div className="report_section">
                    {/* <Report /> */}
                        <div style={{color:'#db2777' }}><AddHomeWorkOutlinedIcon style={{height:'85px' , fontSize: '40px' }}/> </div>
                        <Scheduler    
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            // style={customStyles}
                            className='scheduler-modal'
                            overlayClassName= 'scheduler-modal-overlay'
                            ariaHideApp={false}
                        />
                    <div className='detail'>
                        <p>
                        Alice will need to accept the offer within 25 minutes to be assigned to this job. <br/> 
                        Send to email a calendar invite and notify via WhatsApp. 
                        </p>
                        <button onClick={openModal} className='report_btn' style={{color:"white"}} >  
                                Assign 
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
