import React, { useState , useEffect } from 'react';
import { Link , Redirect } from "react-router-dom" ;  
import '../../styles/dashboard.css';
import { Toggle , Checkbox } from 'rsuite';
import DashboardSidebar from "./dashboardSidebar"
import "../../styles/workOrder.css"
import DistanceMap from './DistanceMap' ;
import RejectionModal from "./RejectionModal" ; 

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { getProviderOffers , getCurrentProvider , getAcceptOffer } from "../../../../services/staffingService";
import toast from 'react-hot-toast';

function formatJobDateTime(job) {
  const date = new Date(job.customFields.workOrderDate);
  const friendlyDate = date.toLocaleDateString('en-US', {
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
  });
  const [startTime, endTime] = job.customFields.shiftHours.split('-').map(time => `${parseInt(time)} Hrs`);
  return `${ friendlyDate } between ${startTime} to ${endTime}`;
}



const OrderCard = ({ offer , offerAccept, staff , openOffers , setOpenOffers , acceptedOffers , setAcceptedOffers , completedOffers , setCompletedOffers }) => {


  const { ticketId:job } = offer ;
  return (
    <div className="staff-order-card">
      <div className="staff-order-details">
        <div className="staff-order-content">
          <div className="staff-order-header d-flex justify-content-between">
            <div className="staff-order-name" style={{color:'#376D37'}}> Well done! Job completed successfully. </div>
            <div className="staff-order-id">
                <StarIcon style={{ color: '#FFA41E' }} />
                <StarIcon style={{ color: '#FFA41E' }} />
                <StarIcon style={{ color: '#FFA41E' }} />
                <StarIcon style={{ color: '#FFA41E' }} />
                <StarHalfIcon style={{ color: '#FFA41E' }} />
            </div>
          </div>
          <div className="staff-order-address">{ formatJobDateTime(job) }</div>
          <div className="staff-order-address">{ job.formatted_location }. </div>
          <div className="staff-order-total">  { job.customFields.serviceType.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') } </div>
          {/* <div className="staff-order-total"> <a href='/payments' target="_blank" rel="noreferrer" > Check Payment <OpenInNewIcon style={{fontSize:'16px', marginBottom:'5px'}} />  </a>  </div> */}
          <div className="staff-order-total" >
            { offer.paymentDetails.paid && <span style={{fontWeight:'600', color: 'green'}}> Payment Recieved </span> }
            { !offer.paymentDetails.paid && <span style={{fontWeight:'600', color: 'red'}}> Pending Payment </span> }
          </div>
        </div>
      </div>

    </div>
  );
};


const NewOrdersTab = ({ offers ,  staff }) => {
  return (
    <div className="staff-new-orders-tab">
       {/* <div className="staff-new-orders-header d-flex justify-content-between">
          <div>New</div>
          <div> <span> Next >> </span> </div>  
      </div>  */}
      <div className="staff-orders-container">
        {offers.map(offer => (
          <OrderCard key={offer._id} offer={offer} staff={staff} 
          />
        ))}
      </div>
    </div>
  );
};

const StatusTabs = () => {

  const [openOffers , setOpenOffers] = useState([]) ;
  const [acceptedOffers , setAcceptedOffers] = useState([]) ;
  const [completedOffers , setCompletedOffers] = useState([]) ;
  const [staff , setStaff] = useState({}) ;

  useEffect(() => {
      getOffers();
  }
  , []);

  const getOffers = async () => {
    const resp= await getProviderOffers();
    const { data } = await getCurrentProvider();
    // setOffers(resp.data.offer) ;
    setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open'} )) ;
    setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted'} )) ;
    setCompletedOffers(resp.data.offer.filter((o) => { return o.status === 'completed'} )) ;
    setStaff({...data}) ;
  }

  const offerAccept = async (offerId) => {
    try {
        const resp = await getAcceptOffer(offerId);
        setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open'} )) ;
        setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted'} )) ;
    } catch (error) {
        if (error.response && error.response.status === 400) {
            toast.error(error.response.data);   
        }
      
    } 
  }

  // if ( openOffers.length === 0 ) return <Redirect to="/staffing/staff/dashboard/work-order/accepted" /> ;

  return (
            <>
              <div className="dashboard">
                  <DashboardSidebar />       
                  <>
                      <div className="main-content">
                        <div className="main-content-inner">
                            {/* ===== status tabs ===== */}
                            <div className="status-tabs">
                              <Link to='/staffing/staff/dashboard/work-order/open' style={{color: 'inherit'}}>     
                                <div className={`tab`}>
                                  <div className="count">{openOffers.length}</div>
                                  <div className="label">New </div>
                                </div>
                              </Link>
                              <Link to='/staffing/staff/dashboard/work-order/accepted' style={{color: 'inherit'}}>                              
                                  <div className={`tab`}>
                                    <div className="count">{acceptedOffers.length}</div>
                                    <div className="label">Accepted</div>
                                  </div>
                              </Link>
                              <Link to='/staffing/staff/dashboard/work-order/completed' style={{color: 'inherit'}}>                              
                                  <div className={`tab active`}>
                                    <div className="count">{completedOffers.length}</div>
                                    <div className="label">Completed</div>
                                  </div>
                              </Link>

                            </div>  
                            {/* ====== new orders ==== */}
                              {
                                Object.keys(staff).length > 0 && (
                                  <NewOrdersTab 
                                    offers={ completedOffers } 
                                    staff={ staff } 
                                  />
                                )
                              }
                        </div>
                      </div>
                  </>
              </div>
            </>
  );
};

export default StatusTabs;
