import React from "react";
import "../../styles/dashboard-sidebar.css";
import { Link, useLocation } from 'react-router-dom';
// Icons
import AssignBlue from "../../icons/assign-blue.png";
import IntegrationsBlue from "../../icons/integrations-blue.png";
import AssignWhite from "../../icons/assign-white.png";
import IntegrationsWhite from "../../icons/integrations-white.png";

import PerformanceWhite from "../../icons/performance-white.png";
import PerformanceBlue from "../../icons/performance-blue.png";

import { Avatar, AvatarGroup } from 'rsuite';
import LogoutIcon from '@mui/icons-material/Logout';
import Home from "../../icons/home.png" ; 

const Sidebar = () => {
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname.includes(path);
    };

    return (
      <div className="sidebar">
        <div className="sidebar-header">
          {/* Header content */}
        </div>
          <nav className="sidebar-nav">
            <Link to="/staffing/staff/dashboard/work-order/open" className={`${isActive('work-order') ? 'active' : ''}`}>
              <img src={isActive('work-order') ? AssignWhite : AssignBlue} height={45} className="icon orders-icon" alt="" />
              Work Orders
            </Link>
            <Link to="/staffing/staff/dashboard/agenda" className={`${isActive('agenda') ? 'active' : ''}`}>
              <img src={isActive('agenda') ? IntegrationsWhite : IntegrationsBlue} height={45} className="icon orders-icon" alt="" />
              Agenda
            </Link>
            <Link to="/staffing/staff/dashboard/performance" className={`${isActive('performance') ? 'active' : ''}`}>
              <img src={isActive('performance') ? PerformanceWhite : PerformanceBlue} height={45} className="icon orders-icon" alt="" />
              Performance
            </Link>
            {/* <a target="_blank" href="https://connect.stripe.com/oauth/authorize?redirect_uri=https://connect.stripe.com/hosted/oauth&client_id=ca_Q4ihom0JdtZyYPEvL3areTSXFCT4trIc&state=onbrd_Q4jL5dpSpTFP4lWQOaPquroEuU&response_type=code&scope=read_write&stripe_user[country]=US" className={`${isActive('performance') ? 'active' : ''}`}>
              <img src={isActive('payout') ? PerformanceWhite : PerformanceBlue} height={45} className="icon orders-icon" />
              Payouts
            </a> */}
            {/* Other  links */}
          </nav>
          <div className="sidebar-footer text-center justify-content-center">
              <div className="">
                <Avatar bordered circle src="https://i.pravatar.cc/150?u=1" />
              </div>
              <div className="">
                <Link to="/staffing"> <span style={{ color:'#3D4784'}}>  Irene </span>  <LogoutIcon style={{ fontSize: '16px'}}/>  </Link>
              </div>
          </div>
      </div>
    );
};

export default Sidebar;
