import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import sendicon from "./suggestion_icon.svg";
import { getCopilotCompletion , getCopilotCompletionStream } from "../../../../services/pfizerService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import suggestions1 from "./suggestions.json" ; 

Modal.setAppElement('#root');

function PromptLibrary({
  err, setErr,
  completionLoading, setCompletionLoading,
  conversation, setConversation,
  completion, setCompletion,
  prompt, setPrompt,
  setCompletionError,
  data_name, copilot_name, completionSettings,
  isOpen, onRequestClose ,
  isModalOpen  , setIsModalOpen ,
  lastwords , setLastwords
}) {

  const suggestions  = [...suggestions1] ;

  const handleSubmit = async (prompt) => {
    setErr('')
    setIsModalOpen(false)
    setCompletionLoading(true)
    
    
    try {
      setConversation(prevConversation => [
          ...prevConversation,
          { role: 'user', content: prompt }
      ]);

      const resp= await getCopilotCompletionStream(prompt)

      if (!resp.ok || !resp.body) {
          setCompletionError(true)
          setConversation(prevConversation => [
              ...prevConversation,
              { role: 'assistant', content: resp.data.errmessage , err: true}
          ]);
          setCompletionLoading(false) 
          throw resp.statusText;
      }

      const reader = resp.body.getReader();
      const decoder = new TextDecoder();
      const loopRunner = true;
      let result = "";
  
      while (loopRunner) {
          const { value, done } = await reader.read();
          if (done) {
              break;
          }
          const decodedChunk = decoder.decode(value, { stream: true });
          
          let compl = decodedChunk ;
          result += compl;

          setLastwords(result)
          // reset();
      }
      setLastwords("")
      setCompletion(result)
      setPrompt(prompt)
      setCompletionLoading(false)
      setConversation(prevConversation => [
          ...prevConversation,
          { role: 'assistant', content: result }
      ]);
      /*
      const resp = await getCopilotCompletion(prompt )
      if (!resp.data.err){
          
          let compl = resp.data.completionObj.choices[0].message.content;

          setCompletion(compl)
          setPrompt(prompt)
          setCompletionLoading(false)
          setConversation(prevConversation => [
              ...prevConversation,
              { role: 'assistant', content: compl }
          ]);
        //   reset();
      } else if (resp.data.err){
          setCompletionError(true)
          setConversation(prevConversation => [
              ...prevConversation,
              { role: 'assistant', content: 'Bad request. Please Try again', err: true}
          ]);
          setCompletionLoading(false)
      }
      */
      
    } catch (ex) {
      if (ex.response && ex.response.status === 404){
          setErr(ex.response.data)
        }
      setCompletionLoading(false)
    }
  };

  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Prompt Suggestions"
    style={{
        overlay: {
            backgroundColor: '#89A7C0', 
            backdropFilter: 'blur(5px)',
            zIndex: 10
        },
        content: {
            fontFamily: 'Arial, sans-serif',
            background: '#181818',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden', // Changed this from 'auto'
            alignItems: 'center',
            justifyContent: 'center',
            width: '95%',
            height: '95%',
            margin: 'auto', 
            
        }
    }}
    >

    {
        suggestions.length >= 1 && <>
        <FontAwesomeIcon onClick={onRequestClose} style={{ position: 'absolute', top: '10px', right: '10px' , color:'white', fontSize: '15px' }} icon={['fas', 'times']} />
        <div className="prompt-library-navbar text-center justify-content-center">
            <span className="prompt-library-navbar-title">Prompt Library </span>
        </div>

        <div className="prompt-library-content">
            <div className="prompt-library-container">
            {suggestions.map((suggestion, index) => (
                <div key={index} className="prompt-library-card" onClick={() => { handleSubmit(suggestion.subtitle) }}>
                <div className="prompt-library-title">
                    {suggestion.title}
                </div>
                <div className="prompt-library-subtitle text-center">
                    {suggestion.subtitle}
                </div>
                <div className="mt-1">
                    <img src={sendicon} style={{ width: '25px' }} alt="" />
                </div>
                </div>
            ))}
            </div>
        </div>
        </>
    }
   {
     suggestions.length < 1 && 
     <>
        <FontAwesomeIcon onClick={onRequestClose} style={{ position: 'absolute', top: '10px', right: '10px' , color:'white', fontSize: '15px' }} icon={['fas', 'times']} />
        <div className="prompt-library-navbar-title">Sorry. No Prompts available for this Co-Pilot.</div>
     </>
   }
</Modal>

  );
}

export default PromptLibrary;
