import React, { useState , useRef, useEffect } from 'react';
import "./styles/segments.css" ; 
import chekclistIcon from "./icons/checklist.png" ; 
import { Toggle } from 'rsuite';
import PredefinedSegments from "./report/predefinedSegments" ; 
import { Link } from "react-router-dom" ;
import Navbar from "./navbar" ; 

const cardDataArray = [
    { title: 'Young Rhode Island ZIP Codes', category: 'Electronics & Appliances', subCategory: 'Kitchen Appliances', population: '250,000', households: '120,000', zipcodes: '214', poi: '34' },
    { title: 'High-Income Havens of Oregon', category: 'Automobile', subCategory: 'Car Parts', population: '80,000', households: '60,000', 'zipcodes' : '52', poi: '16' },
];


const SegmentCard = ({ seq, title, category, subCategory, population, zipcodes, households, poi }) => {
    return (
      <div className="segment-card-container">
        <div className="segment-card-header">
          {title}
          <span className="segment-card-warning-icon">{seq}</span>
        </div>
        <div className="segment-card-content">
          <div className="segment-card-section">
            <span className="segment-card-label">Category</span>
            <span className="segment-card-value">{category}</span>
          </div>
          <div className="segment-card-section">
            <span className="segment-card-label">Sub-category</span>
            <span className="segment-card-value">{subCategory}</span>
          </div>
          <div className="segment-card-section">
            <span className="segment-card-label">Population</span>
            <span className="segment-card-value">{population}</span>
          </div>
          <div className="segment-card-section">
            <span className="segment-card-label">Households</span>
            <span className="segment-card-value">{households}</span>
          </div>
        </div>
        <div className="segment-card-footer">
          <span className="segment-card-days">{zipcodes} ZIP Codes</span>
          <span className="segment-card-overdue">{poi} Point of Interests </span>
        </div>
      </div>
    );
  };




  const Segments = () => {

    const [showPredefined, setShowPredefined] = useState(!false)

    return <> 
         <div className="segments-body">

        <Navbar />

    {/* ===== segment card === when user creates a segment ===== */}
    { !showPredefined && 
        <>
            
                <div className="segments-products">
                        <div className="segments-products-selected">Knoxhult Kitchen Cabinets <img style={{width:'20px'}} src={chekclistIcon} alt="" /> </div>
                        <div className="segments-products-not-selected">Automobile Parts </div>
                
                <div className="ml-auto"> 
                    <Toggle className="mr-1" size="md" onChange={()=>{setShowPredefined(!showPredefined)}} checked={showPredefined}/>
                    <span> Predefined </span>   
                </div>
            </div>
            <div className="segments-cards-container">
            {cardDataArray.map((cardData, i) => (  
                <SegmentCard
                    key={i}
                    seq={i+1}
                    title={cardData.title}
                    category={cardData.category}
                    subCategory={cardData.subCategory}
                    population={cardData.population}
                    households={cardData.households}
                    zipcodes={cardData.zipcodes}
                    poi={cardData.poi}
                />
            ))}
            </div>
        </>
    }
    { showPredefined &&  <PredefinedSegments showPredefined={showPredefined} setShowPredefined={setShowPredefined} /> }

    </div>
    </>
}

export default Segments ; 