import React , { useState , useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { GoogleLogin , GoogleLogout } from 'react-google-login';
import { Link, Redirect } from 'react-router-dom';
import GoogleIcon from '../asset/auth-icons/gicon.png'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { googleAuth , getCurrentUser, login, loginWithJwt } from '../../services/userService';
import { Loader } from 'rsuite';
import Message from 'rsuite/Message';
import toast from 'react-hot-toast';
import { gapi } from 'gapi-script';
import NavbarAuth from "./NavbarAuth" ; 

// import RightArrow from './icons/icons8-right-arrow-50 (2).png'

const schema = yup.object({
    email: yup.string().email('Invalid email format').required('Required'), 
    password: yup.string().min(12).required('Required'),
}).required();

const SignIn = () => {
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    /*
    useEffect(() => {
        function start() {
          gapi.client.init({
            // clientId: process.env.REACT_APP_GAUTH,
            clientId: '616499430338-t44q12v0h3a9ka5fpv980o7cra1c3msi.apps.googleusercontent.com',
            scope: 'email',
          });
        }
        gapi.load('client:auth2', start);
      }, []);
      */ 


    const onSubmit = async(data) =>  {
        try {

            setLoading(true); 
            const resp = await login(data.email, data.password) ;
            
            if (!resp.data.user.firstname) {
                return window.location.href = `/user/form`
            } else {
                return window.location.href = `/home`
            }

            /*
            setLoading(true)
            const resp = await login(data.email, data.password) ;
            setTimeout(() => {
                window.location.href = `/home`
            }, 1000);
            */     
           
        } catch (ex) {
            setLoading(false)
            if (ex.response && ex.response.status === 400){
                setErrorMsg(ex.response.data)
            }
        }
    };

    if (getCurrentUser()) return <Redirect to="/home" />

    return (
        <>
        
        <NavbarAuth /> 
        <div id='signIn' className="signIn-Page">
            <div className="signin-inner">
            {
                errorMsg && 
                <div className="mb-3 text-center">
                    <Message type="error"> {errorMsg} </Message>
                </div>
            }
            <h4 className="text-center mb-4"> Got Your Demo Credentials? Use Them Here </h4>
                {/* <div className='googlebtn-block'>
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GAUTH}
                        buttonText="Enable"
                        render={ renderProps => (
                            <button onClick={renderProps.onClick} className='googlebtn'> 
                                { !loading && 
                                    <>
                                        <img src={GoogleIcon} alt='g-auth' />                  
                                        <span className="ml-1"> Sign In With Google </span> 
                                    </>
                                }
                                { loading && <Loader/>}
                            </button>


                        )}
                        onSuccess={responseGoogleSuccess}
                        onFailure={responseGoogleFail}
                        cookiePolicy={'single_host_origin'}
                    /> 
                </div> */}

                {/* <div className='or-contain'>
                    <p>or</p>
                </div> */}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-icons">
                        <i className="fa fa-envelope icon"></i>
                        <input className="input-field"
                            type="text"
                            {...register("email", { required: true })}
                            placeholder='name@example.com' 
                        />
                        {errors.email && <span className='errorTextForm'> {errors.email.message} </span>}
                    </div>

                    <div className='password-submit-block'>
                        <div className="input-icons mt-3">
                        <i className="fa fa-key icon"></i>                  
                            <input className="input-field password" 
                            type="password"
                                {...register("password", { required: true })} placeholder='Password' 
                            />
                            {errors.password && <span className='errorTextForm'> {errors.password.message} </span>}
                        </div>
                        <button type="submit" className='submitbtn mt-3'>
                            { !loading && <i className="fa fa-arrow-right"></i> }
                            { loading && <Loader /> }
                        </button>

                    </div>
                </form>
                {/* <div className='sign-forgot-link-block mt-3'>
                    <Link to='/signup'>
                        Sign Up
                    </Link>
                    <Link to='/forgotpassword'>Forgot Your Password?</Link>
                </div> */}
            </div>
        </div>
        </>
    )
}

export default SignIn
