import React from "react";
import { Route, Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { getCurrentUser } from "../../services/userService";


const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  
  const available = localStorage.getItem('x-auth-texti-demo')
  const decoded = available ? jwtDecode(available) : undefined ; 


  const today = new Date() ;
  
  // console.log(decoded.exp * 1000)
  // {console.log('+++ calling logout funct +++')}

  return (
    <Route
      {...rest}
      render={(props) => {

        if (!getCurrentUser())
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        
        
        if( decoded && getCurrentUser() && (decoded.exp * 2000 <= today.getTime()))
        
          return ( 
            <Redirect
              to={{
                pathname: "/logout"
              }}
            />
            
            ) ; 
        
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute ;