import React, { useEffect, useState } from 'react';
import { Link, useParams, Redirect, useHistory , useLocation } from "react-router-dom";
import ListingDetails from './listingitem.jsx';
import RecommendedListingDetails from "./recommendedListingItem.jsx"
import ListingDetailsMap from './listingmap.js';
import ListingSidebar from './listingsidebar.js';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { getSecurityStaffsOrderId } from '../../../../services/staffingService';

import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PaidIcon from '@mui/icons-material/Paid';

import { Dropdown } from 'rsuite';

import '../../styles/listing.css'
import jobData from "./job";

const Listing = () => {
    //const { job, recommendedStaffs } = jobData; // Destructuring the new data
    const [location, setLocation] = useState(useParams().location);
    const [geolevel, setGeolevel] = useState(useParams().geolevel)
    const [staffs, setStaffs] = useState([]) // Using recommended staffs
    const [ all , setAll ]= useState([]);
    const [job, setJob] = useState({});
    const [hoveredStaff, setHoveredStaff]= useState({})
    const [loading, setLoading] = useState(false); // Adjusting based on new data structure
    const [exception, setException] = useState(false);
    const [positionsFilled, setPositionsFilled] = useState(false); 
 
    const locationParam= useLocation();
    const query = new URLSearchParams(locationParam.search);
    const view = query.get('view');
    const orderId = useParams().orderId ;
    const [listViewType, setListViewType] = useState(view === 'recommended' ? 'recommended' : 'closest'); 
    const history = useHistory();

    const handleNavigation = (viewType) => {
        setListViewType(viewType);
        history.push(`/staffing/staff-allocation/dashboard/assign/2017?view=${viewType}`);
    };
    

    useEffect(() => {
        closestStaffs();
    }, []);

    const closestStaffs = async() => {

        const { data } = await getSecurityStaffsOrderId(orderId) ; 
        const staffsWithOffersField = data.staffs.map(staff => ({
            ...staff,
            offerPrice: Math.round(data.ticket.customFields.customerHourlyRate*(1-0.35)), 
            customerRatePercentage: 35,
            offerId: staff.offerId || null 
        }));

        const acceptedOffers = staffsWithOffersField.filter(staff => staff.offer && (staff.offer.status === 'accepted' || staff.offer.status === 'completed' || staff.offer.status === 'paid'));
        const totalStaffsRequired = data.ticket.customFields.totalStaffsRequired; 

        if (acceptedOffers.length >= totalStaffsRequired) {
            setStaffs(acceptedOffers);
            setAll([...acceptedOffers]);
            setJob({...data.ticket});
            setPositionsFilled(true);

            return;
        } else {
            
            setStaffs(staffsWithOffersField);
            setAll([...staffsWithOffersField]);
            setJob({...data.ticket});
        }

    }

    if (exception) return <Redirect to="/404" />;

    return (
        <>


            {
                staffs.length >= 1 && Object.keys(job).length > 0 && 
                <>
                    {
                        positionsFilled === true &&
                        <div class="sticky-header-message text-center">
                        <div class="alert alert alert-danger p-2" role="alert" style={{marginBottom: '0px'}}>
                            <span>  
                                All positions are currently filled. No further offers can be sent at this time.
                            </span>
                            </div>
                        </div>
                    }
                    <div id="listing-billboard">
                        <div className='listing-section'>
                                <div className="listing-section-header mt-3 ">
                                        <div className="sort_by align-items-center" >

                                            {/* { <div className="d-flex " style={{marginRight: '15px'}} ><span> <SettingsAccessibilityIcon style={{fontSize:'20px' , marginRight:'3px'}} /> </span> ${ job.customFields.customerHourlyRate }</div> } */}
                                            <div className="d-flex " style={{marginRight: '15px'}} ><span> <LocationOnIcon style={{fontSize:'18px' , marginRight:'3px'}} /> </span> { job.formatted_location  }</div>
                                            { <div className="d-flex " style={{marginRight: '15px'}} ><span> <SettingsAccessibilityIcon style={{fontSize:'20px' , marginRight:'3px'}} /> </span> {staffs.length} security staffs located within 15 miles </div> }
                                            { <div className="d-flex " style={{marginRight: '15px'}} ><span> <PaidIcon style={{fontSize:'20px' , marginRight:'3px'}} /> </span> Compensation at ${job.customFields.customerHourlyRate} per hour</div> }
                                            <div className="" style={{marginRight: '35%'}}>
                                                <Dropdown title={<span style={{fontWeight: 500, fontSize: '13px', color: '#828282'}}> { listViewType.charAt(0).toUpperCase() + listViewType.slice(1) } </span>}>
                                                    { listViewType !== 'closest' && (
                                                        <Dropdown.Item onClick={() => handleNavigation('closest')}>
                                                            <span style={{fontWeight: 500, fontSize: '13px', color: '#828282!important'}}> Closest </span>
                                                        </Dropdown.Item>
                                                    )}
                                                    { listViewType !== 'recommended' && (
                                                        <Dropdown.Item onClick={() => handleNavigation('recommended')} style={{fontWeight: 500, fontSize: '13px', color: '#828282'}}>
                                                        Recommended
                                                        </Dropdown.Item>
                                                    )}
                                                </Dropdown>
                                            </div>
                                        </div>
                            </div>
                            {
                                listViewType === 'closest' && 
                                <div className="custom-container">
                                    <div className="custom-row mt-2">
                                        <div className='sideBar'>
                                            <ListingSidebar staffs={staffs} all={all} setStaffs={setStaffs} /> 
                                        </div>
                                        <div className='listing-content'>
                                            <ListingDetails staffs={staffs} setStaffs={setStaffs} job={job} hoveredStaff={hoveredStaff} setHoveredStaff={setHoveredStaff} />
                                            <ListingDetailsMap staffs={staffs} job = {job} hoveredStaff={hoveredStaff} />
                                        </div>
                                    </div>
                                </div> 
                            }

                            {
                                listViewType === 'recommended' &&
                                <div className="custom-container">
                                    <div className="custom-row  p-3">
                                            <div className='listing-content pl-2'>
                                                    <RecommendedListingDetails className="ml-2" staffs={staffs} setStaffs={setStaffs} job={job} hoveredStaff={hoveredStaff} setHoveredStaff={setHoveredStaff} />
                                            </div>
                                            <ListingDetailsMap staffs={staffs} job = {job} hoveredStaff={hoveredStaff} />
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </>
            }
            {loading && staffs.length === 0 && <div>Loading...</div>}
        </>
    );
}

export default Listing;
