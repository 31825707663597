import React, { useState } from "react";
import { useForm } from 'react-hook-form' ; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import styles from "./styles/addData.module.css" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import CutomButton from "../../common/customButton/customButton"
import { withRouter } from 'react-router'; 
import { Loader, Message } from "rsuite";
import { renameWs } from "../../services/workspaceService";
import { hyphenate } from "../../utils/tools";


const schema = yup.object({
  wsName: yup.string().min(3).required('Required')
}).required();

const RenameData =({ handleCloseModal , setShowModal , ws,  setWs, wsId , type , name })=> {

  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState('')
  const { register, handleSubmit, formState: { errors } } = useForm({
      resolver: yupResolver(schema)
  });

  function updateWorkspaceView (existingWs, idToFind, newName) {

    const updatedData = existingWs.map(item => {
      if (item.type === 'Data' && item._id === idToFind) {
        return { ...item, data_name: newName };
      }
      if (item.type === 'Fine-tune' && item._id === idToFind) {
        return { ...item, ft_name: newName };
      }
      if (item.type === 'Co-Pilot' && item._id === idToFind) {
        return { ...item, copilot_name: newName };
      }
      return item;
    });

    return updatedData;
  }

  const onSubmit = async(data)=> {
    setLoading(true);
    try {
      setLoading(true);
      const name= hyphenate(data.wsName)
      setLoading(true)
      const resp = await renameWs(wsId, name , type ) ;

      if (resp.status === 200) {
        setLoading(false)
        let wsView = updateWorkspaceView(ws, wsId, name)
        setWs([...wsView])
        setShowModal(false)
 
      }    
    } catch (ex) {
      setLoading(false);
      if (ex.response && ex.response.status === 400) {
        setMsg(ex.response.data);
      }
    }
  }

  return  <>
      <div className={`${styles.headerDivModal} mt-1`} >
        <span> Rename </span>
        <FontAwesomeIcon onClick={handleCloseModal} className={`float-right mr-1 ${styles.icon}`} icon={['fas', 'times']} />
    </div>
    <form
      className="form-column align-items-center p-4"
      style={{ width: "100%"}}
      onSubmit={handleSubmit(onSubmit)}
      >       
            <div className={`ml-3 mr-3 ${styles.displayFlex}`}>
                <input 
                    className={ `form-control ${styles.textAreaPlaceholder}` }
                    type="text"
                    {...register("wsName", { required: true })}
                    placeholder={name}
                />
                {errors.wsName && <span className='errorTextForm'> {errors.wsName.message} </span>}
                {msg && <span className='errorTextForm'> {msg} </span>}
            </div>
                                  
            <div className= {` mt-4 ml-3 mr-3 mb-3 ${styles.displayFlex} `} >
                <button className={`${styles.loginButton} ${styles.loginText}`} type="submit" >
                    { !loading && 'Rename'}
                    { loading && <Loader />}
                </button>
            </div>
      </form>

  </>
}

export default withRouter(RenameData);