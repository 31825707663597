import React, { useState, useEffect } from 'react'
import Sidebar from "./Sidebar/SideBar"
import InputSection from "./Sidebar/InputSection" ; 

import NavigationBar from '../homeLoggedIn/NavigationBar';

import { CustomCard } from "./common" ; 
import InputIcon from "../asset/img/InputIcon.svg"
import VoiceIcon from "../asset/img/voice.svg"

import feedback from "../asset/img/feedback.png"
import hr from "../asset/img/hr.png"
import legal from "../asset/img/legal.png"




const FietuneLoop = () => {
    const hour = new Date().getHours();
    const [firstname, setFirstname] = useState('');

    useEffect(() => {

        const userInfo = async () => {
            // let resp = await getUserInfo();
            // setFirstname(resp.data.firstname);
            // if DETAILS is not set to true then redirect to details page. 
            setFirstname('M')
        }
        userInfo();
    })

    return (
        <>

            <NavigationBar head={'Fine-Tunes Hub'} desc={'Customized AI Models fine-tuned on your data, trained specifically for the tasks you need, ensuring optimal performance and precision.'}  />       
            <div className="home-content">
                <div className="d-flex">
                    {/* <Sidebar selected={'finetune'} /> */}
                    {/* <div> finetune section here </div> */}

                    <div className='loop-input-section'>
                        {/* <div className='loop-input-wrapper'>
                            <div className='loop-input-text'><img src={InputIcon} style={{ marginRight: "11px", width: "20px", height: "20px" }} /> Fine-Tunes Hub, Customized AI Models on Your Data </div>
                        </div> */}

                        <div className='loop-input-wrapper-main d-flex p-5'>
                            <CustomCard 
                                title="Sentiment Classification"
                                description="This model is fine-tuned on 5200 text feedbacks, it automatically classifies the user feedback into one of the three sentiments - Positive, Negative and Neutral."
                                icon={feedback}
                            />

                            <CustomCard 
                                title="Feedback Classification"
                                description="This model is trained on a balanced dataset of 5000 customer feedbacks. It is a multi-label classification model capable of identifying topics in the feedback. This model works with 8 labels for topic classification of webshop reviews: Damaging, Package Price, Pricing, Product Quality, Customer Service, Shipping, Website Navigation, and Overall Experience."
                                icon={hr}
                            />
                            
                            <CustomCard 
                                title="Legal Q&A"
                                description="This model trained on 3,600 questions and answers from your legal team, possesses domain knowledge and comprehends the structure required to respond to legal-specific inquiries effectively. This co-pilot is powered by in-depth legal Q&A insights sourced from our comprehensive Knowledge Hub"
                                icon={legal}
                                externalDataLink={'/loop/knowledge'}
                            />

                            

                       </div>


                    </div>


                </div>
            </div>
        </>
    )
}

export default FietuneLoop ; 