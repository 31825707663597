import React, { useState , useRef, useEffect } from 'react';
import { Link } from "react-router-dom" ;
import Navbar from "../navbar" ;

const Categories = () => {

  return <> 
    <div className="segments-body">
    <Navbar />
    <div className="segments-cards-container">
            Check back later. Under development  
    </div>
  </div>
  </>
}

export default Categories ; 