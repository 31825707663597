import React, { useState, useEffect } from 'react'
import NavigationBar from './NavigationBar'
import { getCurrentUser , getUserInfo } from '../../services/userService'
import { Redirect } from 'react-router-dom'
import Workspace from './Workspace'
import ExternalIcon from "../asset/img/externalLink.png"

const Home = () => {
    const hour = new Date().getHours();
    const [firstname, setFirstname] = useState('') ; 


    if (!getCurrentUser().userId  ) return <Redirect to="/" /> ; 

    return (
        <div className="home-block">
            {/* <Sidepanel isOpenSidePanel={isOpenSidePanel}/> */}
            <div className="home-content">
                <NavigationBar 
                    head={`Good ${hour < 12 ? "Morning" : hour < 18 ? "Afternoon" : "Evening"}${firstname?`, ${firstname} `: ''}`} 
                    desc = {`Experience Refine AI's through Demos Here`}
                    // desc={{ __html: `Experience <a href="https://drive.google.com/drive/folders/1t1I_dgQ4cHTXc5AcdMak_1GXm3Z6yYbl?usp=drive_link" target="_blank" > Refine AI's Business Solutions</a> through Demos Here` }}
                    className=""
                />
                <div className="d-flex row justify-content-center" >
                    {/* <Workspace /> */}

                    <div className="card mb-3 mt-5 ml-3 mr-3 " >
                        <div className="card-body">
                            <h5 className="card-title">Smart Staffing </h5>
                            <p className="card-text"> Security Staff Recommendation & Planning  </p>
                            <a href="/staffing" target='_blank' className="btn btn-primary"> Go </a>
                            <a href="https://drive.google.com/drive/folders/1s_zW0psY81fHqKGaX9pg5nWWIC7MZEFQ?usp=drive_link" target='_blank' className="ml-2"> Google Drive <img src={ExternalIcon} alt="" style={{ height:'14px' , width:'14px'}} /> </a>
                            {/* <a href="https://drive.google.com/file/d/1uYoKEvrheN4AlTYvrXIeIGRFXhhxpl_S/view?usp=drive_link" target='_blank' className="ml-2"> Video <img src={ExternalIcon} alt="" style={{ height:'14px' , width:'14px'}} /> </a> */}
                        </div>
                    </div>


                    <div className="card mb-3 mt-5 ml-3 mr-3 ">
                        <div className="card-body">
                            <h5 className="card-title">Merck Segments </h5>
                            <p className="card-text">AI Co-Pilot for Market Research & Sales Planning </p>
                            <a href="https://www.figma.com/proto/rnmKhEJ42lPZqtJ3EnJqZK/chartscore?node-id=1381-5320&starting-point-node-id=1381%3A5320&mode=design&t=xQ25ARN3EExLPAob-1" target='_blank' className="btn btn-primary"> Go </a>
                            <a href="https://vimeo.com/923759323/1eede176dd?share=copy" target='_blank' className="ml-2"> Video <img src={ExternalIcon} alt="" style={{ height:'14px' , width:'14px'}} /> </a>
                            <a href="https://drive.google.com/drive/u/0/folders/1V7_DunmvTlaNQLhOzJkHPPZC786WqGMI" target='_blank' className="ml-2"> Google Drive <img src={ExternalIcon} alt="" style={{ height:'14px' , width:'14px'}} /> </a>
                        </div>
                    </div>


                    <div className="card mb-3 mt-5 ml-3 mr-3 ">
                        <div className="card-body">
                            <h5 className="card-title">DrugDrone</h5>
                            <p className="card-text">AI-powered guide to Pfizer's medication portfolio</p>
                            <a href="/pfizer" target='_blank' className="btn btn-primary"> Go </a>
                            <a href="https://vimeo.com/916727423?share=copy" target='_blank' className="ml-2"> Video <img src={ExternalIcon} alt="" style={{ height:'14px' , width:'14px'}} /> </a>
                        </div>
                    </div>

                    <div className="card mb-3 mt-5 ml-3 mr-3 ">
                        <div className="card-body">
                            <h5 className="card-title">Zipscore Segments</h5>
                            <p className="card-text">AI Co-Pilot for Market Research & location planning</p>
                            <a href="/zipscore/segments" target='_blank' className="btn btn-primary"> Go </a>
                            <a href="https://vimeo.com/916727423?share=copy" target='_blank' className="ml-2"> Video <img src={ExternalIcon} alt="" style={{ height:'14px' , width:'14px'}} /> </a>
                        </div>
                    </div>

                    <div className="card mb-3 mt-5 ml-3 mr-3">
                        <div className="card-body">
                            <h5 className="card-title">Loop - AI Agent</h5>
                            <p className="card-text">Automate anything. Text-to-action AI Agent powered by GPT-3.5 turbo</p>
                            <a href="/loop" target='_blank' className="btn btn-primary"> Go </a>
                        </div>
                    </div> 

                </div>
            </div>
        </div>  
    )
}

export default Home
