import React, { useEffect , useState , useRef  } from "react";
import NavigationBar from '../../../homeLoggedIn/NavigationBar'
import { useParams } from "react-router-dom";
import DOMPurify from 'dompurify';
import { useForm } from 'react-hook-form' ; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import PromptSuggestions from "./promptSuggestions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getCopilotCompletion , getCopilotCompletionStream  } from "../../../../services/pfizerService";
import aiProfile from "../../../asset/img/ai.png"
import SendIcon from "../../../common/svgs/sendIcon"
import PromptLibrary from "./promptLibrary" ; 
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import conversationHistory from './conversationHistory.json' ; 

import { Editor } from '@tinymce/tinymce-react';
import "./copilot.css" ; 

const schema = yup.object({
        prompt: yup.string().required('Prompt is required').max(3200, 'Max length exceeded')
}).required();


const Copilot = () => {
    const [isOpenSidePanel, setisOpenSidePanel] = React.useState(false);
    const [loading, setLoading]= useState(false);  
    const { name, copilotName, task } = useParams();
    const [beastMode, setBeastMode]= useState(false);
    const [conversation, setConversation]=useState([]); 
    const [copilotDetails, setCopilotDetails]= useState([])
    const [prompt, setPrompt]= useState('')
    const [completion, setCompletion]= useState('')
    const [completionLoading, setCompletionLoading] = useState(false) ;
    const [completionError, setCompletionError] = useState(true)
    const [openDrawer, setOpenDrawer] = useState(!true);
    const [completionSettings , setCompletionSettings] = useState({
        temperature: 1,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        best_of: 1
    }) 
    const [ completionSettingsExists, setCompletionSettingsExists ] = useState(false) ;
    const [fileId, setFileId]= useState('')
    const [ sourceBlob, setSourceBlob ]= useState('')
    const [ asset, setAsset ]= useState('')
    const [ assetSaved, setAssetSaved] = useState(false)
    const [err, setErr]= useState(''); 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lastwords, setLastwords] = useState('')

    const chatContainerRef = useRef(null);
    const chatEndRef = useRef(null); 
    
    const history= useHistory();

    const { register, handleSubmit, formState: { errors } , reset } = useForm({
        resolver: yupResolver(schema)
    }); 
    const editorRef = useRef(null);


    useEffect(() => {
        const fetchModelDetails = async () => {
            try {
                // let resp_ft = await getFinetuneHyperparameters(name, copilotName);
                setLoading(true);
                // get conversation history and asset html for tiny MCE
                let resp2= conversationHistory ; 
                
                let convHistory = [] ; 
                resp2.data.conv.forEach(item => {
                    item.choices.forEach(choice => {
                        convHistory.push(choice.message);
                    });
                });
                setConversation([...convHistory])
                setAsset(resp2.data.asset.content)
                setPrompt('resp_card.data.prompt');
                setLoading(false);
            } catch (error) {
                // console.log(error)
                if (error.response && error.response.status === 404) {
                    history.push("/404");
                }
            }
        };
         fetchModelDetails();
        }, []); 


        
        useEffect(() => {
            const codeElements = document.querySelectorAll('.chatBox .msg p > pre > code[class^="language-"]');
            codeElements.forEach(el => {
                const langMatch = el.className.match(/language-(\w+)/);
                if (langMatch && langMatch[1]) {
                    // console.log(`Setting data-lang for language: ${langMatch[1]}`);
                    el.parentElement.setAttribute('data-lang', langMatch[1]); // set on the <pre> parent
                }
            });
        }, [conversation]);



      const onSubmit = async (data) => {
          setErr('')
          setCompletionLoading(true)
         
          try {
                setConversation(prevConversation => [
                    ...prevConversation,
                    { role: 'user', content: data.prompt }
                ]);

                // const apiUrl = 'http://localhost:3001/v1'
                // const resp = await fetch(`${apiUrl}/demo/pfizer/copilot/completion`, {
                //     method: "post",
                //     headers: {
                //         Accept: "application/json, text/plain, */*",
                //         "Content-Type": "application/json",
                //         "x-auth-texti-demo": localStorage.getItem('x-auth-texti-demo'),
                //         "access-control-expose-headers": "x-auth-texti-demo"
                //     },
                //     body: JSON.stringify({ prompt: data.prompt }),
                // });

                const resp= await getCopilotCompletionStream(data.prompt)

                if (!resp.ok || !resp.body) {
                    setCompletionError(true)
                    setConversation(prevConversation => [
                        ...prevConversation,
                        { role: 'assistant', content: resp.data.errmessage , err: true}
                    ]);
                    setCompletionLoading(false) 
                    throw resp.statusText;
                }
                // Here we start prepping for the streaming response
                const reader = resp.body.getReader();
                const decoder = new TextDecoder();
                const loopRunner = true;
                let result = "";
            
                while (loopRunner) {
                    // Here we start reading the stream, until its done.
                    const { value, done } = await reader.read();
                    if (done) {
                        break;
                    }
                    const decodedChunk = decoder.decode(value, { stream: true });
                    // setAnswer(answer => answer + decodedChunk); // update state with new chunk
                    let compl = decodedChunk ;
                    result += compl;

                    setLastwords(result)
                    reset();
                }
                setLastwords("")
                setCompletion(result)
                setPrompt(data.prompt)
                setCompletionLoading(false)
                setConversation(prevConversation => [
                    ...prevConversation,
                    { role: 'assistant', content: result }
                ]);

            /*
            const resp = await getCopilotCompletion(data.prompt )
            if (!resp.data.err){
                let compl = resp.data.completionObj.choices[0].message.content.trim() ; 
                setCompletion(compl)
                setPrompt(data.prompt)
                setCompletionLoading(false)
                setConversation(prevConversation => [
                    ...prevConversation,
                    { role: 'assistant', content: compl }
                ]);
                reset();
            } else if (resp.data.err){
                setCompletionError(true)
                setConversation(prevConversation => [
                    ...prevConversation,
                    { role: 'assistant', content: 'Invalid request. Please retry. Note: There is a 15-message limit for this demo. Have you exceeded it?' , err: true}
                ]);
                setCompletionLoading(false) 
            }
            */
            
        } catch (ex) {
            
            if (ex.response && ex.response.status === 404){
                setErr(ex.response.data)
              }
            // setCompletion('error occured')
            setCompletionLoading(false)
        }
    }

    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView();
        }
    }, [conversation, lastwords]);


    const log = async() => { 
        const sanitizedHTML = DOMPurify.sanitize(editorRef.current.getContent());
        if (editorRef.current) {
          setAssetSaved(true)

          // await updateAssetContent(name, copilotName , sanitizedHTML) ;
          window.alert("The document cannot be saved currently");

          setTimeout(() => {
            setAssetSaved(false)
          }, 3000);
        }
  
      };

      const toggleModal = () => {
        setIsModalOpen(prevState => !prevState);
      };
    

    return (
        <>  
            
            <div className="custom-background-copilot">
                        {
                            !isModalOpen && <NavigationBar 
                                head={`DrugDrone `}
                                desc= {`Your co-pilot with answers on 328 Pfizer drugs I've been trained on.`}
                                showSupportForm = { false }
                            /> 
                        }
                                <div className={`Editer_container ${beastMode ? "activeEditer_container" : ""}`}>    

                                    <div className="chatBox justify-content-center" ref={chatContainerRef} >
                                        <div className="custom-control custom-switch beast_mode">
                                            <input type="checkbox" className="custom-control-input" onClick={() => setBeastMode(!beastMode)} id="customSwitch1" />
                                            {/* <label className="custom-control-label" htmlFor="customSwitch1">Beast mode</label> */}
                                            <label className="custom-control-label" htmlFor="customSwitch1">Editor </label>
                                        </div>
                                                {
                                                    conversation.length === 0 && 
                                                    <PromptSuggestions                
                                                        err={err} 
                                                        setErr={setErr} 
                                                        completionLoading={completionLoading} 
                                                        setCompletionLoading={setCompletionLoading} 
                                                        conversation={conversation} 
                                                        setConversation={setConversation} 
                                                        completion={completion} 
                                                        setCompletion={setCompletion} 
                                                        prompt={prompt} 
                                                        setPrompt={setPrompt} 
                                                        setCompletionError={setCompletionError} 
                                                        data_name={name}
                                                        copilot_name={copilotName}
                                                        completionSettings= {completionSettings}
                                                        lastwords = {lastwords} 
                                                        setLastwords= {setLastwords }
                                                    />
                                                }

                            {
                                conversation.length > 0  && <div className="chatData"  style={{height: '100%', overflowY: 'auto'}}  >
                                {
                                    conversation.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {
                                                item.role === 'user' &&
                                                <div className="msg msgadd mt-2">
                                                <p> {item.content} </p>
                                                
                                                </div>
                                            }
                                            {
                                                item.role === 'assistant' && !item.err && 
                                                <>                                                            
                                                    <div className="msg msgin">
                                                            <img src={aiProfile} className="mt-2" alt="aiProfile" />
                                                            <div className="">
                                                                <p>
                                                                    <ReactMarkdown 
                                                                        children={item.content} 
                                                                        remarkPlugins={[gfm]} 
                                                                    /> 
                                                                </p>   
                                                            </div>
                                                    </div>
                                                
                                                </>
                                            }
                                            {
                                                item.role === 'assistant' && item.err && 
                                                <> 
                                                    <div className="text-center mb-2">
                                                        <p className="errorTextForm">{item.content} </p>
                                                    </div>
                                                </>
                                            }
                                        </React.Fragment>
                                    ))
                                }   
                                {
                                    lastwords && (
                                        <div className="msg msgin">
                                            <img src={aiProfile} className="mt-2" alt="aiProfile" />
                                            {/* <img src={Logo} className="mt-2" alt="aiProfile" /> */}
                                            <div className="">
                                                {/* <p>{item.content} </p> */}
                                                {/* <p> <ReactMarkdown>{item.content}</ReactMarkdown> </p> */}
                                                {/* <p> <ReactMarkdown children={item.content} remarkPlugins={[remarkGfm]} /> </p> */}
                                                <p> 
                                                    <ReactMarkdown 
                                                        children={lastwords} 
                                                        remarkPlugins={[gfm]} 
                                                    /> 
                                                </p>
                                                {/* <ReactMarkdown plugins={[gfm]}>{item.content}</ReactMarkdown> */}
                                            </div>
                                        </div>
                                    )
                                }
                                <div ref={chatEndRef}></div>   
                                </div>
                            }                


                                                    <PromptLibrary 
                                                        err={err} 
                                                        setErr={setErr} 
                                                        completionLoading={completionLoading} 
                                                        setCompletionLoading={setCompletionLoading} 
                                                        conversation={conversation} 
                                                        setConversation={setConversation} 
                                                        completion={completion} 
                                                        setCompletion={setCompletion} 
                                                        prompt={prompt} 
                                                        setPrompt={setPrompt} 
                                                        setCompletionError={setCompletionError} 
                                                        isOpen={isModalOpen}
                                                        isModalOpen = { isModalOpen }
                                                        setIsModalOpen = { setIsModalOpen }
                                                        onRequestClose={toggleModal} 
                                                        lastwords = {lastwords} 
                                                        setLastwords= {setLastwords }
                                                    />

                                                    { !completionLoading && 
                                                        <>
                                                            {/* <div className="AdvancedBtnGroup">
                                                                <button className="btn active"  onClick={(() => setOpenDrawer(!openDrawer))}>Advanced Options</button>
                                                            </div> */}
                                                            <div 
                                                                onClick={toggleModal} 
                                                                className="mb-2" 
                                                                style={{color: 'white' , cursor : 'default' , textDecoration : 'underline' , fontWeight : '600px'}}
                                                            > 
                                                                Prompt Library 
                                                            </div>
                                                             
                                                            <form onSubmit={handleSubmit(onSubmit)} className="mt-1">
                                                                <div className="msgBox d-flex align-items-center"> 
                                                                    
                                                                    <div className="containt d-flex">
                                                                        <textarea
                                                                            type="text"
                                                                            {...register("prompt")} 
                                                                            className='increasing'
                                                                            placeholder="Write here..." 
                                                                        >
                                                                        </textarea>
                                                                        { errors.prompt && <span className='errorTextForm mt-1'> {errors.prompt.message} </span> } 
                                                                    </div>
                                                                    
                                                                    <button className="sendBtn"><SendIcon style={{border: 'none!important'}}/></button>
                                                                </div>
                                                            </form>
                                                        </>
                                                    }
                                                    {
                                                        completionLoading && <div className="text-center mt-4 align-items-center">
                                                            <div class="spinner-grow spinner-grow-sm text-light mr-3 " role="status"> <span class="sr-only"></span> </div>
                                                            <div class="spinner-grow spinner-grow-sm text-light mr-3" role="status"> <span class="sr-only"></span> </div>
                                                            <div class="spinner-grow spinner-grow-sm text-light" role="status"> <span class="sr-only"></span> </div>
                                                        </div>
                                                    }
                                                {err && !completion && <span className="errorTextForm" dangerouslySetInnerHTML={{ __html: err }}/>} 
                                    </div>
    
                                            {/* <Settings 
                                                openDrawer={openDrawer} 
                                                setOpenDrawer={setOpenDrawer} 
                                                setCompletionSettings={setCompletionSettings} 
                                                completionSettingsExists = {completionSettingsExists} 
                                                setCompletionSettingsExists = {setCompletionSettingsExists}
                                            /> */}
                                            {/* <AudioRecorder/> */}
                                            

                                            {
                                                beastMode && <div className="Computation-time Classification-computation-time" >
                                                <div className="custom-control custom-switch beast_mode">

                                                            <div className="tabs-beast-mode">
                                                                    <ul className="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link active" id="Graph" data-toggle="pill" data-target="#Graph-tab" type="button" role="tab" aria-controls="Graph-tab" aria-selected="true">Editor</button>
                                                                        </li>
                                                                        {/* <li className="nav-item" role="presentation">
                                                                            <button className="nav-link " id="Table" data-toggle="pill" data-target="#Table-tab" type="button" role="tab" aria-controls="Table-tab" aria-selected="false">Data</button>
                                                                        </li> */}
                                                                        
                                                                    </ul>
                                                                    <div className="tab-content" id="pills-tabContent" >
                                                                        
                                                                        <div className="tab-pane fade show active" id="Graph-tab" role="tabpanel" aria-labelledby="Graph" style= {{ width:'inherit !important' }} >
                                                                            <Editor
                                                                                apiKey={process.env.REACT_APP_TINY_MCE_EDITOR}
                                                                                onInit={(evt, editor) => editorRef.current = editor}
                                                                                initialValue= { asset && asset }
                                                                                init={{
                                                                                height: '75vh',
                                                                                width:'100% !important', 
                                                                                menubar: true,
                                                                                branding: false,
                                                                                forced_root_block : "",
                                                                                statusbar: false, 
                                                                                plugins: [
                                                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                                                ],
                                                                                toolbar: 'undo redo | blocks | ' +
                                                                                    'bold italic forecolor | alignleft aligncenter ' +
                                                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                                    'removeformat | help',
                                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px ;  }'
                                                                                }}
                                                                            />
                                                                            <button onClick={log} className={!assetSaved? `tiny-mce-editor-save-button` : `tiny-mce-editor-saved-button`}> 
                                                                                { !assetSaved? 'Save' : 'Saved' }
                                                                            </button>

                                                                        </div>

                                                                        <div className="tab-pane fade " id="Table-tab" role="tabpanel" aria-labelledby="Table">
                                                                            {
                                                                                sourceBlob && <embed src={sourceBlob} className="preview" width="100%" height= '72vh' type="application/pdf" frameborder="0" />
                                                                            }                                                                      
                                                                        </div>

                                                                    </div>
                                                        <>
                                                            
                                                            {/* <button onClick={log}>Log editor content</button> */}
                                                        </>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
            </div>
        </>

    )

}




export default Copilot; 