import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox , Dialog, DialogActions, DialogContent, 
    DialogContentText, DialogTitle, FormControlLabel, Typography } from '@mui/material';
import React, { useState , useEffect } from 'react'
// import './styles/listingsidebar.css'
import FilterListIcon from '@mui/icons-material/FilterList';
import { RangeSlider } from 'rsuite';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import AddIcon from '@mui/icons-material/Add';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

/*
const checkBoxItems = [
    { type: "Bulletin" },
    { type: "Digital Bulletin" },
    { type: "Wallscape" },
    { type: "Poster" },
    { type: "Bulletin1" },
    { type: "Digital Bulletin1" },
    { type: "Wallscape1" },
    { type: "Poster5" },
    { type: "Poster6" },
    { type: "Poster7" },
    { type: "Poster8" },
    { type: "Poster10" },
]
*/

export default function ListingSidebar({ allPlaces, setPlaces, placeTypes }) {
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [seeAll, setSeeAll] = useState(false);

    // Extract type names from the placeTypes object
    const checkBoxItems = Object.keys(placeTypes);

    const handleChange = (type) => {
        setSelectedTypes(prev => {
            const index = prev.indexOf(type);
            if (index === -1) {
                return [...prev, type]; // Add type if not present
            } else {
                return prev.filter(t => t !== type); // Remove type if already present
            }
        });
    };

    // Update displayed places whenever selectedTypes changes
    useEffect(() => {
        if (selectedTypes.length === 0) {
            setPlaces(allPlaces); // Show all places if no filters are selected
        } else {
            const filtered = allPlaces.filter(placeItem =>
                placeItem.types.some(type =>
                    selectedTypes.includes(type) // Direct comparison without case transformation
                )
            );
            setPlaces(filtered.length > 0 ? filtered : allPlaces);
        }
    }, [selectedTypes, allPlaces, setPlaces]);

    return (
        <div className='listing-sidebar-section'>
            <ul className='sidebar-items'>
                <li className='sidebar-item'>
                    <div className="item-title category_title">
                        <h2>Type</h2>
                    </div>
                    {
                        (seeAll ? checkBoxItems : checkBoxItems.slice(0, 7)).map((type, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        onChange={() => handleChange(type)}
                                        checked={selectedTypes.includes(type)}
                                    />
                                }
                                label={type.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
                            />
                        ))
                    }
                    <button className='see_all_Btn' onClick={() => setSeeAll(!seeAll)}>
                        {seeAll ? 'See Less' : 'See All'}
                    </button>
                </li>
            </ul>
        </div>
    );
}