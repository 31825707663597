import React, { useState } from 'react';
import '../../styles/dashboard.css';


import DashboardSidebar from "./dashboardSidebar"



const Statistics = () => {

  return (
    <div className="dashboard" style={{ display:'flex '}}>
    <DashboardSidebar />
    <div className="main-content">
      <div className="integration-container">
         Location based  KPI's examples <br />
         - Average Time to Assign a staff <br />
         - Active Providers vs. Customer Ratio by City 
      </div>
    </div>
  </div>


  );
};

export default Statistics;
