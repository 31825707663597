import React, * as react from "react";
import { Route , Switch } from "react-router-dom";
import { Toaster , toast } from "react-hot-toast"
// import Import from "./components/connectors/steps/import" ;
import ProtectedRoute from "./components/common/protectedRoute";
import './App.css';
import "./components/auth/auth.css"; 
import pfizerHome from "./components/homeLoggedIn/pfizerHome";
import Home from "./components/homeLoggedIn/home";
import logger from "./services/logger";
import SignIn from './components/auth/SignIn'
import ForgotPassword from "./components/auth/ForgotPassword";
import SignUp from "./components/auth/Signup";
import Confirm from "./components/auth/Confirm" ; 
import ResendConfirm from "./components/auth/ResendConfirm" ; 
import ResetPwd from "./components/auth/ResetPwd" ; 
import Logout from "./components/auth/logout";
// import Finetune from "./components/workspace/training" ; 
// import Inference from "./components/workspace/inference" ; 
// import TaskType from "./components/finetune/TaskType";

import CopilotDemo from "./components/finetune/Inference/copilot/copilotDemo"
// import DataImportStatusRedirect from "./components/finetune/DataImport";
// import CardsAll from "./components/cards/CardsAll";
import Account from "./components/homeLoggedIn/Account"; 
import ReactGA from "react-ga4" ;
import NotFoundPage from "./components/common/notfound";
import { Helmet } from "react-helmet";


import InputLoop from "./components/loop/input";
import FinetuneLoop from "./components/loop/finetune";
import KnowledgeLoop from "./components/loop/knowledge";
import OutputLoop from "./components/loop/output";
import FlowLoop from "./components/loop/flow";

// staffing 

import StaffingMain from "./components/staffing/main.jsx" ; 
// import Assign from "./components/staffing/staff-allocation/dashboard/assign" ; 
import Assign from "./components/staffing/staff-allocation/dashboard/assign1" ; 
import Integrations from "./components/staffing/staff-allocation/dashboard/intergations" ; 
import Statistics from "./components/staffing/staff-allocation/dashboard/statistics" ; 
import StaffList from "./components/staffing/staff-allocation/listing/listing" ; 
import StaffDetail from "./components/staffing/staff-allocation/listing/listingdetail/listingdetailpage" ; 
import StaffDatabase from "./components/staffing/staff-allocation/dashboard/staff-database/database" ;
import PartnerRegistration from "./components/staffing/staff-allocation/partnerRegistration" ;

import WorkOrder from "./components/staffing/staff/dashboard/workOrder"
import WorkOrderAccepted from "./components/staffing/staff/dashboard/workOrderAccepted"
import WorkOrderCompleted from "./components/staffing/staff/dashboard/workOrderCompleted"
import Agenda from "./components/staffing/staff/dashboard/agenda" ;
import Performance from "./components/staffing/staff/dashboard/performance" ;
import WorkflowOfferStatus from "./components/staffing/staff-allocation/OfferStatus/WorkflowOfferStatus" ;
import WorkflowStaffPayment from "./components/staffing/staff-allocation/OfferStatus/stepper/payout" ;  
import SecurityStaffRegistration from "./components/staffing/staff/securityStaffRegistration" ;

// Segments 
import Segments from "./components/segments/landing.jsx" ; 
import Report from "./components/segments/report/report.jsx" ; 
import LocationPlanning from "./components/segments/locationPlanning/main.jsx"
import PlacesList from "./components/segments/locationPlanning/places/listing.js" ; 
import PlacesDetail from "./components/segments/locationPlanning/places/listingdetail/detail.js" ;
import Categories from "./components/segments/newSegment/categories.jsx" ;  


import 'rsuite/dist/rsuite.min.css' ; // switch to this later ?? 
import "bootstrap/dist/css/bootstrap.min.css";

class App extends react.Component {
  state = {};

  componentDidMount() {
    // ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID) ; 
    ReactGA.initialize('G-3KC33TK96R') ; 
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    if (process.env.REACT_APP_ENVIRON === "production" ) {
      logger.init()
    }
  }

  render() {
    return (
        <>
            {/* <Helmet> <meta http-equiv="Content-Security-Policy" content="frame-ancestors 'none'" /> </Helmet> */}
            <Toaster position="top-center" reverseOrder={false}/>
            <Switch>


                {/* ========== MSD ==================================== */}

                {/* ========== Staffing ==================================== */}

                
                {/* <ProtectedRoute path="/us/advertising/billboards/:geolevel/:location" component={UsBillboardsList} />  */}
                {/* <ProtectedRoute path="/us/advertising/billboard/:ref" component={UsBillboardDetail} /> */}
                <ProtectedRoute path="/staffing/staff-allocation/dashboard/assign/:job/:staff" component={StaffDetail} />
                <ProtectedRoute path="/staffing/staff-allocation/dashboard/assign/:orderId" component={StaffList} /> 
                <ProtectedRoute path="/staffing/staff-allocation/dashboard/assign" component={Assign} /> 
                <ProtectedRoute path="/staffing/staff-allocation/dashboard/integrations/staff-database" component={StaffDatabase} /> 
                <ProtectedRoute path="/staffing/staff-allocation/dashboard/integrations" component={Integrations} /> 
                <ProtectedRoute path="/staffing/staff-allocation/dashboard/statistics" component={Statistics} /> 

                <ProtectedRoute path="/staffing/staff-allocation/work-order/offer/:offerId/payment" component={WorkflowStaffPayment} />
                <ProtectedRoute path="/staffing/staff-allocation/work-order/offer/:offerId" component={WorkflowOfferStatus} />
                <ProtectedRoute path="/staffing/partner-registration" component={PartnerRegistration} />


                <ProtectedRoute path="/staffing/staff/dashboard/work-order/open" component={WorkOrder} />
                <ProtectedRoute path="/staffing/staff/dashboard/work-order/accepted" component={WorkOrderAccepted} />
                <ProtectedRoute path="/staffing/staff/dashboard/work-order/completed" component={WorkOrderCompleted} />
                <ProtectedRoute path="/staffing/staff/dashboard/agenda" component={Agenda} />
                <ProtectedRoute path="/staffing/staff/dashboard/performance" component={Performance} />
                <ProtectedRoute path="/staffing/security-staff/registration" component={SecurityStaffRegistration} />

                <ProtectedRoute path="/staffing" component={StaffingMain} /> 


                {/* ========== Segments ==================================== */}
                <ProtectedRoute path="/zipscore/lookup/us/:zipcode/:lat/:lng/place/:placeLat/:placeLng" component={ PlacesDetail } />
                <ProtectedRoute path="/zipscore/lookup/us/:zipcode/:lat/:lng/places" component={ PlacesList } />

                <ProtectedRoute path="/zipscore/segments/report/:id/areas" component={ LocationPlanning } />
                <ProtectedRoute path="/zipscore/segments/report/:id" component={ Report } />  
                <ProtectedRoute path="/zipscore/segments/new" component={ Categories } />
                <ProtectedRoute path="/zipscore/segments" component={ Segments } />


                {/* ========== pfizer copilot ==================================== */}

                {/* <ProtectedRoute path="/data/:name" component={ TaskType } /> */}
                <ProtectedRoute path="/pfizer/copilot" component={ CopilotDemo } />    
                <ProtectedRoute path="/notification" component={Notification } />  
                <ProtectedRoute path="/pfizer" component={pfizerHome } />



                {/* ========== AI Agent ==================================== */}
                <ProtectedRoute path="/loop/input" component={ InputLoop } />
                <ProtectedRoute path="/loop/finetune" component={ FinetuneLoop } />
                <ProtectedRoute path="/loop/knowledge" component={ KnowledgeLoop } />
                <ProtectedRoute path="/loop/output" component={ OutputLoop } />
                {/* ==>> create new component that shows all loops <ProtectedRoute path="/loop/flow" component={ FlowLoopAll } /> */}
                <ProtectedRoute path="/loop/flow" component={ FlowLoop } />
                <ProtectedRoute path="/loop" component={ FlowLoop } />

                {/* ========== Landing & Authentication ==================================== */}
                
                <ProtectedRoute path="/home" component={ Home } />
                <Route path="/account" component={ Account }  />
                <Route path="/logout" component={ Logout }  />
                <Route path="/login" component={ SignIn }  />
                <Route path="/confirm/resend" component={ ResendConfirm } />
                <Route path="/confirm" component={Confirm} />
                <Route path="/forgotpassword" component={ ForgotPassword }  />
                <Route path="/pwd/reset" component={ResetPwd} />
                <Route path="/signup" component={ SignUp }  />

                <Route path="/404" component={ NotFoundPage }  />
                <Route path="/" component={ SignIn }  />

            </Switch>
        </>

    );
  }
}

export default App;