import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import OneTap from '../../common/Onetap';
// import LoadingPage from "../../common/tools-ui/loadingPage"  ;
// import Navbar from "../navbar" ;

import AreasComponent from "./areas" ; 
import { getZipcodesBySegmentId } from "../../../services/segmentService";
import { Loader, Placeholder } from 'rsuite';
import { useParams } from 'react-router-dom';
import "../styles/locationPlanning.css" ; 

const Main = () => {
    const [loading, setLoading] = useState(true);
    const [areas, setAreas] = useState([]);
    const [country, setCountry] = useState(null);
    const [zipcodeAreas, setZipcodeAreas] = useState([]);   
    const [segmentData, setSegmentData] = useState({}) ;
    const [activeZipcode, setActiveZipcode] = React.useState('');
    const segmentId = useParams().id; 

    useEffect(() => {
        const country = 'us';
        setCountry(country);

        async function getZipcodes() {
            try {
               const response = await getZipcodesBySegmentId(segmentId);
               const zipcodes = response.data.areas.map(item => item.plz);
               setAreas([...zipcodes]);
               setZipcodeAreas([...response.data.areas])
               setSegmentData(response.data.segment) ;
               setLoading(false);

            } catch (error) {
                // include redirect to 404 page here 
                console.error(error);
            }
        }

        getZipcodes();

        // setLoading(false);
        // setDesc(dts[0].desc);
        // setImgUrl(dts[0].imgSource);
    }, [segmentId]);

    return (
        <>  
            {/* <Navbar /> */}
            {
                !loading && 
                <AreasComponent 
                    areas={areas} 
                    country={country} 
                    setZipcodeAreas={setZipcodeAreas} 
                    zipcodeAreas={zipcodeAreas} 
                    segmentData={segmentData}
                    activeZipcode={activeZipcode}
                    setActiveZipcode={setActiveZipcode}
                />
            }
            {
                loading && 
                <div>
                {/* <Placeholder.Paragraph rows={8} /> */}
                <Loader center content="loading" />
                <Loader center content="loading" />
              </div>
            }
        </>
    );
}

export default Main;
