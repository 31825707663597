import React, { useState , useRef, useEffect } from 'react';
import "../styles/segments.css" ; 
import { Favorite, FavoriteBorder } from '@mui/icons-material'; 
import { Checkbox, FormControlLabel } from '@mui/material';
import shuffle from "../icons/shuffle.png"
import { Toggle } from 'rsuite';
import { Link } from "react-router-dom" ; 

import { getSegmentsList } from "../../../services/segmentService" ;

function shuffleArray(array) {
    let currentIndex = array.length, randomIndex;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}




function PredefinedSegmentCard({ cardId, title , imgUrl, description, zipCount, key  , activeCardIndex }) {

    return (
        <div className={`predefinedSegmentsCard ${key== activeCardIndex+1 ? 'predefinedSegmentsCardSelected' : ''} ` } >

                {/* <img src={imgUrl} alt={'Profile'} className="predefinedSegmentsProfileImg" /> */}
                    
                <div className="d-flex">

                    <span className="predefined-segment-card-header mt-1">{title}</span>
                    <Checkbox className='favorite' icon={<img alt="fav icon" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675180766/free-tools/heart-border.svg" />} checkedIcon={<img alt="fav icon" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675180797/free-tools/heart.svg" />} />
                
                </div>
                <hr className="mt-2" style={{ backgroundColor: '#ffeee7' , opacity:0.4 }}/>
                 {/* <div className="segment-card-section">
                    <span className="segment-card-label">Population</span>
                    <span className="segment-card-value">234</span>
                </div>
                <div className="segment-card-section">
                    <span className="segment-card-label">Households</span>
                    <span className="segment-card-value">34</span>
                </div>  */}

            <div className="segments-description mb-3">{description} </div>
            
            <div className="segment-card-footer justify-content-center">
                <Link to={`/zipscore/segments/report/${cardId}`}> <span className="segment-card-days">Go </span> </Link> 
                {/* <span className="segment-card-overdue">Share </span> */}
            </div>
        </div>
    );
}

const PredefinedSegment = ({showPredefined, setShowPredefined}) => {
    const [activeCardIndex, setActiveCardIndex] = useState(0);

    const [predefinedSegments, setPredefinedSegments] = useState([]);

    useEffect(() => {
        // getSegmentsList().then(({ data }) => {
        //     setPredefinedSegments(data);
        // });

        async function fetchSegments() {
            const resp = await getSegmentsList();
            console.log('+++ segments list ' , resp.data)
            setPredefinedSegments(resp.data);
        }

        fetchSegments();

    }, []);


    function handleShuffle() {
        const shuffledSegments = shuffleArray([...predefinedSegments]);
        setPredefinedSegments(shuffledSegments);
    }

    useEffect(() => {
        const container = document.querySelector('.predefinedSegmentsCardContainer');
    
        const handleScroll = () => {
          const cards = Array.from(container.children);
          const visibleCardIndex = cards.findIndex(card => {
            const { top } = card.getBoundingClientRect();
            const { top: containerTop } = container.getBoundingClientRect();
            return top - containerTop >= 0 && top - containerTop < window.innerHeight;
          });
          if (visibleCardIndex !== -1) {
            setActiveCardIndex(visibleCardIndex);
          }
        };
        container.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => container.removeEventListener('scroll', handleScroll);
      }, []); 
    

    
      return (
        <>
            {/* ===== show this toggle button later when user can create categories of their products ===== */}
            {/* <div className="mt-2 mr-3 justify-content-end text-right"> 
                    <Toggle className="mr-1" size="md" onChange={()=>{setShowPredefined(!showPredefined)}} checked={showPredefined}/>
                    <span> Predefined </span>   
            </div> */}
            <div className="predefinedSegmentsCardContainer">
                {predefinedSegments.map((card, index) => (
                    <>
      
                        <PredefinedSegmentCard 
                         key={card.description_persona.Title} 
                         cardId={card.id}
                         title={card.description_persona.Title}
                         imgUrl={card.imgUrl}
                         description={card.description_persona.Overview }
                         zipCount={card.zipCount}
                         activeCardIndex= {activeCardIndex}
                        />
                    </>
                ))}
            </div>
            <div className="fixedCardNumberDisplay">
                Segment {activeCardIndex + 1} of {predefinedSegments.length}
            </div> 
            {/* <div className="fixedshuffleDisplay" onClick={handleShuffle} >
                <img src={shuffle} alt="Shuffle" />
                <span className="hover-text">Shuffle Segments</span>
            </div> */}

        </>
    );
}

export default PredefinedSegment ; 



