import React from 'react'
// import '../styles/detail.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddCardIcon from '@mui/icons-material/AddCard';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ForestIcon from '@mui/icons-material/Forest';
import { ReactComponent as Report } from './report.svg'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
// import { useNavigate } from 'react-router-dom';

export default function ViewListingDetails({ billboard, places, flow }) {
    // let navigate = useNavigate();

    const { plz, lat, lng, address, type, face, 
        description, size, impressions, price, city, county, state, city_key, county_key, state_key } = billboard ; 
    
    let poiclasses= ['point_restaurant', 'point_lodge', 'point_shop', 'point_park' ]
    let adjustedPoi = Object.keys(places).map((_, i) => poiclasses[i % poiclasses.length])

    return (
        <div className='viewListingDetails_section mt-2'>
            <div className="view_details_box">
                {/* <div className='back_link' onClick={() => navigate('/listing')}><ChevronLeftOutlinedIcon />Back to listing</div> */}
                <div className="view_box">
                    <div className="title">
                        <h2>{`${size} ${type} facing ${face === undefined? "West" : face}`}</h2>
                        <div className="available_box">
                            <FormControlLabel control={<Checkbox className='available_check' icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} />} label='Available' />
                            <Checkbox className='favorite' icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
                        </div>
                    </div>
                    <div className="title">
                        {/* <div className='link'>Browse demographics of <a href="">{plz}</a>, <a href="">{city}</a>, <a href="">{state}</a> </div> */}
                      
                    </div>
                    <div className="d-flex">
                        <div className="address">
                            <img width="16px" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675175047/free-tools/billboard-marker.svg" alt="" />
                            {address}
                        </div>
                        <div className="daily_update mt-2">
                            <div className="daily_impre">
                                <img width="16px" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675174406/free-tools/immpressions.svg" alt="" /> 
                                daily impressions not available
                            </div>
                            <div className="daily_approx">
                                <img width="16px" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675174757/free-tools/prices.svg" alt="" /> 
                                daily price not available
                            </div>
                        </div>
                    </div>
                    <hr className='hr' />
                        <p className='detail mt-2 mb-2'> 
                            {description? description : `Description of this poster is currently not available`}
                        </p>
                    <hr className='hr' />
                    <div className="point_section">
                        <div className="title">
                            {/* <h2>Places nearby </h2> */}
                            <h2> Nearby places within 5 miles </h2>
                        </div>
                        <div className='point'>

                            {
                                Object.entries(places).map(([key,value], index)=> (
                                    <>
                                        <span className={`point_tag ${adjustedPoi[index]} mt-2`}> {value} {key} </span>
                                    </>
                                ))
                            }

                            {/* <span className='point_tag point_restaurant'> {JSON.stringify(places)} </span> */}

                            {/* <span className='point_tag point_restaurant'><ApartmentOutlinedIcon />43 Restaurants</span>
                            <span className='point_tag point_lodge'><HomeIcon />12 Lodges</span>
                            <span className='point_tag point_shop'><StorefrontIcon />02 Grocery Shops</span>
                            <span className='point_tag point_park'><ForestIcon />04 Parks</span> */}
                        </div>
                        {/* <div className="view_detail">Check Footfall Traffic <ChevronRightOutlinedIcon /></div> */}
                    </div>
                    
                        {/* {

                            /* ====== ACTIVATE LATER =================
                            /* =======================================
                            
                        } */}
                        
                        <hr className='hr' />
                        <div className="driving_section">
                                <div className="title">
                                    <h2>Driving Speed</h2>
                                </div>
                                <div className="driving_items">
                                    <div className="driving_item speed">
                                        <h2><DirectionsCarIcon />Driving Speed</h2>
                                        <p> 9.93 mph</p>
                                        
                                    </div>
                                    <div className="driving_item uncapped">
                                        <h2><SpeedOutlinedIcon />Driving Speed Uncapped</h2>
                                        <p>9.93 mph</p>
                                    </div>
                                    <div className="driving_item jam">
                                        <h2><DirectionsCarIcon />Jam Factor</h2>
                                        <p>1.2 </p>
                                    </div>
                                    <div className="driving_item congestion">
                                        <h2><PowerOutlinedIcon />Jam Factor</h2>
                                        <p>1.2</p>
                                    </div> 
                                </div>
                            </div>
                            
                            
                            
                        
                </div>

                <div className="report_section">
                    <Report />
                    <div className='detail'>
                        <p>
                            Target billboards with precision. Request detailed reports on footfall traffic, demographics, and traffic flow data for your chosen locations.
                        </p>
                        <button className='report_btn'>
                            <a href="mailto:mukund@zipscore.ai?subject=Request Report" style={{color:"white"}} >
                                Request Report
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
