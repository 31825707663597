import React, { useState , useRef, useEffect } from 'react';
import { Toggle , Badge  } from 'rsuite';
import { Link } from "react-router-dom" ;
import '../../styles/dashboard.css';
import DashboardSidebar from "./dashboardSidebar"
import { getZendeskTickets } from "../../../../services/staffingService" ;
import JobStreetView from "../OfferStatus/jobStreetView" ; 
import "../../styles/assign1.css"



  const Segments = () => {

    const [showPredefined, setShowPredefined] = useState(!false) ; 
    const [isAutoAssign, setIsAutoAssign] = useState(false) ;
    const [tickets, setTickets] = useState([]) ;
    const [activeCardIndex, setActiveCardIndex] = useState(0);

  
    React.useEffect(() => { 
      fetchTickets()
    },[])
  
    async function fetchTickets() {
      const resp= await getZendeskTickets() ;
      setTickets([...resp.data.tickets]) ;
    }


  function formatJobDateTime(job) {
    const date = new Date(job.customFields.workOrderDate);
    const friendlyDate = date.toLocaleDateString('en-US', {
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
    const [startTime, endTime] = job.customFields.shiftHours.split('-').map(time => `${parseInt(time)} Hrs`);
    return (
      <div className="job-details">
        <div className="staff-required-circle">
          {job.customFields.totalStaffsRequired}
        </div>
        <div className="">
          {
            job.customFields.totalStaffsRequired > 1 ? 'Staffs' : 'Staff'
          } required on {friendlyDate} between {startTime} to {endTime}
        </div>
      </div>
    );
  }

  const allPositionsFulfilled = (job) => {
    const acceptedOffers =  job.offers.filter(offer => offer.status === 'accepted' || offer.status === 'completed' || offer.status === 'paid');
    const totalStaffsRequired = job.customFields.totalStaffsRequired ;
    return acceptedOffers.length === totalStaffsRequired ;
  }
  



    return <> 
        {/* <div className="dashboard">
         <DashboardSidebar />
         <div className="staffing-new-assignments-body">
            <div className="pl-4 pt-3"> <Badge style={{ padding:'3px' }} color="green" content={tickets.length}> <span style={{fontSize:"14px", color:"#db2777", fontWeight: "bold"}}> New Assignments  </span></Badge> </div>
            <div className="staff-assignments-cards-container">
                {
                  tickets.length > 0 && tickets.map((job, index) => {
                    return <>
                      <div className="offer-staffing-street-view-container">
                        <JobStreetView job={job} />
                        <div className="staff-order-header d-flex justify-content-between mt-4">
                                <div className="staff-order-name"> { job.customFields.serviceType.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') } </div>
                                <div className="staff-order-id">#{job.customFields.orderId} </div>
                        </div>
                        <div className="staff-order-address"> { job.formatted_location } </div>
                        <div className="staff-order-total"> { job.customFields.customerName } </div>
                        <div className="staff-order-total">  { formatJobDateTime(job) } </div>
                        
                        <div className="staff-order-total"> 
                                <Link to="/staffing/staff-allocation/dashboard/assign/2017?view=recommended">
                                    Invite 4 more 
                                </Link>
                                — 56% acceptance rate in this area.
                        </div>
                        <hr />
                      </div>
                            </>
                    })
                }
                
            </div>
          </div>
        </div> */}
          <div className="dashboard">
         <DashboardSidebar />
         <div className="staffing-new-assignments-body">
            <div className="pl-4 pt-3"> <Badge style={{ padding:'3px' }} color="green" content={tickets.length}> <span style={{fontSize:"14px", color:"#db2777", fontWeight: "bold"}}> Assignments  </span></Badge> </div>
            <div className="staff-assignments-cards-container">
                {
                  tickets.length > 0 && tickets.map((job, index) => {
                    return <>
                      <div className="offer-staffing-street-view-container">
                        <JobStreetView job={job} />
                        <div className="staff-order-header d-flex justify-content-between mt-4">
                                <div className="staff-order-name"> { job.customFields.serviceType.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') } </div>
                                <div className="staff-order-id">#{job.customFields.orderId} </div>
                        </div>
                        <div className="staff-order-address"> { job.formatted_location } </div>
                        <div className="staff-order-total"> { job.customFields.customerName } </div>
                        <div className="staff-order-total">  { formatJobDateTime(job) } </div>
                        
                        {
                          !job.offers.length && !allPositionsFulfilled(job) &&
                          <div className="staff-order-total"> 
                                  <Link to={`/staffing/staff-allocation/dashboard/assign/${job.customFields.orderId}?view=recommended`}>
                                      <span style={{fontSize:"14px", color:"#db2777", fontWeight: "bold" , textDecoration:"underline"}}> Send Offers </span>
                                  </Link>
                                  — 56% acceptance rate in this area.
                          </div>
                        }
                        {
                          job.offers.length >=1 && !allPositionsFulfilled(job) && (
                            // if total staffs required is equal to total accepted offers 
                            <div className="staff-order-total"> 
                                  <Link to={`/staffing/staff-allocation/dashboard/assign/${job.customFields.orderId}?view=recommended`}>
                                      <span style={{fontSize:"14px", color:"#db2777", fontWeight: "bold" , textDecoration:"underline"}}> Send More Offers </span>
                                  </Link>
                                  — {job.offers.length} {job.offers.length == 1 ? "offer" : "offers"} sent.
                          </div>
                          )
                        }
                        {
                          job.offers.length >=1 && allPositionsFulfilled(job) && (
                            // if total staffs required is equal to total accepted offers 
                            <div className="staff-order-total"> 
                                  <Link to={`/staffing/staff-allocation/dashboard/assign/${job.customFields.orderId}?view=recommended`}>
                                      <span style={{fontSize:"14px", color:"#db2777", fontWeight: "bold" , textDecoration:"underline"}}> Review Offers </span>
                                  </Link>
                                  — {job.offers.length == 1 ? "Position" : "All positions"} fulfilled.
                          </div>
                          )
                        }

                        <hr />
                      </div>
                      </>
                    })
                }
                
            </div>
          </div>
        </div>

    </>

}
export default Segments ; 