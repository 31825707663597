import _ from "lodash" ; 

export function transformSheet(excelArray) {

    let columnFound = false;
    let columns = [];
    let excelMap = {};
    const excelMapArray = [];
    let currentColumnIndex = 0;

    excelArray.forEach((row, i) => {
      if(row.length !== 0) {
        currentColumnIndex = 0;
        excelMap = {};
        row.forEach((field, index) => {
          if(field.length !== 0) {
            if (columnFound) {
              if (currentColumnIndex<columns.length) {
                excelMap[columns[currentColumnIndex]] = field
                currentColumnIndex++;
              }
            } else {
              columns.push(field);
            }
          }
        });

        if (columnFound) {
          excelMapArray.push(excelMap);
        }
        if (!columnFound) columnFound = true;
      }
    });

    return excelMapArray ; 

}

export const fieldChecks= (unmap, notunique, mappedValues)=> {
  
    if (unmap.length > 0) {
      return {
        err: `You have not yet mapped the following fields - ${unmap.join(", ")}. Map unnecessary columns as "other"`,
        ex: true,
      };
    } else if (notunique.filter(col => col !== "other").some(col => mappedValues.indexOf(col) !== mappedValues.lastIndexOf(col))) {
      const duplicateCols = notunique.filter(col => col !== "other" && mappedValues.indexOf(col) !== mappedValues.lastIndexOf(col));
      return {
        err: `You have mapped ${duplicateCols.join(", ")} twice. If you do not want this field, please map it as "other"`,
        ex: true,
      };
    } else if (!mappedValues.includes("prompt")) {
      return {
        err: "Choosing prompt values is necessary",
        ex: true,
      };
    } else if (!mappedValues.includes("completion")) {
      return {
        err: "Choosing completion values is necessary",
        ex: true,
      };
    } else {
      return {
        err: "",
        ex: false,
      };
    }
  ;
}


export const transformImports = ( headerMappingList, dt) => {


        var mapped = []
        dt.map((item, index) => {
            let obj = {}
            Object.entries(item).map((value, key) => {
                if (headerMappingList[value[0]] !== 0 ) {
                    obj[headerMappingList[value[0]]] = value[1];                        
                } else {
                    obj[value[0]] = value[1]
                }
            })  
            mapped.push(obj);
        })
        return mapped
}


export const transformImports_v1 = (headerMappingList, dt) => {

  const mapped = dt.map((item) => {
    const obj = {};

    Object.entries(headerMappingList).forEach(([originalKey, mappedKey]) => {
      if (originalKey in item) {
        obj[mappedKey] = item[originalKey];
      }
    });

    const { prompt, completion } = obj; // Pick only the "prompt" and "completion" columns
    return { prompt, completion };

  });

  return mapped;
};


export const hyphenate = (str) => {
  // Replace spaces and special characters with hyphens and convert to lowercase
  return str.trim().replace(/[^a-zA-Z0-9-]+/g, '-').toLowerCase();
}




