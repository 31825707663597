import React from 'react';
import { getCurrentUser } from '../../services/userService';
import { getStaffUser } from '../../services/staffingService';
import { Redirect , Link  } from 'react-router-dom';
import { Avatar } from 'rsuite';

import './styles/staffing-main.css'; // Importing the CSS file

import policeman from "./icons/policeman.png" ; 
import manager from "./icons/project-manager.png" ; 


const StaffingMain = () => {

    if (!getCurrentUser().userId) return <Redirect to="/" />;

    return (
        <div className="staffing-main">
        <div className="staffing-button-container">
          {/* Provider Block */}
          <div className="staffing-button-block">
            <img className="staffing-icon" src={manager} alt="Provider" />
            <h5 className="staffing-title">Staff Allocation Company</h5>
            <p className="staffing-description">
              Efficiently assign security staff at facilities to meet client needs.
            </p>
            <Link to="/staffing/partner-registration" className="staffing-button">
              Start as New
            </Link>
            <hr />
            <Link to="/staffing/staff-allocation/dashboard/assign" className="staffing-button">
            <Avatar className="avatar" size="xs" circle src="https://i.pravatar.cc/150?u=4" />
              Log In as Nancy from Star Security Services
            </Link>
          </div>
  
          {/* Staff Block */}
          <div className="staffing-button-block">
            <img className="staffing-icon" src={policeman} alt="Staff" />
            <h5 className="staffing-title">Security Staff</h5>
            <p className="staffing-description">
              Accept job assignments and get paid.
            </p>
            <Link to="/staffing/security-staff/registration" className="staffing-button avatar-link">
              Start as new security staff
            </Link>
            <hr />

            <Link to="/staffing/staff/dashboard/work-order/open" className="staffing-button">
                <Avatar className="avatar" size="xs" circle src="https://i.pravatar.cc/150?u=1" />
                Log In as Irene
            </Link>
            {/* <Link to="/staffing/staff/dashboard/work-order" className="staffing-button">
              Log In as Frank
            </Link> */}
          </div>
        </div>
      </div>
    );
}

export default StaffingMain;
