import axios from "axios";
import { toast } from "react-hot-toast";
import logger from "./logger";


axios.interceptors.response.use(null, (error) => {
  
  const expectedError = 
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  
  if (error.response && error.response.status === 400 && error.response.data === 'Invalid token.'){
    setTimeout(() => {
      toast.error("Session Expired.Please Log in again. INCLUDE MESSAGE LOG IN ");
      window.location.href = "/login"
    }, 1000);
  }  else if (!expectedError) {
    logger.logException(error);
    toast.error("An unexpected error occured.");
  }

  return Promise.reject(error);

});

function setJwt(jwt) {
  axios.defaults.headers.common['x-auth-texti'] = jwt ;
  // axios.defaults.headers.common['x-auth-token'] = 'jwt' ;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt
};
