import http from "./http";

let api_segment_dev = "http://localhost:3000" ; 
// let api_segment_prod = "https://api.refineai.com/demo" ; 
let api_segment_prod = "https://api.zipscore.ai" ; 

const api = process.env.REACT_APP_ENVIRON === "development" 
               ? api_segment_dev
               : api_segment_prod ; 



export function getSegmentData( filters, cond, value ) {
    return http.post(api , {
        filters: filters, 
        cond: cond, 
        value: value
    });
}

// http://localhost:3000/segment/us/segmentlist
export function getSegmentsList() {
    return http.get(`${api}/segment/us/list`);
}

// http://localhost:3000/segment/us/2e84 
export function getSegmentDataById(id) {
    return http.get(`${api}/segment/us/${id}`);
}

// http://localhost:3000/segment/us/:id/zipcodes 

export function getZipcodesBySegmentId(id) {
    return http.get(`${api}/segment/us/${id}/zipcodes`);
}


export function getZipcodePlaces(zipcode, lat, lng)  {
    return http.post(`${api}/lookup/us/zipcode/places` , {
        plz: zipcode, 
        lat: lat, 
        lng: lng 
    });
}


// http://localhost:3000/segment/us/income

export function getSegmentsByHighImpactVariableType(highImpactVariableType){
    return http.get(`${api}/segment/us/list/${highImpactVariableType}`);
}