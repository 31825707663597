import React, { useState } from "react";
import { useForm } from 'react-hook-form' ; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import styles from "./styles/addData.module.css" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import CutomButton from "../../common/customButton/customButton"
import { withRouter } from 'react-router'; 
import { Loader, Message } from "rsuite";
import { useHistory } from "react-router-dom";
import { hyphenate } from "../../utils/tools";
import { addName } from "../../services/dataService";


const schema = yup.object({
  wsName: yup.string().min(3).required('Required')
}).required();

const AddData =({ handleCloseModal , setWs, wsId })=> {

  const [loading, setLoading] = useState(false)
  const [message, setMessage]= useState('')
  const { register, handleSubmit, formState: { errors } } = useForm({
      resolver: yupResolver(schema)
  });
  const history = useHistory() ; 

  const onSubmit = async (data)=> {

   // create entry on the database for this name 
   // move to choose a task page -- 
   // note steps finished 
   // history.push(`/data/${data.wsName}`);
    try {
      setLoading(true);
      const name= hyphenate(data.wsName)
      const resp= await addName(name) ;      
      if (resp.status === 200) {
        history.push(`/data/${name}`);
      }
      
      setLoading(false); 
    } catch (ex) {
      setLoading(false);
      if (ex.response && ex.response.status === 400) {
        setMessage(ex.response.data);
      }
    }
  }

  return  <>
      <div className={`${styles.headerDivModal}`} >
      <span> Add Data </span>
      <FontAwesomeIcon onClick = { handleCloseModal } className={`float-right mr-1 ${styles.icon}`} icon={['fas', 'times']} />
    </div>
    <form
        className="form-column align-items-center p-4"
        style={{ width: "100%"}}
        onSubmit={handleSubmit(onSubmit)}
      >       
            <div className={`ml-3 mr-3 ${styles.displayFlex}`}>
                <input 
                    className={ `form-control mt-1 ${styles.textAreaPlaceholder}` }
                    type="text"
                    {...register("wsName", { required: true })}
                    placeholder='Untitled Dataset' 
                />
                {errors.wsName && <span className='errorTextForm'> {errors.wsName.message} </span>}
                {message && <span className='errorTextForm'> {message} </span>}
            </div>
                                  
            <div className= {` mt-3 ml-3 mr-3 mb-3 ${styles.displayFlex} `} >
                <button className={`${styles.loginButton} ${styles.loginText}`} type="submit" >
                    +
                </button>
            </div>
      </form>
  </>
}

export default withRouter(AddData);