import React , { useState , useEffect } from 'react'
import { useForm } from 'react-hook-form'
import GoogleLogin, { GoogleLogout } from 'react-google-login';
import { Link , useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import GoogleIcon from '../asset/auth-icons/gicon.png'
import logo1 from '../asset/auth-icons/logo1.png'
import logo2 from '../asset/auth-icons/logo2.png'
import logo3 from '../asset/auth-icons/logo3.png'
import logo4 from '../asset/auth-icons/logo4.png'
import logo5 from '../asset/auth-icons/logo5.png'
import thumb from '../asset/auth-icons/thumb.png'
import FlowdashImg from '../asset/auth-icons/chris-97be6031dfd8f922ae14d61fa8ff30e7.jpg'
import { getCurrentUser, googleAuth, loginWithJwt, register as registerUser } from '../../services/userService';
import { Loader, Message } from 'rsuite';
import toast from 'react-hot-toast';
import logo from "../asset/img/logo.svg"
import ftsettings from "../asset/img/ft-settings.svg"
// import { gapi } from 'gapi-script';

const schema = yup.object({
    email: yup.string().email('Invalid email format').required('Required') 
}).required();

const SignUp = () => {
    const [loading, setLoading ] = useState(false)
    const [message, setMessage]= useState("")
    const [success, setSuccess]= useState(true)
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const history= useHistory() ; 

    // useEffect(() => {
    //     function start() {
    //       gapi.client.init({
    //         clientId: process.env.REACT_APP_GAUTH,
    //         scope: 'email',
    //       });
    //     }
    
    //     gapi.load('client:auth2', start);
    //   }, []);

    const onSubmit = async (data) => {
        try {
          setLoading(true);
          const resp = await registerUser(data.email);
          if (resp.status === 200) {
            setMessage("We've sent you confirmation email. If the e-mail hasn't arrived within few minutes, please check your spam, bulk or junk mail folder to locate it");
          }
          setLoading(false);
        } catch (ex) {
            setSuccess(false)
            setLoading(false);
          if (ex.response && ex.response.status === 400) {
            setMessage(ex.response.data);
          }
        }
    };

    const responseGoogleSuccess = async (response) => {


        try {
            setLoading(true);
            const args = { profile: response.profileObj , oneTap : false}
            const resp = await googleAuth(args)       
            // const resp = await googleAuth(response.profileObj)
            loginWithJwt(resp.headers['x-auth-texti'])
            
            if (resp.data === "new") {
                window.location.href = `/user/form`
                setLoading(false);
            } else {
                window.location.href = `/home`
                setLoading(false);
            }
            
        } catch (error) {
            setSuccess(false)
            setMessage("Trouble authenticating your google account");
            toast.error("We could'nt authenticate your google account!")
            setLoading(false)
        }

    }

    const responseGoogleFail = (response) => {
        setSuccess(false)
        setMessage("Trouble authenticating your google account");
        toast.error("We could'nt authenticate your google account!")
        setLoading(false)
    }

    if (getCurrentUser()) history.push("/home")

    return (
        <div id='Sign-up' className="sign-up-page">
            <div className='sign-up-block'>
                <div className="Sign-up-inner">
                    <div className="Sign-up-left mt-5 ">
                        <div className='Sign-up-title-block'>

                            <div className="text-center mt-5"> 
                                <Link to="/"> <img className="signUpCenterLogo " src={logo} alt="sign up logo " /> </Link> 
                            </div>

                            <div className="text-center">
                                {
                                    message && 
                                    <>
                                       {
                                           !success ? 
                                            <div className="alert alert-danger mb-4" role="alert">
                                                {message}
                                            </div>
                                            :
                                            <div className="alert alert-success mb-4" role="alert">
                                                {message}
                                            </div>

                                       }
                                    </>
                                }

                            <h3 className="mb-4 mt-2 text-center "> Welcome! Let's set up your account. </h3>
                            </div>
                            <div className='googlebtn-block'>
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GAUTH}
                                    buttonText="Enable"
                                    render={renderProps => (
                                        // <button onClick={renderProps.onClick} style={customStyle}>Enable</button>
                                        <button onClick={renderProps.onClick} className='googlebtn'>
                                            { !loading && 
                                                <>
                                                    <img src={GoogleIcon} alt='gatuh' />                  
                                                    <span className="ml-1"> Sign up for free with Google </span>
                                                </>
                                            }
                                            { loading && <Loader/>}
                                        </button>
                                    )}
                                    onSuccess={responseGoogleSuccess}
                                    onFailure={responseGoogleFail}
                                    cookiePolicy={'single_host_origin'}
                                    //scope="https://www.googleapis.com/auth/spreadsheets.readonly"
                                /> 
                                {/* <button className='googlebtn'><img src={GoogleIcon} /> Sign up for free with Google</button> */}
                            </div>

                            <div className='or-contain mt-3'>
                                <p>or</p>
                            </div>

                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-3'>
                            <div className='form-signup'>
                                <div class="input-icons">
                                    <i class="fa fa-envelope icon"></i>
                                    <input class="input-field"
                                        type="text"
                                        {...register("email")}
                                        placeholder='name@example.com' 
                                    />
                                    {errors.email && <span className='errorTextForm'> {errors.email.message} </span>}
                                </div>
                                <button type="submit" className='signupbtn mt-3'>
                                    { !loading && 'Sign up for free'}
                                    { loading && <Loader/>}
                                </button>
                            </div>
                        </form>

                        <div className='sign-up-content'>
                            <p>No Credit Card Required</p>
                            <p>
                                By creating an account, you agree to our<a  href='https://www.texti.ai/terms' className='link-content' target="_blank" rel="noreferrer"> terms of service </a>and<a href='https://www.texti.ai/privacy' className='link-content' target="_blank" rel="noreferrer"> privacy policy </a>
                            </p>
                        </div>
                        {/* <div className='commany-ads'>
                            <img src={logo1} />
                            <img src={logo2} />
                            <img src={logo3} />
                            <img src={logo4} />
                            <img src={logo5} />

                        </div> */}
                        <div className='sign-In-link-block'>
                            <Link to='/login'>
                                Sign In
                            </Link>
                            <Link to='/confirm/resend'>Didn't receive an email confirmation?</Link>
                        </div>
                    </div>

                </div>
                <div className='sign-up-inner2'>
                    <div className="sign-up-right">
                        <div className='thumb-block'>
                            <img src={ftsettings} alt='registration side icon' style={{width:"100%"}}/>
                        </div>
                        {/* <div className='flowdash'>
                            <div className="flodash-description">
                                <p>
                                    "Flowdash has allowed us to greatly improve our human in the loop processes.
                                    We’ve saved many hours of work while increasing throughput and transparency."
                                </p>
                                <div className="flodash-tab-inner">
                                    <img src={FlowdashImg}/>
                                    <div className="flodash-tab-inner-title">
                                        <h4>Chris Lu</h4>
                                        <h6>Investor, ESO Fund</h6>
                                    </div>
                                </div>
                            </div>

                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp
