import React, { useEffect , useState } from 'react';
import sendicon from "./suggestion_icon.svg"
import { getCopilotCompletion , getCopilotCompletionStream } from "../../../../services/pfizerService";
import suggestions1 from "./suggestions.json" ; 

function PromptSuggestion({
        err, setErr,
        completionLoading, setCompletionLoading,
        conversation, setConversation,
        completion, setCompletion,
        prompt, setPrompt,
        setCompletionError,
        lastwords ,
        setLastwords
    }) {

        // const [suggestions , setSuggestions] = useState([ ...suggestions1 ])
        const suggestions  = [...suggestions1] ; 


    const handleSubmit = async (prompt) => {
    
        setErr('')
        setCompletionLoading(true)
        
        try {
          setConversation(prevConversation => [
              ...prevConversation,
              { role: 'user', content: prompt }
          ]);
          
          /*
          const resp = await getCopilotCompletion(prompt)
          if (!resp.data.err){
              
              let compl = resp.data.completionObj.choices[0].message.content;
              console.log('completion +++', compl)
    
              setCompletion(compl)
              setPrompt(prompt)
              setCompletionLoading(false)
              setConversation(prevConversation => [
                  ...prevConversation,
                  { role: 'assistant', content: compl }
              ]);
            //   reset();
          } else if (resp.data.err){
              setCompletionError(true)
              setConversation(prevConversation => [
                  ...prevConversation,
                  { role: 'assistant', content: 'Bad request. Please Try again', err: true}
              ]);
              setCompletionLoading(false)
          }
          */ 

            const resp= await getCopilotCompletionStream(prompt)

            if (!resp.ok || !resp.body) {
                setCompletionError(true)
                setConversation(prevConversation => [
                    ...prevConversation,
                    { role: 'assistant', content: resp.data.errmessage , err: true}
                ]);
                setCompletionLoading(false) 
                throw resp.statusText;
            }

            const reader = resp.body.getReader();
            const decoder = new TextDecoder();
            const loopRunner = true;
            let result = "";
        
            while (loopRunner) {
                const { value, done } = await reader.read();
                if (done) {
                    break;
                }
                const decodedChunk = decoder.decode(value, { stream: true });
                
                let compl = decodedChunk ;
                result += compl;

                setLastwords(result)
                // reset();
            }
            setLastwords("")
            setCompletion(result)
            setPrompt(prompt)
            setCompletionLoading(false)
            setConversation(prevConversation => [
                ...prevConversation,
                { role: 'assistant', content: result }
            ]);
          
        } catch (ex) {
          if (ex.response && ex.response.status === 404){
              setErr(ex.response.data)
            }
          setCompletionLoading(false)
        }
      }
      
    return (
        <div className="prompt-suggestion-grow" >
            <div className="prompt-suggestion-container">
                <div className="prompt-suggestion-grid">
                    {suggestions.length >= 1 && suggestions.slice(0,5).map((suggestion, index) => (
                        <div key={index} className="prompt-suggestion-item" onClick={ ()=>{ handleSubmit(suggestion.subtitle) } }>
                            <button className="prompt-suggestion-btn">
                                <div className="prompt-suggestion-content">
                                    <div className="prompt-suggestion-tex text-center">
                                        <div className="prompt-suggestion-title">
                                            {suggestion.title}
                                        </div>
                                        <div className="prompt-suggestion-subtitle">
                                            {suggestion.subtitle}
                                        </div>
                                    </div>
                                    <div className="prompt-suggestion-icon">
                                        {/* SVG icon here */}
                                        <img src={sendicon} style={{width: '25px'}} alt="" />
                                    </div>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PromptSuggestion;