import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

function init() {
  Sentry.init({
    dsn:
      "https://0976a01349784519a9bbbc386d24f7df@o4505335622139904.ingest.sentry.io/4505335624630272",
    release: "1-0-0",
    environment: process.env.REACT_APP_ENVIRON,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // 20% 
    tracesSampleRate: 0.2,
  });
}


function logException(error) {
  Sentry.captureException(error);
}

function logMessage(message) {
  console.log('error in logger')
  Sentry.captureMessage(message);
}


export default { init, logException, logMessage };