import React from 'react';
import { Link } from 'react-router-dom'; 

const Navbar = () => {
  return (
    <div className="segments-menu-bar" style={{ zIndex: 500 }}>
      <div className="segments-menu-options">
        <Link to='/zipscore/segments'>
          <span className="segments-menu-option">
            <span className="selected">Segments</span>
          </span>
        </Link>
        <span className="segments-menu-option">Look-Up</span>
        <span className="segments-menu-option">Co-Pilot</span>
        <span className="segments-menu-option">Scribble</span>
      </div>
      <Link to='/zipscore/segments/new'>
          <button className="segments-menu-button">+ Create New Segment</button>
      </Link>
    </div>
  );
};

export default Navbar;


