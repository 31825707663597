import React from 'react';
import './common.css';

import ExternalLinkIcon from "../asset/img/externalLink.png"

/*
export const ConnectionCard = ({ name, endpoint, status }) => {
  return (
    <div className="container mt-4">
    <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
            <div className="connection-card">
                <div className="connection-name">{name}</div>
                <div className="connection-details">
                    <p><span className="text-muted">{endpoint}</span></p>
                    <p><span className={status ? "text-success" : "text-danger"}>
                    {status ? 'Connected' : 'Disconnected'}
                    </span></p>
                </div>
            </div>
        </div>
        </div>
    </div>
  );
};
*/ 

export const ConnectionCard = ({ name, endpoint, status }) => {
    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
            <div className="connection-card">
              <div className="connection-name">{name}</div>
              <div className="connection-details">
                <p><span className="text-muted">{endpoint}</span></p>
                <p><span className={status ? "text-success" : "text-danger"}>
                  {status ? 'Connected' : 'Disconnected'}
                </span></p>
              </div>
              <button className="loop-edit-button">Edit</button>
            </div>
          </div>
        </div>
      </div>
    );
};


export const ComponentCard = ({ title, description }) => {
    return (
      <div className='loop-input-wrapper-card text-center ml-3 mt-3'>
        <div className='loop-input-card-inner'>
          {/* <img src={VoiceIcon} alt="VoiceIcon" /> */}
          <div className='voice-text'>{title}</div>
        </div>
        <div className='loop-card-paratext'>{description}</div>
        <button className='loop-add-button mt-2'> + </button>
      </div>
    );
};

/*
export const CustomCard = ({ title, description, icon }) => {
    return (
      <div className="loop-finetune-card-container">
        <div className="loop-finetune-custom-card">
          <div className="loop-finetune-card-header">
            {icon && <img src={icon} className={`${icon} loop-finetune-card-icon`} style={{width:'120px', height: '120px'}} />}
            <h3>{title}</h3>
          </div>
          <div className="loop-finetune-card-body">
            <p>{description}</p>
          </div>
        </div>
      </div>
    );
  };
  */

  export const CustomCard = ({ title, description, icon, externalDataLink }) => {
    return (
      <div className="loop-finetune-card-container">
        <div className="loop-finetune-custom-card">
          <div className="loop-finetune-card-header">
            {icon && <img src={icon} className={`${icon} loop-finetune-card-icon`} style={{width:'120px', height: '120px'}} />}
            <h3>{title}</h3>
          </div>
          <div className="loop-finetune-card-body">
            <p>{description}</p>
            {externalDataLink && 
            <>
                <div className="loop-finetune-card-external-data">
                  <a href={externalDataLink} target="_blank" rel="noopener noreferrer">Knowledge Hub <img style={{height:'12px', width:'12px'}} src={ExternalLinkIcon} alt="" /> </a>
                </div>
            </>
            }
          </div>
        </div>
      </div>
    );
};


  export const DocumentCard = ({ title, description, documentCount }) => {
    return (
      <div className="loop-finetune-kb-card p-4 ml-4">
        <div className="loop-finetune-kb-card-header">
          <div className="loop-finetune-kb-doc-icon">
            {/* Icon or image representing documents */}
            <i className="flaticon-documents"></i>
          </div>
          <h3 className="loop-finetune-kb-title">{title}</h3>
        </div>
        <div className="loop-finetune-kb-card-body">
          <p className="loop-finetune-kb-description">{description}</p>
          <span className="loop-finetune-kb-count">{documentCount} Documents</span>
        </div>
      </div>
    );
  };
  
  
  

// === use this for multiple COonnections =========== // 
/*
const ConnectionList = () => {
    const connections = [
    { name: "User's API Connection", endpoint: "https://api.example.com", status: true },
  ];

  return (
    <div className="container mt-4">
        <div className="row">
            {connections.map((connection, index) => (
                <ConnectionCard
                    key={index}
                    name={connection.name}
                    endpoint={connection.endpoint}
                    status={connection.status}
                />
            ))}
        </div>
    </div>
  );
};

export default ConnectionList;
*/ 
