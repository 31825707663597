// https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/about-big-calendar--page


// test-token - mukund02006
// nyk_v0_LPQKKmh9Who2Lld6tGyqU4h7dHoT48ooSqDDF0YDzYFm6mt3FrQM9DXXnLjl11Ag

import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import calendarEvents from "../events.json"
import DashboardSidebar from "./dashboardSidebar" ; 
const localizer = momentLocalizer(moment);


const MyCalendar = ({}) => {
    
    const nylasEvents = calendarEvents ; 
    const events = nylasEvents.data.map(event => ({
        title: event.title,
        start: new Date(event.when.start_time * 1000),
        end: new Date(event.when.end_time * 1000),
        desc: event.description
    }));

    return (
        
    <div className="dashboard">
        <DashboardSidebar /> 
        
        <div className="main-content">
          <div className="main-content-inner">
            {/* ===== status tabs ===== */}
                <Calendar
                    className="mt-5 p-4"
                    defaultView='agenda'
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: '80vh' }}
                />
          </div>
          *The agenda view is only possible if the staff has approved calendar access for the staff allocation company.
        </div>
    </div>
        
    );
};


export default MyCalendar;


/*

{
    "request_id": "ae507794-e538-4864-a31d-175e119d68f6",
    "data": [
        {
            "busy": true,
            "calendar_id": "primary",
            "conferencing": {},
            "description": "staffing job\n\nDecline this meeting: https://app.cronofy.com/rts/q_EPztUO",
            "hide_participants": false,
            "ical_uid": "660349fb0841f50a83617317+66034abf9b1b7100eaf635bb-32da3749-partner@event.cronofy.com",
            "organizer": {
                "name": "",
                "email": "mukund02006@gmail.com"
            },
            "participants": [
                {
                    "email": "jha.mukundmadhav@gmail.com",
                    "status": "no"
                },
                {
                    "email": "mukund02006@gmail.com",
                    "status": "yes"
                }
            ],
            "read_only": false,
            "reminders": {
                "use_default": false,
                "overrides": [
                    {
                        "reminder_method": "popup",
                        "reminder_minutes": 10
                    }
                ]
            },
            "sequence": 1711491775,
            "title": "M J (M J Org)",
            "visibility": "default",
            "creator": {
                "name": "",
                "email": "mukund02006@gmail.com"
            },
            "html_link": "https://www.google.com/calendar/event?eid=XzZvcjMwY3BrNzVqNjRjMW82Z29tY2Q5Z2M0czM2ZGhoNnNwajJkcGI2b3IzMGNwa2M1aDZjZWIyNjVoM2VjOWc2MWltMnBobTZjcW00b2hkNmNwNjhvOWo2c3EzaWJiZ2M1cDc4cmo1ZTkwNmF0ajVkcHEyc29yaWR0bjZ1cGpwNXBobXVyOCBtdWt1bmQwMjAwNkBt",
            "grant_id": "a03b9603-52dc-42b3-9e83-645cf35d450d",
            "id": "_6or30cpk75j64c1o6gomcd9gc4s36dhh6spj2dpb6or30cpkc5h6ceb265h3ec9g61im2phm6cqm4ohd6cp68o9j6sq3ibbgc5p78rj5e906atj5dpq2soridtn6upjp5phmur8",
            "object": "event",
            "status": "confirmed",
            "when": {
                "start_timezone": "Europe/Berlin",
                "end_timezone": "Europe/Berlin",
                "object": "timespan",
                "start_time": 1711495800,
                "end_time": 1711497600
            },
            "created_at": 1711491775,
            "updated_at": 1711491829
        }
    ]
}

*/ 
