import http from "./http";

let api_staffing_dev = "http://localhost:3001" ; 
let api_staffing_prod = "https://refineai-apis-v1-9c071037b2a4.herokuapp.com" ; 

const api = process.env.REACT_APP_ENVIRON === "development" 
            ? api_staffing_dev
            : api_staffing_prod ; 



// http://localhost:3001/staffing/nylas/auth
export function nylasOAuth(){
    return http.get(`${api}/staffing/nylas/oauth`);
}



// http://localhost:3001/staffing/nylas/calendar/delete?grantId=undefined

export function unlinkCalendar(grantId){
    return http.delete(`${api}/staffing/nylas/calendar/delete/${grantId}`);
}

export function getStaffUser(){
    return http.get(`${api}/staffing/user`);
}


export function zendeskOAuth(){
    return http.get(`${api}/staffing/zendesk/oauth`);
}

export function unlinkZendesk(){
    return http.delete(`${api}/staffing/zendesk/unlink`);
}

export function getZendeskTickets(){
    return http.get(`${api}/staffing/zendesk/tickets/`);
}

//http://localhost:3001/staffing/security/security

export function getSecurityStaffsAll(){
    return http.get(`${api}/staffing/security/staffs/all`);
}

export function getSecurityStaffsOrderId(orderId){
    return http.get(`${api}/staffing/security/staffs/${orderId}`);
}

export function getOffer(offerId){
    return http.get(`${api}/staffing/offer/${offerId}`);
}

export function createOffer(staff, job){
    return http.post(`${api}/staffing/offer/create`, {staff, job});
}


export function createRevisedOffer(offerId, revisedProviderHourlyRate){
    return http.post(`${api}/staffing/offer/revised/create`, {
        offerId, revisedProviderHourlyRate
    });
}


/* === deprecate this =====  */
export function createFinalOffer(offerId, finalRatePercentage, finalAmount){
    return http.post(`${api}/staffing/offer/final/create`, {
        offerId, finalRatePercentage, finalAmount
    });
}


export function getProviderOffers(){
    return http.get(`${api}/staffing/provider/offers`);
}


export function getCurrentProvider() {
    return http.get(`${api}/staffing/provider/current`); 
}


// router.get('/provider/offer/accept/:offerId', acceptOffer ) ;
export function getAcceptOffer(offerId){
    return http.get(`${api}/staffing/provider/offer/accept/${offerId}`);
}

export function postRejectOffer(offerId, reason){
    return http.post(`${api}/staffing/provider/offer/reject`, {offerId, reason});
}

export function getGeoCheckIn(offerId){
    return http.get(`${api}/staffing/provider/offer/accept/check-in/${offerId}`);
}

export function getGeoCheckOut(offerId){
    return http.get(`${api}/staffing/provider/offer/accept/check-out/${offerId}`);
}