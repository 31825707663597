import React, { useState } from 'react';
import '../../styles/integrations.css';
import { Toggle } from 'rsuite';
import DashboardSidebar from "./dashboardSidebar"
import AutoAssign from "./autoAssign"
import { getStaffUser , unlinkCalendar , nylasOAuth , zendeskOAuth , unlinkZendesk } from "../../../../services/staffingService" ;
import { Link } from "react-router-dom" ;
// https://dribbble.com/shots/19587301-Cloud-Kitchen-Aggregator-POS
import GoogleCalendar from "../../icons/google-calendar.png" ; 
import WhatsApp from "../../icons/whatsapp.png" ; 
import ZendeskIcon from "../../icons/zendesk.png" ; 
import DatabaseIcon from "../../icons/databaseIcon.png" ; 


const Integrations = () => {

  const [staffUser, setStaffUser] = React.useState({}) ;
  const tokenIsValid = isTokenValid(staffUser.integrations);
  const expiryDate = getTokenExpiryDate(staffUser.integrations);


  const integrationsData = [
    { name: 'Gmail', imageUrl: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1715253244/icons/gmail.png', description: 'Streamline your email communication.' },
    { name: 'Outlook', imageUrl: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1715253244/icons/outlook.png', description: 'Organize your calendars and emails.' },
    { name: 'Slack', imageUrl: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1715253778/icons/slack.png', description: 'Centralize team communications.' },
    { name: 'Teams', imageUrl: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1715253244/icons/business.png', description: 'Collaborate in video and chat.' },
    { name: 'WhatsApp', imageUrl: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1715254921/icons/whatsapp.png', description: 'Connect globally with chat and calls.' },
    { name: 'Plaid', imageUrl: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1715253855/icons/plaid.png', description: 'Connect bank for risk assessment & verification' },
    { name: 'Salesforce', imageUrl: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1715253245/icons/salesforce.png', description: 'Sales CRM' },
    { name: 'Hubspot', imageUrl: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1715253628/icons/hubspot.png', description: 'CRM' },
    { name: 'MS SQL Server', imageUrl: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1715254584/icons/ms-sql-server.png', description: 'Manage and analyze large data sets on Azure' },
    { name: 'ID.me', imageUrl: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1715253415/icons/ideme.png', description: 'Seamlessly verify identity' },
    { name: 'Zapier', imageUrl: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1715255369/icons/zapier.png', description: 'Scale success with automation' }
  ];
     
  React.useEffect(() => {
    fetchUser() ; 
  },[]) ;

  async function fetchUser(){
      const { data } = await getStaffUser() ; 
      setStaffUser(data) ; 
  }

  const handleLinkCalendar = async() => {
    const resp = await nylasOAuth()   
    window.location.href = resp.data.authUrl; 
  }

  const handleLinkZendesk = async() => {
    const resp = await zendeskOAuth()   
    window.location.href = resp.data.authUrl; 
  }

  const handleUnlinkCalendar = async(grantId) => { 
    await unlinkCalendar(grantId) ;
    fetchUser() ;
  }

  const handleUnlinkZendesk = async () => {
    await unlinkZendesk();
    fetchUser();
    window.alert("Your access tokens have been deleted. To revoke access of the token, please follow the steps from the documentation at: https://support.zendesk.com/hc/en-us/articles/4408889192858-Managing-access-to-the-Zendesk-API#:~:text=To%20delete%20an%20API%20token,Delete%20on%20the%20right%20side.");
};


  function isTokenValid(integrations) {
    if (!integrations || !integrations.nylas) {
      return false;
    }
    const { expiresIn, created } = integrations.nylas;
    const issueDate = new Date(created);
    const expiryDate = new Date(issueDate.getTime() + expiresIn * 1000); 
    const now = new Date();
    
    return now < expiryDate;
  }

  function getTokenExpiryDate(integrations) {

    if (!integrations || !integrations.nylas) {
      return null;
    }
    const { expiresIn, created } = integrations.nylas;
    const issueDate = new Date(created);
    const expiryDate = new Date(issueDate.getTime() + expiresIn * 1000); // expiresIn is in seconds
    return expiryDate.toLocaleString(); // Formats date and time to local string
  }

  const handleLink = (integrationName) => {
    console.log(`Linking ${integrationName}`);
    // Implement linking logic here, possibly updating staffUser state
  };

  // Handle unlinking integration
  const handleUnlink = (integrationName) => {
    console.log(`Unlinking ${integrationName}`);
    // Implement unlinking logic here, possibly updating staffUser state
  };

  return (
    <div className="dashboard" style={{ display:'flex '}}>
    <DashboardSidebar />

    {
      staffUser && 
      <div className="main-content">
        
        <div className="integration-container">

        <div className="integration-section text-center">
            <div className="integration-section-header">Already connected for you</div>
            {/* Connected integrations go here */}
            <div className="integration-item">
                <img className="integration-icon" src={GoogleCalendar} alt="" />
              <p className="integration-name">Google Calendar</p>
              {
              staffUser.integrations && staffUser.integrations.nylas ?
              <p className="integration-status">
                { staffUser.integrations.nylas.email} <br/>
                { tokenIsValid ? (
                    <>
                      <span className="integration-activate" onClick={()=> handleUnlinkCalendar(staffUser.integrations.nylas.grantId) } > (Unlink) </span>
                      {/* <span> - Token expires on {expiryDate.toLocaleString()}</span> */}
                    </>
                    ) : (
                    <>
                      <span className="integration-activate" onClick={handleLinkCalendar}> (Relink) </span>
                      <span> - Token expired</span>
                    </>
                  )}
                </p>
                : (
                  <p className="integration-activate" onClick={handleLinkCalendar}>
                    Link your calendar
                  </p>
                )}
            </div>

            <div className="integration-item ">
              <img className="integration-icon" src={ZendeskIcon} alt="" />
              <p className="integration-name">Zendesk </p>
                <p className="integration-status">
              
              { 
                staffUser.integrations && staffUser.integrations.zendesk ?  
                  <>
                      { staffUser.integrations.zendesk.email} <br/>
                      <span className="integration-activate" onClick={()=> handleUnlinkZendesk()}  > (Unlink) </span>
                      {/* <span> - Token expires on {expiryDate.toLocaleString()}</span> */}
                  </>
                : <span className="integration-activate" onClick={handleLinkZendesk} > Link </span>
              }
              {/* { staffUser.integrations.zendesk && <p className="integration-activate"> Linked </p> } */}
              </p>
            </div>

            <div className="integration-item ">
              <img className="integration-icon" src='https://res.cloudinary.com/zipscores-collection/image/upload/v1715253245/icons/snowflake.png' alt="" />
              <p className="integration-name"> Snowflake </p>
                <p className="integration-status">
                      refineai.us-east-1.snowflakecomputing.com <br />
                
                      <Link to="/staffing/staff-allocation/dashboard/integrations/staff-database" target="_blank"> 
                        <span className="integration-activate" >
                         Fetch new staffs
                        </span>
                      </Link>
                </p>
            </div>

            <div className="integration-item ">
              <img className="integration-icon" src='https://res.cloudinary.com/zipscores-collection/image/upload/v1715253017/icons/stripe.png' alt="" />
              <p className="integration-name"> Stripe </p>
                <p className="integration-status">
                      Stripe  API and Webhooks<br />
                
                      <a href="https://dashboard.stripe.com/acct_1PEsyqCUwZm8zMLg/test/payments?limit=10" target="_blank" rel="noreferrer"> 
                        <span className="integration-activate" >
                         Payments & Invoices
                        </span>
                      </a>
                </p>
            </div>



        </div>


        <div className="integration-section">
            <div className="integration-section-header">Refine AI connects to 900+ platforms on demand, to name a few - </div>
            {/* New integrations go here */}
            {integrationsData.map((integration) => (
              <div className="integration-item text-center" key={integration.name}>
                <img className="integration-icon" src={integration.imageUrl} alt={integration.name} />
                <p className="integration-name">{integration.name}</p>
                <p className="integration-description">{integration.description}</p>
                <p className="integration-status">
                  {staffUser.integrations && staffUser.integrations[integration.name.toLowerCase()] ?
                    <>Connected as {staffUser.integrations[integration.name.toLowerCase()].email}<br />
                      <span className="integration-activate" onClick={() => handleUnlink(integration.name.toLowerCase())}> (Unlink) </span>
                    </> :
                    <span className="integration-activate" onClick={() => handleLink(integration.name.toLowerCase())}> Link {integration.name} </span>
                  }
                </p>
              </div>
            ))}
          </div>
        


        </div>
      </div>
    }

  </div>


  );
};

export default Integrations;
