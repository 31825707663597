import React from "react";
// import logo from "../asset/img/logo.svg";
// import logo from "../asset/img/stemly-logo.png";
import { Link } from "react-router-dom";
// import logo from "../../../containers/homepage-icon.svg";


const NavBar = () => {

  return (
    <div className='navbar-auth-centered mt-5' >
        <h2 style={{fontWeight: 600}}> Refine AI </h2> 
      {/* <Link to="/">
        <img style={{width: "250px"}} src={logo} alt="logo" />
      </Link> */}
    </div>
  );
};

export default NavBar;