import React, { useState, useEffect } from 'react'
import Sidebar from "./Sidebar/SideBar"
import InputSection from "./Sidebar/InputSection" ; 

import NavigationBar from '../homeLoggedIn/NavigationBar';

import { DocumentCard } from "./common" ; 
import feedback from "../asset/img/feedback.png"


import InputIcon from "../asset/img/InputIcon.svg"
import VoiceIcon from "../asset/img/voice.svg"


const KnowledgeLoop = () => {
    const hour = new Date().getHours();
    const [firstname, setFirstname] = useState('');

    useEffect(() => {

        const userInfo = async () => {
            // let resp = await getUserInfo();
            // setFirstname(resp.data.firstname);
            // if DETAILS is not set to true then redirect to details page. 
            setFirstname('M')
        }
        userInfo();
    })

    return (
        <>
            <NavigationBar head={' Knowledge Hub '} desc={'A centralized repository for all your external data, designed to enhance your models retrieval and generation capabilities'}  />       
            <div className="home-content">
                <div className="d-flex">
                   {/* <Sidebar selected={'knowledge'} /> */}
                   {/* ======== CARDS <div> knowledge section here </div> */}

                   <div className='loop-input-section'>

                        <div className='loop-input-wrapper-main d-flex '>

                            <DocumentCard 
                                title="Legal & Compliance Docs"
                                description="This contains an 80-page document encompassing all the legal and compliance knowledge of your company, which the gen AI model utilizes as its database"
                                icon={feedback}
                                documentCount={150}
                            />

                            <DocumentCard 
                                title="Sharepoint"
                                description="This contains SharePoint documents from the Sales team that assist them in accessing vital information, streamlining workflows, and enhancing decision-making processes."
                                icon={feedback}
                                documentCount={205}
                            />

                       </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default KnowledgeLoop ; 