import React from 'react';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import LaunchIcon from '@mui/icons-material/Launch';
import {Link} from "react-router-dom" ; 



function LocationPlanning({ segmentData }) {

  return (
    <div className="report-app-container">
      <div className="report-top-box-outer">
        <div className="report-top-box-inner-selected">
          Location Planning
        </div>
      </div>

      <div className="report-body-container">
        <div className="report-map-box">
          {/* The map would go here */}
        </div>
        <div className="report-right-container">
          <div className="report-statistics-container">
            <div className="">
              <div className="report-planning-number-box">
                <span className="report-planning-number-box-bold">
                  {segmentData.population.toLocaleString()}
                </span>
                <span className="report-planning-number-box-normal">
                  Residents
                </span>
              </div>
              <div className="report-planning-number-box mt-4" style={{border: '1px solid #E1C9E9', background: '#FCF3FF'}}>
                <span className="report-planning-number-box-bold">
                  {segmentData.proportions.gender.female >= segmentData.proportions.gender.male ? segmentData.proportions.gender.female : segmentData.gender.male}%
                </span>
                <span className="report-planning-number-box-normal">
                  {segmentData.proportions.gender.female >= segmentData.proportions.gender.male ? 'Female' : 'Male'}
                </span>
              </div>
            </div>
            <div className="">
              <div className="report-planning-number-box" style={{backgroundColor:'#EEFCEF', border:'1px solid #B1D8B4'}}>
                <span className="report-planning-number-box-bold">
                  {segmentData.households.toLocaleString()}
                </span>
                <span className="report-planning-number-box-normal">
                  Households
                </span>
              </div>
              <div className="report-planning-number-box mt-4" style={{border: '1px solid #E0D3C5', background: '#FFF6EC'}}>
                <span className="report-planning-number-box-bold">
                  {segmentData.young >= segmentData.old ? segmentData.young : segmentData.old}%
                </span>
                <span className="report-planning-number-box-normal">
                  {segmentData.young >= segmentData.old ? 'under 40' : 'over 40'}
                </span>
              </div>
            </div>
          </div>
          <div className="report-cta-buttons">
            <div className="report-planning-cta-description" style={{width: '540px'}}> 
             {`Increase your sales and market penetration by targeting essential businesses, POIs, and billboard locations across these ${segmentData.zipCount} ZIP Codes.`}
            </div>
            <Link to={`/zipscore/segments/report/${segmentData.id}/areas?segment=${segmentData.description_persona.Title}`} target="_blank">
              {/* <div className="report-cta-button">
                <AdsClickIcon className="mr-2" /> Target 692 ZIP Codes Now
              </div> */}
              <div className="report-cta-button mt-3 ">
                <MapsHomeWorkIcon className="mr-2" /> Begin Location Planning
              </div>
            </Link> 
            {/* <div className="report-cta-button mt-1">
              <MapsHomeWorkIcon className="mr-2" /> Assign Strategic POIs to Your Staff
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationPlanning;
