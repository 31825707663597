import React, { useState, useEffect } from 'react'
import Sidebar from "./Sidebar/SideBar"
import InputSection from "./Sidebar/InputSection" ; 
import InputIcon from "../asset/img/InputIcon.svg"

import NavigationBar from '../homeLoggedIn/NavigationBar';


import {ConnectionCard, ComponentCard } from './common';

const OutputLoop = () => {
    const hour = new Date().getHours();
    const [firstname, setFirstname] = useState('');

    useEffect(() => {

        const userInfo = async () => {
            // let resp = await getUserInfo();
            // setFirstname(resp.data.firstname);
            // if DETAILS is not set to true then redirect to details page. 
            setFirstname('M')
        }
        userInfo();
    })

    return (
        <>
            <NavigationBar head={'Output'} desc={'Select your preferred system for receiving AI Agent outputs, ensuring seamless integration and tailored delivery of AI-generated insights and responses.'}  />       
            <div className="home-content">
                <div className="d-flex">
                    {/* <Sidebar selected={'output'} /> */}
                     
                    <div className='loop-input-section'>
                        {/* <div className='loop-input-wrapper'>
                            <div className='loop-input-text'><img src={InputIcon} style={{ marginRight: "11px", width: "20px", height: "20px" }} /> Multimodal Input (Text, Image, Video) </div>
                        </div> */}

                        <div className='loop-input-wrapper-main d-flex flex-wrap'>
                            <ComponentCard title={'Webhooks'} description={'Sends notifications or data to other systems in real-time.'} />
                            <ComponentCard title={'Email Notifications'}description={'Automatically dispatches email alerts to all the configured reciepients'}  />
                            <ComponentCard title={'WhatsApp'} description={'Receives and processes WhatsApp messages for enhanced customer interaction and support.'} />
                            <ComponentCard title={'Database Updates'} description={'Writes processed data back to specified databases, perform advanced SQL operations as well'}/>
                            <ComponentCard title={'File Exports'} description={'Generates and saves data files, such as CSV or Excel, for record-keeping.'} />
                            <ComponentCard title={'IoT Actions'}   description={'Triggers predefined actions on IoT devices based on processed data.'} />
                       </div>

                       <hr className="mt-5" style={{ width: "70vw", height: "2px", backgroundColor: "#708090", border: "none" }} />
                       <ConnectionCard name={"User's Email Configured"} endpoint={"hello@texti.ai"} status={true} />

                    </div>

                </div>
            </div>
        </>
    )
}

export default OutputLoop ; 