import React, { useState, useEffect } from 'react'
import Sidebar from "./Sidebar/SideBar"
import InputSection from "./Sidebar/InputSection" ; 

import NavigationBar from '../homeLoggedIn/NavigationBar';

import InputIcon from "../asset/img/InputIcon.svg"
import VoiceIcon from "../asset/img/voice.svg" ; 

import {ConnectionCard, ComponentCard } from './common';


const InputLoop = () => {
    const hour = new Date().getHours();
    const [firstname, setFirstname] = useState('');

    useEffect(() => {

        const userInfo = async () => {
            // let resp = await getUserInfo();
            // setFirstname(resp.data.firstname);
            // if DETAILS is not set to true then redirect to details page. 
            setFirstname('M')
        }
        userInfo();
    })

    return (
        <>
            <NavigationBar head={'Multimodal Input'} desc={'Add multimodal input, whether it is text, video, or image, to your AI agent that listens to your requests through other systems'}  />       
            <div className="home-content">
                <div className="d-flex">
                   {/* <Sidebar selected={'input'} /> */}
                   {/* <InputSection /> */}

                   {/* THIS IS THE INPUT SECTION */}
                   <div className='loop-input-section'>
                        {/* <div className='loop-input-wrapper'>
                            <div className='loop-input-text'><img src={InputIcon} style={{ marginRight: "11px", width: "20px", height: "20px" }} /> Multimodal Input (Text, Image, Video) </div>
                        </div> */}
                        <div className='loop-input-wrapper-main d-flex flex-wrap'>
                            <ComponentCard title={'API Endpoints'} description={'Connects to RESTful APIs for seamless integration with external services.'} />
                            <ComponentCard title={'Email'}  description={'Processes information and requests received directly via email.'} />
                            <ComponentCard title={'Salesforce'} description={'Integrates with Salesforce to capture events and data for processing.'} />
                            <ComponentCard title={'SAP'} description={'Taps into SAP systems to leverage enterprise resource planning data.'} />
                            <ComponentCard  title={'Form Submissions'}  description={'Captures data submitted through internal or external web .'} />
                            <ComponentCard title={'IoT Device Streams'}  description={'Integrates with IoT devices to collect and process data streams.'} />
                       </div>
                            <hr className="mt-5" style={{ width: "70vw", height: "2px", backgroundColor: "#708090", border: "none" }} />
                            <ConnectionCard name={"User's API Connection"} endpoint={"https://api.example.com"} status={true} />
                    </div>
                </div>
            </div>
                {/* <div className="ml-5"> Back to loop </div> */}
        </>
    )
}

export default InputLoop ; 