/* eslint-disable default-case */
import React, { Component } from 'react';
import mapboxgl from "mapbox-gl";
import '../../styles/listingmap.css'
import { dropzoneCountries, mapboxStyle , mapboxToken } from "../../../../global" ;


class Map extends Component {

    state = {
        // lng: 10.492188 ,
        // lat: 50.690426 ,
        zoom: 5.3,
        plzSelections: [],
        plzTooltip : true, 
        selectedPopulation: 0 , 
        selectedHouseholds: 0 , 
        download: [], 
        threeD: false,        
        instanceLayers: [] , 
        instanceSources: [], 
        showInstances: true, 
        staffstart: {}
    };

    

    addLayer = (tileset) => { 

        this.map.addSource('postleitzahlen', {
            type: 'vector',
            url: tileset
        });

        this.map.addLayer(
            {
                'id': 'zipcode',
                'type': 'fill',
                'source': 'postleitzahlen', 
                'source-layer': 'postleitzahlen',
                'paint': {
                    'fill-opacity': 0.3,
                    'fill-color': '#8FB5F8',
                },
            },
            'waterway-label'
        );

        this.map.addLayer(
            {
                'id': 'zipcode-boundary',
                'type': 'line',
                'source': 'postleitzahlen', 
                'source-layer': 'postleitzahlen',
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-opacity': 0.2,
                    'line-color': 'grey',
                    'line-width': 0.5,
                }
            },
            'waterway-label'
        )
            
        this.map.addSource('marker-source', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [this.props.job.location.coordinates[0], this.props.job.location.coordinates[1]]
                        }, 
                        'properties': {
                            // icon: 'marker-solid-blue'
                            icon: 'mapbox-marker-icon-blue'
                        }
                    }
                ]
            }
        });

        this.map.addLayer({
                'id': 'marker-layer',
                'type': 'symbol',
                'source': 'marker-source',
                layout: {
                    'icon-image': ['get', 'icon']
                }
        });
    }

    componentDidMount() {

        const mapAttri = dropzoneCountries.filter((o)=> {
            return o.value === "us"
        })        


        mapboxgl.accessToken = mapboxToken ; 
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: mapboxStyle,
            center: [mapAttri[0].lng, mapAttri[0].lat],
            zoom: this.state.zoom,
            attributionControl: false, 
            // pitch: 90
        });

        const thiss = this;

        this.map.on('load', () => {
            this.addLayer(mapAttri[0].tileset) ;
            this.plotInstances() ;  
            this.plotLineBetweenPoints(); 

            this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
            this.map.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');

            // only valid for billboard in zipcode page
            //const zip = [this.props.billboards[0].plz]
            const zip = [this.props.job.customFields.zipCode]

            this.map.setFilter('zipcode', ['in', 'postcode', ...zip ]) ;
            this.map.setPaintProperty("zipcode", 'fill-color', "#8FB5F8");


            this.map.setFilter('zipcode-boundary', ['in', 'postcode', ...zip ]) ;
            // this.map.setPaintProperty("zipcode-boundary", 'line-color', "#F75E04");
            this.map.setPaintProperty("zipcode-boundary", 'line-color', "#db2777");
            this.map.setPaintProperty("zipcode-boundary", 'line-width', 0.2);
            this.map.setPaintProperty("zipcode-boundary", 'line-opacity', 0.5);
        });



        /* === no label required ========== 


        let popup = new mapboxgl.Popup({
            closeButton: false,
        }); 

        // display popup zip code and sales value
        thiss.map.on('mousemove', 'zipcode-boundary', function (e) {
            if (thiss.state.plzTooltip === false) return; 
            popup.setLngLat(e.lngLat).setHTML(
                "<div style=color:grey;text-align:center;>" +
                "<div>" + e.features[0].properties.postcode + "</div>" +
                "<div>" + e.features[0].properties.locality + "</div>" +
                "</div>"
            ).addTo(thiss.map);
        });

        thiss.map.on('mouseleave', 'zipcode-boundary', function () {
            popup.remove();
        });
        */ 


        this.map.flyTo({
            center: [this.props.job.location.coordinates[0], this.props.job.location.coordinates[1]],
            zoom: this.props.zoom ? this.props.zoom : 13.5,
            bearing: 0,
            speed: 1, // make the flying slow
            // curve: 3, // change the speed at which it zooms out
            curve: 3, // change the speed at which it zooms out
            easing: (t) => t,
            essential: true
        });
        
    }

  
    componentDidUpdate(prevProps, prevState, snapshot) {  


        if( prevProps.zip !== this.props.zip ) {

            // const zip = [this.props.zip]
            // const zip = ['10019']
            const zip = [this.props.job.zipcode]

            this.map.setFilter('zipcode', ['in', 'postcode', ...zip ]) ;
            this.map.setPaintProperty("zipcode", 'fill-color', "#8FB5F8");


            this.map.setFilter('zipcode-boundary', ['in', 'postcode', ...zip ]) ;
            this.map.setPaintProperty("zipcode-boundary", 'line-color', "#F75E04");
            this.map.setPaintProperty("zipcode-boundary", 'line-width', 2);
            this.map.setPaintProperty("zipcode-boundary", 'line-opacity', 0.8);

            this.map.removeLayer('marker-layer') 
            this.map.removeSource('marker-source')
            this.map.addSource('marker-source', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': [
                        {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [this.props.job.location.coordinates[0], this.props.job.location.coordinates[1]]
                            }, 
                            'properties': {
                                icon: 'mapbox-marker-icon-blue'
                                // icon: 'policeman'
                            }
                        }
                    ]
                }
            });
            
            this.map.addLayer({
                    'id': 'marker-layer',
                    'type': 'symbol',
                    'source': 'marker-source',
                    layout: {
                        'icon-image': ['get', 'icon']
                    }
            });

            this.map.flyTo({
                center: [this.props.job.coordinates.long, this.props.job.coordinates.lat],
                zoom: 12.2,
                bearing: 0,
                speed: 1, // make the flying slow
                curve: 5, // change the speed at which it zooms out
                easing: (t) => t,
                essential: true
            });
        }

        if (this.props.hoveredStaff !== prevProps.hoveredStaff) {
    
            // Call updateLineStart with the new start coordinates
            if (this.props.hoveredStaff && this.props.hoveredStaff.location.coordinates) {
                this.updateLineStart(this.props.hoveredStaff.location.coordinates);
            }

            /*
            this.map.flyTo({
                center: [this.props.hoveredStaff.location.coordinates[0], this.props.hoveredStaff.location.coordinates[1]],
                zoom: 13.1,
                easing: (t) => t,
                essential: true
            });
            */

            
            this.map.setCenter([
                this.props.hoveredStaff.location.coordinates[0],
                this.props.hoveredStaff.location.coordinates[1]
            ]);
            

  
        }
    }

    componentWillUnmount() {
        this.map.remove();
    }

    setThreeD = (val) => {
        setTimeout(()=>{ 
            const pitch = !val ? 0 : 90
            const bearing = !val ? 0 : 0
            
            this.map.setPitch(pitch)
            this.map.setBearing(bearing)
            this.setState({threeD : !val})
        } , 500)       
    }
    
    plotInstances = async() => {

        const { instanceLayers , instanceSources } = this.state ; 
        const instances = this.props.staffs ;

        if (instances.length<1) return ; 
        
        for(let i = 0; i<instances.length; i++){
            let layer = `layer${i}` ; 

            const sameInstance = instances.filter(m => m.lat === instances[i].location.coordinates[0] && m.lng === instances[i].location.coordinates[1]);

                    this.map.addSource(layer, {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                {
                                    'type': 'Feature',
                                    'geometry': {
                                        'type': 'Point',
                                        'coordinates': [instances[i].location.coordinates[0], instances[i].location.coordinates[1]],
                                         
                                    },
                                    'properties': {
                                        //  icon: sameInstance.length>1 ? 'billboard-multiple': 'livelocation',
                                        // icon: 'policeman',
                                        icon: 'policeman-decent-blue',
                                        // icon: 'policeman-decent-pink',
                                        // icon: 'policeman-live-blue',

                                        // 'size': instances[i].size, 
                                        // 'type': instances[i].type, 
                                        // 'face': instances[i].face, 
                                        // 'address' : instances[i].address
                                        // 'by': instances[i].by, 
                                    }
                                }
                            ]
                        }
                    });

                    this.map.addLayer({
                            'id': layer,
                            'type': 'symbol',
                            'source': layer,
                            layout: {
                                'icon-image': ['get', 'icon'],
                                'icon-allow-overlap': true
                            }
                    });
                    /*
                    let popup = new mapboxgl.Popup({
                        closeButton: !false,
                    });
                    
                    const _this = this;
                    this.map.on('mousemove', layer, function (e) { 

                        let {size, type, face , address} = e.features[0].properties; 
                        let text= `${size}" ${type && type.toLowerCase()} facing ${face && face.toLowerCase()} for outdoor advertising near ${address}`;  

                        if (_this.state.plzTooltip === false) return ;
                        popup.setLngLat(e.lngLat).setHTML(
                            
                            "<div style=color:#555555;text-align:left;font-family: Axiform;>" +
                            '<div class="mt-1" ">'+ text + '<br /></div>' +   
                            //'<div class="mt-1 hover-underline-animation"> <a href="/us/ohio/zip-code-map">' + instances.length + ' advertising billboards in '+ _this.props.zip + '</a> </div>' +
                            '</div>' 
                            
                        ).addTo(_this.map);
                    });
                    */

                    instanceSources.push(layer)
                    instanceLayers.push(layer)  
                }                    
                
    }

    plotLineBetweenPoints = () => {

        const start = {"lat": this.props.staffs[0].location.coordinates[1] , "long": this.props.staffs[0].location.coordinates[0] };
        const end = {"lat": this.props.job.location.coordinates[1] , "long": this.props.job.location.coordinates[0] };
        const { map } = this;
    
        // GeoJSON object for the line
        const geojson = {
            'type': 'Feature',
            'properties': {},
            'geometry': {
                'type': 'LineString',
                'coordinates': [
                    [start.long, start.lat],
                    [end.long, end.lat]
                ]
            }
        };
    
        // Add a source and layer for the line
        map.addSource('route', {
            'type': 'geojson',
            'data': geojson
        });
    
        map.addLayer({
            'id': 'route',
            'type': 'line',
            'source': 'route',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                // 'line-color': '#888',
                // 'line-color': '#3e4784',
                'line-color': '#db2777',
                'line-width': 2
            }
        });
    };
    

    updateLineStart = (newStart) => {
        const { map } = this; // Assuming your map instance is stored in this.map
    
        // Assuming your line source is named 'route' as in your previous example
        const lineSource = map.getSource('route');
        if (lineSource) {
            const lineData = lineSource._data;
            
            // Update the start coordinates
            lineData.geometry.coordinates[0] = [newStart[0], newStart[1]];
    
            // Set the new data
            lineSource.setData(lineData);
        }
    }
    

    render() {

        return (
            <>          
                <div className="map-billboard-list" ref={el =>  this.mapContainer = el}></div>
            </>
         );
    }
}

export default Map ;
