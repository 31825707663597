import http from "./http";
import jwtDecode from "jwt-decode";


import apis  from "../config/apis.json";
const api = process.env.REACT_APP_ENVIRON === "development" 
               ? apis.dev
               : apis.prod




export function updateProvider(provider_name, xkey , orgId ){
    const resp = http.put(`${api}/client/provider/update` , {
      provider_name: provider_name , 
      xkey: xkey ,
      orgId: orgId
    });
    
    return resp ;
}

export function revokeProviderKey(provider_name){
  const resp = http.put(`${api}/client/provider/revoke` , {
    provider_name: provider_name
  });
  
  return resp ;
}

export function getClientInfo(){
  const resp = http.get(`${api}/client/info`);
  
  return resp ;
}