/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import AddData from './AddDataForm';
import styles from "./styles/modal.module.css"
import ReactModal from "react-modal"
import moment from "moment";
import Options from './Options';
import Addplus from "../common/svgs/addplus"
import shuttle from "../asset/img/shuttle.svg"
import database from "../asset/img/database.svg"
import aiProfile from "../asset/img/ai.png"
import instances from "./instances.json" ; 

const WorkspacesCard = () => {

    const [showModal, setShowModal] = useState(false)
    const [Filterdata, setFilterdata] = useState(null)
    const [ws, setWs] = useState([])
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0);
    const [countPilots, setCountPilots] = useState(0);


    useEffect(() => {

        async function fetchWorkspaceList() {
            // const resp = await getDataList();
            setWs([...instances.instances]);
            // setUsers([...resp.data.users]);
            setLoading(false)

        }
        fetchWorkspaceList();


    }, []);


    const handleModal = () => {
        setShowModal(!showModal)
    }


    function determineLink(o) {
        switch (o.type) {
            case 'Data':
                return o.source ;
            case 'Co-Pilot':
                return `/pfizer/copilot`;
            default:
                // Default link if none of the cases match
                return '/home';
        }
    }

    const workspaceItem = (item, image, ws) => {
        return <>
            <div className='workspaces-items'>
                <div className='workspaces-item'>

                    <div className="workspaces-user-name" >
                        
                        <Link to={determineLink(item)} target="_blank" >
                            <div className='avatar d-flex'>
                                <div className="emoji" >
                                    <img src={image} width="30px" alt='data icon' />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <a href={determineLink(item)} target="_blank" rel="noreferrer" > 

                        <span className="hover-underline-animation" >{item.type === 'Data' ? item.data_name : item.type === 'Fine-tune' ? item.ft_name : item.copilot_name}  </span>
                        <div className='padding-days'>{moment(item.created).fromNow()}
                            <div className='progress_card' style={{ fontSize: '13px', color: '#828282', fontWeight: '500px', lineHeight: '17px' }}>
                                {!item.success && !item.failed && <span className='progress_card_item Pending'> Pending </span>}
                                {item.success && <span className='progress_card_item Complete'> Complete </span>}
                                {item.failed && <span className='progress_card_item Failed'> Failed </span>}
                            </div>
                        </div>
                        <hr />
                        <div className="card-body">
                            <div className="description">
                                {!item.success && <p>{`${item.type} currently unavailable`}</p>}
                                {item.success && <p> {item.description} </p>}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </>
    }

    return (
        <div className="workspaces-block">
            <div className="workspaces-inner-block">
                {/* <div className='search-workspaces'>
                    <SearchIcon />
                    <input type="text" placeholder='Search your workspaces' />
                </div> */}
                <div className="workspaces-title-button">
                    <div className="workspaces-title">
                        {/* <h2>Data & Finetunes</h2> */}
                    </div>
                    <div className="workspaces-button">
                        <ReactModal
                            isOpen={showModal}
                            className={styles.Modal}
                            overlayClassName={styles.Overlay}
                            onRequestClose={handleModal}
                            ariaHideApp={false}
                        >
                            <div className="mt-2">
                                <AddData
                                    handleCloseModal={handleModal}
                                />
                            </div>
                        </ReactModal>
                    </div>
                </div>

                {
                    ws.filter(item => item.type === "Co-Pilot").length >=1 && 
                    <div className="workspaces-inner">
                        <div className='workspaces-inner-wrraper'>
                            <h2 className='dataTypeName'>
                                AI Co-Pilot 
                                <span className='viewAll hover-underline-animation' onClick={() => setCountPilots(1)}>
                                    {!loading && ws.filter(item => item.type === "Co-Pilot").length > 4 ? 'View All >' : ''}
                                </span>
                            </h2>
                            <div className="workspaces-item-block" style={{ padding: "5px" }}>
                                {
                                    !loading && (Filterdata || ws).filter((item, index) => item.type === "Co-Pilot").map((item, index) => (index < 4 || countPilots === 1) && workspaceItem(item, aiProfile, ws))
                                }
                            </div>
                        </div>
                    </div>
                }

                <div className="workspaces-inner">
                    <div className='workspaces-inner-wrraper'>
                        
                        <h2 className='dataTypeName'> Pfizer Drugs Covered by AI Co-pilot - {`${ws.length-1} products `}
                        </h2>
                        <div className="workspaces-item-block" style={{ padding: "5px" }}>

                            {
                                !loading && (Filterdata || ws).filter((item, index) => item.type === "Data").map((item, index) => (index < 7 || count === 1) && workspaceItem(item, database, ws))
                            }
                             <div className='workspaces-items add-workspaces' onClick={handleModal}>
                                <div className='workspaces-item'>
                                    <Addplus />
                                    <p className='add-workspaces-text'>Add </p>
                                </div>
                            </div>
                        </div>
                        <div className=" ml-auto text-center mt-4 ">
                            <h5 className='viewAll hover-underline-animation' style={{color: '#795CB2'}} onClick={() => setCount(1) }>
                                        {!loading && ws.filter(item => item.type === "Data").length > 5 ? `View All ${ws.length-1} items >>` : ''}
                            </h5>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default WorkspacesCard