import http from "./http";
import jwtDecode from "jwt-decode";
import apis  from "../config/apis.json";

http.setJwt(getJwt());



const api = process.env.REACT_APP_ENVIRON === "development" 
               ? apis.dev
               : apis.prod


            
export function getJwt() {
    return localStorage.getItem('x-auth-refineai');
}
            


export function register(email){
    return http.post(`${api}/user/register/`, {
        email: email 
    }); 
}

export function googleAuth(dt){
    return http.post(`${api}/user/google/auth`, {
        profile: dt.profile, 
        oneTap: dt.oneTap
    });
}

export function getVerifyConfirmation( tokenStr ){
    const resp = http.post(`${api}/user/confirm/link/verify` , {
        confirmationCode: tokenStr
    });
    return resp ;
}

export function resendConfirmationLink(email){
    return http.post(`${api}/user/confirm/link/resend`, {
        email: email 
    }); 
}

export function getResetPwdLink( email ){
    const resp = http.post(`${api}/user/pwd/reset/link` , {
      email: email
    });
    
    return resp ;
}

export function ResetPwdLinkConfirmation(email, token ){
    const resp = http.post(`${api}/user/pwd/reset/link/verify` , {
      email: email.toLowerCase(), 
      token: token
    });

    return resp ;
}

export function updatePwd(id, email, pwd ){
    const resp = http.put(`${api}/user/pwd/update` , {
      userId: id, 
      email:email.toLowerCase() ,  
      pwd: pwd
    });
    return resp ;
}

export function updateDetails(details ){
    const { firstname , lastname, jobtitle, companyname } = details ; 
    const resp = http.put(`${api}/user/details` , {
        firstname: firstname , 
        lastname: lastname , 
        jobtitle: jobtitle , 
        companyname: companyname 
    });
    return resp ;
}

export function loginWithJwt (jwt) {
    return localStorage.setItem("x-auth-refineai", jwt);
}

export function logout() {
    localStorage.removeItem('x-auth-refineai');
}

/*
export function login(email, password) {
    const response = http.post(`${api}/user/login`, { email, password });
    return response ; 
}
*/


export function mockLogin(email, password) {

    if (email === "demo@refineai.io" && password === "9fzAk6BJ7eGd6") {
        // const mockToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDhiNDU1YzEyNTM4YTUzNTJjNDUyYmM3IiwiY2xpZW50SWQiOiI2NDhiNDU1YzE3NzM4YTUzNTJjYTJiYzQiLCJmaXJzdG5hbWUiOiJEZW1vIiwibGFzdG5hbWUiOiJTdGVtbHkiLCJlbWFpbCI6ImRlbW9Ac3RlbWx5LmFpIiwic3RhdHVzIjoiQWN0aXZlIiwiaXNBZG1pbiI6dHJ1ZSwiY2xpZW50TmFtZSI6IkVGRFFFS0FuekgiLCJzdWJzRXhwaXJ5IjoxNzAwOTA1MjE4LCJsb2dpbkNvdW50IjowLCJpYXQiOjE3MDA5MDUyMTgsImV4cCI6MTcwMDkwNTIxOH0.9oEQirZKFg0rZ5AaFOCs7iTUKXWILgHzAhInJMTBQdM"; // Replace with the token you generated
        const mockToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDhiNDU1YzEyNTM4YTUzNTJjNDUyYmM3IiwiY2xpZW50SWQiOiI2NDhiNDU1YzE3NzM4YTUzNTJjYTJiYzQiLCJmaXJzdG5hbWUiOiJEZW1vIiwibGFzdG5hbWUiOiJSZWZpbmVBSSIsImVtYWlsIjoiZGVtb0ByZWZpbmVhaS5pbyIsInN0YXR1cyI6IkFjdGl2ZSIsImlzQWRtaW4iOnRydWUsImNsaWVudE5hbWUiOiJFRkRRRUtBbnpIIiwic3Vic0V4cGlyeSI6MTcwMDkwNTIxOCwibG9naW5Db3VudCI6MCwiaWF0IjoxNzAwOTA1MjE4LCJleHAiOjE4MDE4MTUyMTh9.a4qHFVixRKB9cepUcB2j8rCpskZT-xv8CoCcH3g8E4A";        
        
        return Promise.resolve({
            data: {
                user: {
                    email: email,
                    firstname: "Demo", // Example data; you can modify it as needed
                    // ... any other mock user data you want to return
                },
                token: mockToken,
            },
            headers: {
                "x-auth-refineai": mockToken
            }
        });
    } else {
        // Simulating an error similar to an Axios error response structure
        return Promise.reject({
            response: {
                status: 400,
                data: "Invalid demo credentials"
            }
        });
    }
}



export async function login(email, password) {
    // const response = await http.post(`${api}/user/login`, { email, password });

    const response = await mockLogin(email, password);
    
    localStorage.setItem('x-auth-refineai', response.headers["x-auth-refineai"]);
    return response ; 
}

export function getCurrentUser() {
    try {
      const jwt = localStorage.getItem('x-auth-refineai');
      return jwtDecode(jwt);
    } catch (ex) {
      return null;
    }
}

/*
export function addUser(newUser){
   return http.post(`${api}/user/add`, { newUser });
}
*/

export function addUser(newUser, requestedBy){
    return http.post(`${api}/user/add`, { newUser: newUser, requestedBy: requestedBy });
 }

export function allUsers(){
    return http.get(`${api}/user/all`)
}

export async function getUserInfo() {
    const response = await http.get(`${api}/user/info`);
    return response ; 
}
