import React, { useState } from 'react';

const variablesData = [
    { imgSrc: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1713341275/payment.svg', variableType: 'income' },
    { imgSrc: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1713341276/office-worker-outline.svg', variableType: 'livingWith' },
    { imgSrc: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1713341275/mortarboard.svg', variableType: 'education' },
    { imgSrc: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1713341275/detached.svg', variableType: 'housingType' },
    { imgSrc: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1713341275/age-group.svg', variableType: 'age' },
    { imgSrc: 'https://res.cloudinary.com/zipscores-collection/image/upload/v1713773549/gender.svg', variableType: 'gender' },

  ];

const HighImpactVariables = ({ segmentData }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const numVisibleVariables = 4; // Number of variables to show at a time


    const handleRightClick = () => {
        if (currentIndex < variablesData.length - numVisibleVariables) {
          setCurrentIndex(currentIndex + numVisibleVariables);
        }
      };
    
      // Function to handle left arrow click
      const handleLeftClick = () => {
        if (currentIndex > 0) {
          setCurrentIndex(currentIndex - numVisibleVariables);
        }
      };

      const findImageSrc = (variableType) => {
        const variable = variablesData.find(v => v.variableType === variableType);
        return variable ? variable.imgSrc : ''; 
      };
    
/*

                { segmentData.variableTypes}
                { segmentData.highImpactVariableText}

          {variablesData.slice(currentIndex, currentIndex + numVisibleVariables).map((variable, index) => (
              
            <div className="report-variableBox" key={index}>
                <img className="report-variableImage" src={variable.imgSrc} alt="" />
                <p>{variable.text}</p>
            </div>
            
            ))}

*/

  return (
    <div className="report-highImpactContainer">
        <h2>High Impact Variable</h2>
        <div className="report-variablesList">
            {segmentData.highImpactVariable.slice(currentIndex, currentIndex + numVisibleVariables).map((item, index) => (
              
            <div className="report-variableBox" key={index}>
                <img className="report-variableImage" src={findImageSrc(item.variableType)} alt="" />
                <p className="mt-2">{item.label}</p>
            </div>

            ))}
        </div>
        {/* <div className="report-variablesWrapper">
            <button onClick={handleLeftClick}  className="navButton leftButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.5999 12.7098C10.5061 12.6169 10.4317 12.5063 10.381 12.3844C10.3302 12.2625 10.3041 12.1318 10.3041 11.9998C10.3041 11.8678 10.3302 11.7371 10.381 11.6152C10.4317 11.4934 10.5061 11.3828 10.5999 11.2898L15.1899 6.70982C15.2836 6.61685 15.358 6.50625 15.4088 6.38439C15.4595 6.26253 15.4857 6.13183 15.4857 5.99982C15.4857 5.8678 15.4595 5.7371 15.4088 5.61524C15.358 5.49338 15.2836 5.38278 15.1899 5.28982C15.0025 5.10356 14.749 4.99902 14.4849 4.99902C14.2207 4.99902 13.9672 5.10356 13.7799 5.28982L9.18986 9.87982C8.62806 10.4423 8.3125 11.2048 8.3125 11.9998C8.3125 12.7948 8.62806 13.5573 9.18986 14.1198L13.7799 18.7098C13.9661 18.8946 14.2175 18.9987 14.4799 18.9998C14.6115 19.0006 14.7419 18.9754 14.8638 18.9256C14.9856 18.8758 15.0964 18.8025 15.1899 18.7098C15.2836 18.6169 15.358 18.5063 15.4088 18.3844C15.4595 18.2625 15.4857 18.1318 15.4857 17.9998C15.4857 17.8678 15.4595 17.7371 15.4088 17.6152C15.358 17.4934 15.2836 17.3828 15.1899 17.2898L10.5999 12.7098Z" fill="#374957"/>
                </svg>
            </button>
            <button onClick={handleRightClick}  className="navButton rightButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M13.4001 12.7098C13.4939 12.6169 13.5683 12.5063 13.619 12.3844C13.6698 12.2625 13.6959 12.1318 13.6959 11.9998C13.6959 11.8678 13.6698 11.7371 13.619 11.6152C13.5683 11.4934 13.4939 11.3828 13.4001 11.2898L8.81014 6.70982C8.71641 6.61685 8.64202 6.50625 8.59125 6.38439C8.54048 6.26253 8.51434 6.13183 8.51434 5.99982C8.51434 5.8678 8.54048 5.7371 8.59125 5.61524C8.64202 5.49338 8.71641 5.38278 8.81014 5.28982C8.9975 5.10356 9.25095 4.99902 9.51514 4.99902C9.77933 4.99902 10.0328 5.10356 10.2201 5.28982L14.8101 9.87982C15.3719 10.4423 15.6875 11.2048 15.6875 11.9998C15.6875 12.7948 15.3719 13.5573 14.8101 14.1198L10.2201 18.7098C10.0339 18.8946 9.78248 18.9987 9.52014 18.9998C9.38853 19.0006 9.25807 18.9754 9.13623 18.9256C9.0144 18.8758 8.90358 18.8025 8.81014 18.7098C8.71641 18.6169 8.64202 18.5063 8.59125 18.3844C8.54048 18.2625 8.51434 18.1318 8.51434 17.9998C8.51434 17.8678 8.54048 17.7371 8.59125 17.6152C8.64202 17.4934 8.71641 17.3828 8.81014 17.2898L13.4001 12.7098Z" fill="white"/>
                </svg>
            </button>
        </div>  */}
    </div>
  );
};

export default HighImpactVariables;
