import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox , Dialog, DialogActions, DialogContent, 
    DialogContentText, DialogTitle, FormControlLabel, Typography } from '@mui/material';
import React, { useState } from 'react';
import '../../styles/listingsidebar.css';
import FilterListIcon from '@mui/icons-material/FilterList';
import { RangeSlider } from 'rsuite';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import AddIcon from '@mui/icons-material/Add';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export default function ListingSidebar({ all, staffs, setStaffs }) {
    const [priceRange, setPriceRange] = useState([32, 75]);
    const [impressions, setImpressions] = useState([25, 300]);
    const [startDate, setStartDate] = useState(new Date());
    const [serviceType, setServiceType] = useState([]); // renamed from billboardType
    
    // Flatten and deduplicate the services array
    const checkBoxItems = Array.from(new Set(all.flatMap(i => i.services)));

    const [seeAll, setSeeAll] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (service) => {
        const updatedServiceType = serviceType.includes(service)
            ? serviceType.filter(s => s !== service)
            : [...serviceType, service];
        
        setServiceType(updatedServiceType);
        updateStaffList(updatedServiceType);
    };

    const updateStaffList = (selectedServices) => {
        const filtered = selectedServices.length
            ? all.filter(staff => staff.services.some(service => selectedServices.includes(service)))
            : all;
        
        setStaffs(filtered);
    };

    return (
        <>
            <div className='listing-sidebar-section'>
                <ul className='sidebar-items'>
                    <li className='sidebar-item'>
                        <div className="item-title category_title">
                            <h2>Service Type</h2>
                        </div>
                        {
                            !seeAll ?
                                checkBoxItems.slice(0, 4).map((m, i) => (
                                    <FormControlLabel key={i} control={<Checkbox onChange={() => handleChange(m)} />} label={m} />
                                )) :
                                checkBoxItems.map((m, i) => (
                                    <FormControlLabel key={i} control={<Checkbox onChange={() => handleChange(m)} />} label={m} />
                                ))
                        }
                        {
                            seeAll ? <span className='see_all_Btn' onClick={() => setSeeAll(false)}>See Less</span> : <span className='see_all_Btn' onClick={() => setSeeAll(true)}>See All</span>
                        }  
                    </li>
                    <li className='sidebar-item'>
                        <div className="item-title">
                            <h2>Hourly Charge</h2>
                            <h3 className='range'>${priceRange[0]} - ${priceRange[1]}</h3>
                            <RangeSlider defaultValue={[35, 45]} min={30} max={75} onChange={(e) => setPriceRange(e)} />
                        </div>
                    </li>
                    <li className='sidebar-item'>
                        <div className="item-title">
                            <h2>Total Work Orders</h2>
                            <h3 className='impressions'>{impressions[0]} - {impressions[1]}</h3>
                            <RangeSlider defaultValue={[25, 250]} min={0} max={350} onChange={(e) => setImpressions(e)} />
                        </div>
                    </li>
                    {/* <li className='sidebar-item'>
                        <div className="item-title">
                            <h2 className='availability'>Availability</h2>
                            <div className="availability-datePicker">
                                <div className="availability-datePicker-icon">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_841_761)">
                                            <path d="M11.0833 1.16667H10.5V0.583333C10.5 0.428624 10.4385 0.280251 10.3291 0.170854C10.2197 0.0614582 10.0714 0 9.91667 0C9.76196 0 9.61358 0.0614582 9.50419 0.170854C9.39479 0.280251 9.33333 0.428624 9.33333 0.583333V1.16667H4.66667V0.583333C4.66667 0.428624 4.60521 0.280251 4.49581 0.170854C4.38642 0.0614582 4.23804 0 4.08333 0C3.92862 0 3.78025 0.0614582 3.67085 0.170854C3.56146 0.280251 3.5 0.428624 3.5 0.583333V1.16667H2.91667C2.1434 1.16759 1.40208 1.47518 0.855295 2.02196C0.308514 2.56874 0.00092625 3.31007 0 4.08333L0 11.0833C0.00092625 11.8566 0.308514 12.5979 0.855295 13.1447C1.40208 13.6915 2.1434 13.9991 2.91667 14H11.0833C11.8566 13.9991 12.5979 13.6915 13.1447 13.1447C13.6915 12.5979 13.9991 11.8566 14 11.0833V4.08333C13.9991 3.31007 13.6915 2.56874 13.1447 2.02196C12.5979 1.47518 11.8566 1.16759 11.0833 1.16667ZM1.16667 4.08333C1.16667 3.6192 1.35104 3.17409 1.67923 2.8459C2.00742 2.51771 2.45254 2.33333 2.91667 2.33333H11.0833C11.5475 2.33333 11.9926 2.51771 12.3208 2.8459C12.649 3.17409 12.8333 3.6192 12.8333 4.08333V4.66667H1.16667V4.08333ZM11.0833 12.8333H2.91667C2.45254 12.8333 2.00742 12.649 1.67923 12.3208C1.35104 11.9926 1.16667 11.5475 1.16667 11.0833V5.83333H12.8333V11.0833C12.8333 11.5475 12.649 11.9926 12.3208 12.3208C11.9926 12.649 11.5475 12.8333 11.0833 12.8333Z" fill="#333333" />
                                            <path d="M7 9.625C7.48325 9.625 7.875 9.23325 7.875 8.75C7.875 8.26675 7.48325 7.875 7 7.875C6.51675 7.875 6.125 8.26675 6.125 8.75C6.125 9.23325 6.51675 9.625 7 9.625Z" fill="#333333" />
                                            <path d="M4.08301 9.625C4.56626 9.625 4.95801 9.23325 4.95801 8.75C4.95801 8.26675 4.56626 7.875 4.08301 7.875C3.59976 7.875 3.20801 8.26675 3.20801 8.75C3.20801 9.23325 3.59976 9.625 4.08301 9.625Z" fill="#333333" />
                                            <path d="M9.91699 9.625C10.4002 9.625 10.792 9.23325 10.792 8.75C10.792 8.26675 10.4002 7.875 9.91699 7.875C9.43374 7.875 9.04199 8.26675 9.04199 8.75C9.04199 9.23325 9.43374 9.625 9.91699 9.625Z" fill="#333333" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_841_761">
                                                <rect width="14" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <DatePicker dateFormat={"dd-MM-yyyy"} selected={startDate} onChange={(date) => setStartDate(date)} />
                                <div className='datepicker-arrow'>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.914 4.78925C10.8597 4.73457 10.7952 4.69118 10.7241 4.66156C10.6531 4.63195 10.5768 4.6167 10.4998 4.6167C10.4228 4.6167 10.3466 4.63195 10.2755 4.66156C10.2044 4.69118 10.1399 4.73457 10.0856 4.78925L7.41398 7.46091C7.35975 7.51558 7.29523 7.55898 7.22415 7.5886C7.15306 7.61821 7.07682 7.63346 6.99981 7.63346C6.9228 7.63346 6.84656 7.61821 6.77547 7.5886C6.70439 7.55898 6.63987 7.51558 6.58564 7.46091L3.91398 4.78925C3.85975 4.73457 3.79523 4.69118 3.72415 4.66156C3.65306 4.63195 3.57682 4.6167 3.49981 4.6167C3.4228 4.6167 3.34656 4.63195 3.27547 4.66156C3.20439 4.69118 3.13987 4.73457 3.08564 4.78925C2.977 4.89854 2.91602 5.04639 2.91602 5.2005C2.91602 5.35461 2.977 5.50245 3.08564 5.61175L5.76314 8.28924C6.09127 8.61696 6.53606 8.80103 6.99981 8.80103C7.46356 8.80103 7.90835 8.61696 8.23648 8.28924L10.914 5.61175C11.0226 5.50245 11.0836 5.35461 11.0836 5.2005C11.0836 5.04639 11.0226 4.89854 10.914 4.78925Z" fill="#333333" />
                                    </svg>
                                </div> 

                            </div>
                        </div>
                    </li> */}
                </ul>
                {/* Filter button if needed */}
            </div>
        </>
    );
}
