import React, { useEffect, useState } from 'react' ; 
import { useParams , Redirect , useHistory , useLocation } from "react-router-dom";
import ListingDetails from './listingitem.jsx' ; 
import ListingDetailsMap from './listingmap.js' ; 
import ListingSidebar from './listingsidebar.js' ; 
// import Header from "../../../look-up/datas/us/Header/" ; 
import { getZipcodePlaces } from "../../../../services/segmentService" ; 
// import LoadingPage from "../../../common/tools-ui/loadingPage" ;
// import Limitwall from "../../../look-up/datas/us/Authwall/indexlimit"; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Loader, Placeholder } from 'rsuite';
// import './styles/listing.css'

const Listing = () => {

    const { zipcode , lat, lng } = useParams() ;
    // const [zipcode, setZipscode ] = useState(useParams().statekey);
    // const [zipcode, setZipscode ] = useState("24986");
    const [limitExceeded, setLimitExceeded] = useState(false)
    const [location, setLocation ] = useState(useParams().location);
    const [geolevel, setGeolevel]= useState(useParams().geolevel) ; 

    const [currentAddress, setCurrentAddress] = useState(zipcode) ;
    const [hoveredPlace, setHoveredPlace] = useState({ place: { location: { latitude: lat, longitude: lng } } } ) ;

    const [allPlaces, setAllPlaces] = useState([]); 
    const [places, setPlaces]= useState([]) ; 
    const [ placeTypes, setPlaceTypes ] = useState([]) ; 
    const [loading, setLoading] = useState(true) ; 
    const [exception, setException] = useState(false) ; 
    const history = useHistory() ; 


    const loc = useLocation();
    const searchParams = new URLSearchParams(loc.search);
    const segmentQuery = searchParams.get('segment');

    useEffect(() => {
        getPlaces()
    }, [])

    const getPlaces = async() => {

        try {

            const resp = await getZipcodePlaces(zipcode, lat, lng)
            setAllPlaces([...resp.data.places])
            setPlaces([...resp.data.places])
            setPlaceTypes(resp.data.placeTypes[0])
            const currLoc = await reverseGeocode(lng, lat) ; 
            setCurrentAddress(currLoc.features[0].properties.full_address)
            setLoading(false)
            
        } catch (error) {
           console.log(error)
        }
    }

    const reverseGeocode = async (longitude, latitude) => {
        try {
            const url = `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${longitude}&latitude=${latitude}&access_token=pk.eyJ1IjoibWotZXBzaWxvbiIsImEiOiJja24wcWV5eTcwcTY5MnZwazA0cTRxMTlwIn0.powZMmJIS2FoR4JY1DFSGg`;
            const response = await fetch(url);
            const data = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            throw new Error('Reverse geocoding failed');
        }
    };



    // const limitwall= <React.Fragment>   
    //     <div className="mt-3 mb-4 text-center " >
    //         <img style={{width:'100%' , maxWidth:'550px'}} src='https://res.cloudinary.com/zipscores-collection/image/upload/v1663332859/11132.jpg' alt="limits"/>
    //         <h2 className="mt-1 mb-5 text-center "> Feature in beta. Today's limit exceeded. Please try again tomorrow. </h2>
    //     </div>
    // </React.Fragment>

    if (exception) return <Redirect to="/404"/> ;

    return (
            <>
                {/* <Header /> */}
                {
                    !loading && 
                    <div id="listing-billboard">
                    <div className='listing-section'>

                        <div className="listing-section-header mt-3 ">
                            {/* <div style={{ paddingLeft: '310px' }} className="title ">
                                <span className="mt-2" > Places in </span>
                            </div> */}
                            {/* <div className="sort_by mt-3">
                                <Dropdown title="Recommended" className="sort_by">
                                    <Dropdown.Item>Option 1</Dropdown.Item>
                                    <Dropdown.Item>Option 2</Dropdown.Item>
                                </Dropdown>
                            </div> */}
                                <div className="sort_by align-items-center">

                                    { segmentQuery && <div className="d-flex " style={{marginRight: '15px'}} ><span> <SettingsAccessibilityIcon style={{fontSize:'20px' , marginRight:'3px'}} /> </span> { segmentQuery }</div> }
                                    {/* <div className="d-flex " style={{marginRight: '15px'}} ><span> <LocationOnIcon style={{fontSize:'18px' , marginRight:'3px'}} /> </span> { currentAddress } (2 miles)  </div> */}
                                    <div className="d-flex " style={{marginRight: '15px'}} ><span> <LocationOnIcon style={{fontSize:'18px' , marginRight:'3px'}} /> </span> { currentAddress } (2 miles)  </div>
                                    <div className="d-flex " style={{marginRight: '35%'}}><span> Popularity <KeyboardArrowDownIcon style={{fontSize:'18px'}} /></span></div>
                                </div>
                        </div>

                        <div className="custom-container">
                            <div className="custom-row mt-2">
                                <div className='sideBar'>
                                    <ListingSidebar places={places} placeTypes={placeTypes} setPlaces={setPlaces} allPlaces={allPlaces} />
                                </div>
                                <div className='listing-content'>
                                    <ListingDetails places={places} currentAddress={currentAddress} 
                                        hoveredPlace={hoveredPlace}
                                        setHoveredPlace={setHoveredPlace}
                                    />
                                    <ListingDetailsMap 
                                        places={places} lat={lat} lng={lng} zipcode={zipcode} 
                                        hoveredPlace={hoveredPlace}
                                        setHovered={setHoveredPlace}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {
                    loading && 
                    <div>
                        <Loader center content="loading" />
                        <Loader center content="loading" />
                    </div>
                }
            </>

    )
}

export default Listing ;


