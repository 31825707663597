import React from "react";
import "../../styles/dashboard-sidebar.css";
import { Link, useLocation } from 'react-router-dom';
import Home from "../../icons/home.png" ; 
import AssignBlue from "../../icons/assign-blue.png";
import IntegrationsBlue from "../../icons/integrations-blue.png";
import AssignWhite from "../../icons/assign-white.png";
import IntegrationsWhite from "../../icons/integrations-white.png";
import StatisticsWhite from "../../icons/statistics-white.png";
import StatisticsBlue from "../../icons/statistics-blue.png";

const Sidebar = () => {
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname.includes(path);
    };

    return (
      <div className="sidebar">
        <div className="sidebar-header">
          {/* Header content */}
        </div>
        <nav className="sidebar-nav">
          <Link to="/staffing/staff-allocation/dashboard/assign" className={`${isActive('assign') ? 'active' : ''}`}>
            <img src={isActive('assign') ? AssignWhite : AssignBlue} height={45} className="icon orders-icon" />
            Assign
          </Link>
          <Link to="/staffing/staff-allocation/dashboard/integrations" className={`${isActive('integrations') ? 'active' : ''}`}>
            <img src={isActive('integrations') ? IntegrationsWhite : IntegrationsBlue} height={45} className="icon orders-icon" />
            Integrations
          </Link>
          <Link to="/staffing/staff-allocation/dashboard/statistics" className={`${isActive('statistics') ? 'active' : ''}`}>
            <img src={isActive('statistics') ? StatisticsWhite : StatisticsBlue} height={45} className="icon orders-icon" />
            Analytics
          </Link>
          
          {/* Other links */}
        </nav>
        <div className="sidebar-footer">
          <Link to="/staffing">
            <img src={Home} height="38px" alt="" />
          </Link>
        </div>
      </div>
    );
};

export default Sidebar;
