import React, { useState, useEffect } from 'react';
import { Steps, Button, Panel, Notification } from 'rsuite';

import { Link } from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import './WorkflowStepper.css';
import NegotiationSteps from "./NegotiationSteps"; 


function formatJobDateTimeShiftStart(job) {
    const date = new Date(job.customFields.workOrderDate);
    const friendlyDate = date.toLocaleDateString('en-US', {
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    });
    const [startTime, endTime] = job.customFields.shiftHours.split('-').map(time => `${parseInt(time)} Hrs`);
    return `on ${ friendlyDate } at ${startTime}`;
}

function formatJobDateTimeShiftEnd(job) {
    const date = new Date(job.customFields.workOrderDate);
    const friendlyDate = date.toLocaleDateString('en-US', {
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    });
    const [startTime, endTime] = job.customFields.shiftHours.split('-').map(time => `${parseInt(time)} Hrs`);
    return `on ${ friendlyDate } at ${endTime}`;
}


const WorkflowStepper = ({ offer, negotiations, setOffer, setNegotiations }) => { 
    return (
      <div className="p-4" id="offer-stepper">
          <Steps current={offer.overallActiveStep} vertical>

              <Steps.Item title="Negotiation" description={ 
                    <NegotiationSteps
                        negotiations={ negotiations } 
                        setNegotiations = {setNegotiations}
                        offer={ offer }
                        setOffer={ setOffer }
                    />
               } />
              <Steps.Item title="Job Status" description={
                  <>
                      {  offer.overallActiveStep >= 1 && <>
                            <p> 
                                { offer.jobExecution.checkInConfirmed ? 
                                    <>
                                        Geo Check In confirmed at {new Date(offer.jobExecution.geoCheckIn).toLocaleTimeString()} Hrs.
                                    </> 
                                    : <> Geo Check In - Pending {formatJobDateTimeShiftStart(offer.ticketId)} Hrs.</>
                                }
                            </p>
                            <p>
                                { offer.jobExecution.checkOutConfirmed ? 
                                    <>
                                        Geo Check Out confirmed at {new Date(offer.jobExecution.geoCheckOut).toLocaleTimeString()} Hrs.
                                    </> 
                                    : <> Geo Check Out - Pending {formatJobDateTimeShiftEnd(offer.ticketId)} Hrs.</>
                                }
                            </p>
                      </> }
                  </>
              } />
              <Steps.Item title="Payment" description={
                  <>
                      {
                        offer.overallActiveStep >= 2 && <>
                            <p>
                                Request Payment from {offer.ticketId.customFields.customerName} - 
                                <a target="_blank" href={offer.paymentDetails.linkToPay} rel="noreferrer"> Payment Link </a>
                            </p>
                            <p>
                                Make Payment to {offer.securityStaffId.firstName} - <a href="https://dashboard.stripe.com/acct_1PEZnVFfbiU42f9H/test/payments/new" target="_blank" rel="noreferrer"> Send </a>
                            </p>
                            <p>
                                Manage Your Finances -
                                <a href="https://dashboard.stripe.com/acct_1PEZnVFfbiU42f9H/test/payments?limit=10" target="_blank" rel="noreferrer"> Issue Invoices, Settle Disputes, and More </a> 
                            </p>
                        </>
                      }
                  </>
              } />
          </Steps>
      </div>
    );
  };
  

export default WorkflowStepper;
