import React, { useEffect, useState } from 'react';
import '../../styles/recommendedListingItem.css'; // Ensure this path is correct or adjust as needed
import { Link } from "react-router-dom";
import toast from 'react-hot-toast';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import StarIcon from '@mui/icons-material/Star';
import Scheduler from "../dashboard/scheduler";
import { InputNumber , Badge , Button } from 'rsuite';
import { createOffer } from '../../../../services/staffingService';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import GradeIcon from '@mui/icons-material/Grade';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LanguageIcon from '@mui/icons-material/Language';
import CallIcon from '@mui/icons-material/Call';

const ListingDetails = ({ staffs, hoveredStaff, setStaffs, setHoveredStaff, ticket, job }) => {
    const [activeStaffId, setActiveStaffId] = useState(null);

    const handleMouseEnter = (staffMember) => {
        setHoveredStaff(staffMember);
    };

    const openModal = (staffId) => {
        setActiveStaffId(staffId);
    };

    const closeModal = () => {
        setActiveStaffId(null);
    };

    const sendOffer = async (staff, job) => {
        try {
            const resp = await createOffer(staff, job);
            const offerId = resp.data.offer._id;
            const offerStatus = resp.data.offer.status;
            /*
            setStaffs(prevStaffs => {
                return prevStaffs.map(staff => staff._id === resp.data.offer.securityStaffId ? { ...staff, offerId: offerId } : staff);
            });
            */ 

            setStaffs(prevStaffs => {
                return prevStaffs.map(staff => {
                    if (staff._id === resp.data.offer.securityStaffId) {
                        return {
                            ...staff,
                            offer: {
                                ...staff.offer, 
                                offerId: offerId,
                                status: offerStatus
                            }
                        };
                    }
                    return staff;
                });
            });
            
            setActiveStaffId(null);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data);
            }
        }
    };

    const handleChange = (value, staffId) => {
        setStaffs(prevStaffs =>
            prevStaffs.map(staff =>
                staff._id === staffId ? { ...staff, offerPrice: value } : staff
            )
        );
    };

    

    return (
       <>
    <div className='recommended-staff-listing'>
    { staffs.length > 0 && (
        <>
            {/* Category: Top Performers */}
            <div className="recommended-staff-listing-category">
                Recommended for recent work with the same customer
            </div>
            <div className='recommended-staff-listing-listingDetails_section'>
                { staffs.slice(0, 3).map((m) => (
                    <div key={m._id} className="recommended-staff-listing-view_box" onMouseEnter={() => handleMouseEnter(m)}>
                        <div className="title d-flex justify-content-between">
                            {`${m.firstName} ${m.lastName}`}
                            <div className="available_box listing-daily_impre">
                                <span style={{ fontSize: '12px', color: '#4A4A4A', fontWeight: 'bold', backgroundColor: '#F4F4F5', padding: '3px 6px', borderRadius: '4px', fontFamily: 'Arial, sans-serif' }}>
                                    {`${((job.customFields.customerHourlyRate - m.offerPrice) / job.customFields.customerHourlyRate * 100).toFixed(0)}% Margin`}
                                </span>
                            </div>
                        </div>
                        {/* Remaining card content */}
                        <div className="address mt-2">
                        <LocationOnIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                        {`${m.street}, ${m.zipcode}, ${m.state} (${m.distanceMiles.toFixed(1)} miles away)`}
                    </div>

                    <div className="daily_update mt-1">
                        <div className="daily_impre">
                        
                            <GradeIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                            {`20 jobs done in last 3 months`}
                        </div>
                        <div className="daily_approx mt-1">
                            <AttachMoneyIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                            {`$${m.price} per hour `}
                        </div>
                    </div>

                    <hr className='hr' />

                    { !m.offer  && (
                        <div className=" d-flex justify-content-between">
                            <div className="info">
                                <span style={{ fontSize: '12.5px', color: '#3e4784', fontWeight: '400' }}>Set Hourly Rate</span>
                                <span className="" id="price-bidding">
                                    <InputNumber
                                        size="xs"
                                        style={{ fontSize: '12.5px', fontWeight: '400' }}
                                        value={m.offerPrice}
                                        onChange={(value) => handleChange(value, m._id)}
                                    />
                                </span>
                            </div>
                            {activeStaffId === m._id && (
                                <Scheduler
                                    isOpen={true}
                                    onRequestClose={closeModal}
                                    className='scheduler-modal'
                                    overlayClassName='scheduler-modal-overlay'
                                    ariaHideApp={false}
                                    sendOffer={sendOffer}
                                    staff={m}
                                    job={job}
                                />
                            )}

                            <div onClick={() => openModal(m._id)} style={{fontWeight:600, fontSize:'12.5px', color:'#db2777' , cursor:"default"}}>
                                Make Offer <ChevronRightOutlinedIcon />
                            </div>
                        </div>
                    )}

                    {/* {m.offerId && (
                        <div className="listing-request_info">
                            <Link to={`/staffing/staff-allocation/work-order/offer/${m.offerId}`} target="_blank">
                                <div className="info" style={{ color: '#28A745' }}>
                                    Check Offer Progress <OpenInNewIcon style={{ fontSize: '18px', marginLeft: '5px' }} />
                                </div>
                            </Link>
                        </div>
                    )} */}

                    {
                        m.offer?.offerId && (
                            <div className="listing-request_info d-flex justify-content-end">
                                <div className=""></div>
                                <div>
                                    <Link to={`/staffing/staff-allocation/work-order/offer/${m.offer.offerId}`} >
                                        <div className="info" style={{fontWeight:'500' }}>
                                            {/* <Badge color={m.offer.status === 'accepted' ? 'green' : m.offer.status=== 'rejected' ? 'red' : 'yellow'}> <span className="p-2"> Offer Status </span></Badge> */}
                                            { m.offer.status === 'completed' && <span style={{color:'#28a745'}}> Pending Payment <ChevronRightOutlinedIcon /> </span> }
                                            { m.offer.status === 'paid' && <span style={{color:'#28a745'}}> Payment Completed  <ChevronRightOutlinedIcon /> </span> }
                                            { m.offer.status === 'accepted' && <span style={{color:'#28a745'}}> Offer Accepted  <ChevronRightOutlinedIcon /> </span> }
                                            { m.offer.status === 'rejected' && <span style={{color:'#dc3545'}}> Offer Rejected </span> }
                                            { m.offer.status === 'open' && <span style={{color:'#ffb400' , fontSize:'13px'}}> Pending Response <OpenInNewIcon style={{fontSize:'14px'}}/> </span> }
                                            
                                        </div>
                                    </Link>
                                </div>
                            </div>     
                        )
                    }

                    </div>
                ))}
            </div>
            <hr />
            {/* =================================== Category: Best ========================================= */}
            {staffs.length > 3 && (
                <>
                    <div className="recommended-staff-listing-category">
                        Promote newly onboarded staff in the area
                    </div>
                    <div className='recommended-staff-listing-listingDetails_section'>
                        {staffs.slice(3,5).map((m) => (
                            <div key={m._id} className="recommended-staff-listing-view_box" onMouseEnter={() => handleMouseEnter(m)}>
                                <div className="title d-flex justify-content-between">
                                    {`${m.firstName} ${m.lastName}`}
                                    

                                    <div className="available_box listing-daily_impre">
                                <span style={{ fontSize: '12px', color: '#4A4A4A', fontWeight: 'bold', backgroundColor: '#F4F4F5', padding: '3px 6px', borderRadius: '4px', fontFamily: 'Arial, sans-serif' }}>
                                    {`${((job.customFields.customerHourlyRate - m.offerPrice) / job.customFields.customerHourlyRate * 100).toFixed(0)}% Margin`}
                                </span>
                            </div>
                                    
                                </div>
                                <div className="address mt-2">
                        <LocationOnIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                        {`${m.street}, ${m.zipcode}, ${m.state} (${m.distanceMiles.toFixed(1)} miles away)`}
                    </div>

                    <div className="daily_update mt-1">
                        <div className="daily_impre">
                        
                            <GradeIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                            {`20 jobs done in last 3 months`}
                        </div>
                        <div className="daily_approx mt-1">
                            <AttachMoneyIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                            {`$${m.price} per hour `}
                        </div>
                    </div>

                    <hr className='hr' />

                                {!m.offer && (
                                    <div className=" d-flex justify-content-between">
                                        <div className="info">
                                            <span style={{ fontSize: '12.5px', color: '#3e4784', fontWeight: '400' }}>Set Hourly Rate</span>
                                            <span className="" id="price-bidding">
                                                <InputNumber
                                                    size="xs"
                                                    style={{ fontSize: '12.5px', fontWeight: '400' }}
                                                    value={m.offerPrice}
                                                    onChange={(value) => handleChange(value, m._id)}
                                                />
                                            </span>
                                        </div>
                                        {activeStaffId === m._id && (
                                            <Scheduler
                                                isOpen={true}
                                                onRequestClose={closeModal}
                                                className='scheduler-modal'
                                                overlayClassName='scheduler-modal-overlay'
                                                ariaHideApp={false}
                                                sendOffer={sendOffer}
                                                staff={m}
                                                job={job}
                                            />
                                        )}

                                        <div onClick={() => openModal(m._id)} style={{fontWeight:600, fontSize:'12.5px', color:'#db2777' , cursor:"default"}}>
                                            Make Offer <ChevronRightOutlinedIcon />
                                        </div>
                                    </div>
                                )}
                                {  
                                    m.offer?.offerId && (
                                        <div className="listing-request_info d-flex justify-content-end">
                                            <div className=""></div>
                                            <div>
                                                <Link to={`/staffing/staff-allocation/work-order/offer/${m.offer.offerId}`} >
                                                    <div className="info" style={{fontWeight:'500' }}>
                                                        {/* <Badge color={m.offer.status === 'accepted' ? 'green' : m.offer.status=== 'rejected' ? 'red' : 'yellow'}> <span className="p-2"> Offer Status </span></Badge> */}
                                                        { m.offer.status === 'completed' && <span style={{color:'#28a745'}}> Pending Payment <ChevronRightOutlinedIcon /> </span> }
                                                        { m.offer.status === 'paid' && <span style={{color:'#28a745'}}> Payment Completed  <ChevronRightOutlinedIcon /> </span> }
                                                        { m.offer.status === 'accepted' && <span style={{color:'#28a745'}}> Offer Accepted  <ChevronRightOutlinedIcon /> </span> }
                                                        { m.offer.status === 'rejected' && <span style={{color:'#dc3545'}}> Offer Rejected </span> }
                                                        { m.offer.status === 'open' && <span style={{color:'#ffb400' , fontSize:'13px'}}> Pending Response <OpenInNewIcon style={{fontSize:'14px'}}/> </span> }
                                                        
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>     
                                    )
                                }
                            </div>
                        ))}
                    </div>
                </>
            )}

            <hr />

            {/* =================================== Category: Best ========================================= */}
            {staffs.length > 5 && (
                <>
                    <div className="recommended-staff-listing-category">
                        4 Stars and above 
                    </div>
                    <div className='recommended-staff-listing-listingDetails_section'>
                        {staffs.slice(5,staffs.length).map((m) => (
                                <div key={m._id} className="recommended-staff-listing-view_box" onMouseEnter={() => handleMouseEnter(m)}>
                                    <div className="title d-flex justify-content-between">
                                        {`${m.firstName} ${m.lastName}`}
                                        <div className="available_box listing-daily_impre">
                                    <span style={{ fontSize: '12px', color: '#4A4A4A', fontWeight: 'bold', backgroundColor: '#F4F4F5', padding: '3px 6px', borderRadius: '4px', fontFamily: 'Arial, sans-serif' }}>
                                        {`${((job.customFields.customerHourlyRate - m.offerPrice) / job.customFields.customerHourlyRate * 100).toFixed(0)}% Margin`}
                                    </span>
                                </div>
                                    
                                </div>
                                <div className="address mt-2">
                        <LocationOnIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                        {`${m.street}, ${m.zipcode}, ${m.state} (${m.distanceMiles.toFixed(1)} miles away)`}
                    </div>

                    <div className="daily_update mt-1">
                        <div className="daily_impre">
                        
                            <GradeIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                            {`20 jobs done in last 3 months`}
                        </div>
                        <div className="daily_approx mt-1">
                            <AttachMoneyIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                            {`$${m.price} per hour `}
                        </div>
                    </div>

                    <hr className='hr' />

                    {!m.offer && (
                        <div className=" d-flex justify-content-between">
                            <div className="info">
                                <span style={{ fontSize: '12.5px', color: '#3e4784', fontWeight: '400' }}>Set Hourly Rate</span>
                                <span className="" id="price-bidding">
                                    <InputNumber
                                        size="xs"
                                        style={{ fontSize: '12.5px', fontWeight: '400' }}
                                        value={m.offerPrice}
                                        onChange={(value) => handleChange(value, m._id)}
                                    />
                                </span>
                            </div>
                            {activeStaffId === m._id && (
                                <Scheduler
                                    isOpen={true}
                                    onRequestClose={closeModal}
                                    className='scheduler-modal'
                                    overlayClassName='scheduler-modal-overlay'
                                    ariaHideApp={false}
                                    sendOffer={sendOffer}
                                    staff={m}
                                    job={job}
                                />
                            )}

                            <div onClick={() => openModal(m._id)} style={{fontWeight:600, fontSize:'12.5px', color:'#db2777' , cursor:"default"}}>
                                Make Offer <ChevronRightOutlinedIcon />
                            </div>
                        </div>
                    )}

                    {/* {m.offerId && (
                        <div className="listing-request_info">
                            <Link to={`/staffing/staff-allocation/work-order/offer/${m.offerId}`} target="_blank">
                                <div className="info" style={{ color: '#28A745' }}>
                                    Check Offer Progress <OpenInNewIcon style={{ fontSize: '18px', marginLeft: '5px' }} />
                                </div>
                            </Link>
                        </div>
                    )} */}

                        { 
                            m.offer?.offerId && (
                                    <div className="listing-request_info d-flex justify-content-end">
                                        <div className=""></div>
                                        <div>
                                            <Link to={`/staffing/staff-allocation/work-order/offer/${m.offer.offerId}`} >
                                                <div className="info" style={{fontWeight:'500' }}>
                                                    {/* <Badge color={m.offer.status === 'accepted' ? 'green' : m.offer.status=== 'rejected' ? 'red' : 'yellow'}> <span className="p-2"> Offer Status </span></Badge> */}
                                                    
                                                    { m.offer.status === 'completed' && <span style={{color:'#28a745'}}> Pending Payment <ChevronRightOutlinedIcon /> </span> }
                                                    { m.offer.status === 'paid' && <span style={{color:'#28a745'}}> Payment Completed  <ChevronRightOutlinedIcon /> </span> }
                                                    { m.offer.status === 'accepted' && <span style={{color:'#28a745'}}> Offer Accepted  <ChevronRightOutlinedIcon /> </span> }
                                                    { m.offer.status === 'rejected' && <span style={{color:'#dc3545'}}> Offer Rejected </span> }
                                                    { m.offer.status === 'open' && <span style={{color:'#ffb400' , fontSize:'13px'}}> Pending Response <OpenInNewIcon style={{fontSize:'14px'}}/> </span> }
                                                    
                                                </div>
                                            </Link>
                                        </div>
                                    </div>     
                                )
                        }
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    )}
    </div>
    </>

    );
};

export default ListingDetails;