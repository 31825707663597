import React , { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link , Redirect } from 'react-router-dom';
import { Message , Loader } from 'rsuite';
import FormControl from 'rsuite/esm/FormControl';
import RightArrow from '../asset/auth-icons/icons8-right-arrow-50 (2).png'
import { getResetPwdLink , getCurrentUser } from '../../services/userService';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import NavbarAuth from "./NavbarAuth" ; 


const schema = yup.object({
    email: yup.string().email('Invalid email format').required('Required') 
}).required();

const ForgotPassword = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const [loading, setLoading ] = useState(false)
    const [message, setMessage]= useState("")
    const [success, setSuccess]= useState(true)
    
    const onSubmit = async(data) => {
    
        try {
            setLoading(true);
            const resp = await getResetPwdLink(data.email) ; 
            if (resp.status == 200) {
              setMessage("Please check your inbox for a password reset email. Don't forget to check your spam or junk folder as well. The link expires in 60 minutes");
            }
            setSuccess(true)
            setLoading(false);
          } catch (ex) {
            setSuccess(false)
            setLoading(false);
            if (ex.response && ex.response.status === 400) {
              setMessage(ex.response.data);
            } else if (ex.response && ex.response.status === 404){
              setMessage(ex.response.data);
            }
          }
    }

    if (getCurrentUser()) return <Redirect to="/home" />

    return (
        <>
            
            <NavbarAuth />
            <div id='Forgot-password' className="signIn-Page">
                <div className="Forgot-password-inner">
                    <div className='Forgot-password-title-block'>
                    {
                        message && 
                        <>
                            {
                                !success ? 
                                <div className="alert alert-danger mb-3" role="alert">
                                    {message}
                                </div>
                                :
                                <div className="alert alert-success" role="alert">
                                    {message}
                                </div>

                            }
                        </>
                    }
                        <h3 className="mb-4">
                            Forgot Your Password?
                        </h3>
                        <p className="mb-2">Enter your email address below and we'll send you insructions for resetting your password</p>

                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='form-forgotpassword'>
                            <div className="input-icons">
                                <i className="fa fa-envelope icon">
                                </i>
                                
                                <input className="input-field"
                                    type="text"
                                    {...register("email", { required: true })}
                                    placeholder='name@example.com' />
                                {errors.email && <span className='errorTextForm'> {errors.email.message} </span>}
                            </div>
                            <button type="submit" className='submitbtn'>
                                { !loading && <i className="fa fa-arrow-right"></i> }
                                { loading && <Loader /> }
                            </button>
                        </div>

                    </form>
                    <div className='sign-In-link-block mt-3'>
                        <Link to='/login'>
                            Sign In
                        </Link>
                        <Link to='/signup'>Sign Up</Link>
                    </div>


                </div>

            </div>
        </>
    )
}

export default ForgotPassword