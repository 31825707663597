import React, { useState , useRef, useEffect } from 'react';
import HighImpactVariables from "./impactVariables.jsx" ; 
import LocationPlanning from "./locationPlanningBlock.jsx" ; 
import OtherSegments from "./otherSegments.jsx" ; 
import ShareComponent from "./share"
import "../styles/report.css" ; 
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { getSegmentDataById } from "../../../services/segmentService" ; 
import gfm from 'remark-gfm';
import Navbar from "../navbar" ;


const Report = () => {

    
    const { id } = useParams() ;
    const [segmentData, setSegmentData] = useState({}) ;
    // const segment= predefined.find( s => s.id == id ) ;

    const [displayText, setDisplayText] = useState('') ;

    
    useEffect(() => {

            const fetchSegmentData = async () => {

            try {
                    const { data } = await getSegmentDataById(id)
                    setSegmentData(data) ;
                    simulateTextStreaming(data.description_persona);
            } catch (error) {
                    if (error.response && error.response.status === 400) {
                        console.log(error.response.data);
                    }   
            }
        }

        fetchSegmentData() ;

    }, [id]) ;


    const simulateTextStreaming = (data) => {
        const fullText = `
**Overview:**  
${data.Overview}

**Economic Characteristics:**  
${data.Economic_Characteristics}

**Lifestyle and Preferences:**  
${data.Lifestyle_and_Preferences}

**Marketing Strategy Recommendations:**  
${data.Marketing_Strategy_Recommendations}
`;
        // const delay = 20; 
        const delay = 8; 
        let currentText = '';
        let position = 0;

        const intervalId = setInterval(() => {
            currentText += fullText.charAt(position);
            setDisplayText(currentText);
            position++;

            if (position >= fullText.length) {
                clearInterval(intervalId);
            }
        }, delay);
    };

    return <> 
            {
                Object.keys(segmentData).length >= 1 && 
                <div className="">
                    <Navbar />

                {/* segment data page  */}
                <div className="report-screenshot-container">
                        <div class="report-ellipse report-ellipse-top1"></div>
                        <div class="report-ellipse report-ellipse-top2"></div>
                        <div class="report-text-container">
                            <h1 class="report-headline"> {segmentData.description_persona.Title} </h1> 
                            <p class="report-description">Algorithmic Precision Segment Targeting</p>
                        </div>
                        <img className="report-decorative-image" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1714053739/Group_2088.svg" alt="Decorative" /> 
                </div>
    
                    <div class="report-info-container">
                        {/* <div class="report-number-circle">  # </div> */}
                        <img class="report-featured-image mt-2 mb-2" src={segmentData.imgUrl} alt="Featured" />
                        <div class="report-text-content">
                            {/* <h2 class="report-segment-title"> Core Stability Zones </h2> */}
                            {/* <h2 class="report-segment-title"> Persona </h2> */}
                            <p class="report-segment-description">

                            {/* <ReactMarkdown 
                                children={samplePersona} 
                                remarkPlugins={[gfm]} 
                            />  */}
                               

                                    {/* <p>
                                        <strong>Overview:</strong> <br />
                                        {displayText.Overview} <br />
                                    </p>
                                    <p>
                                        <strong>Economic Characteristics:</strong> <br />
                                        {displayText.Economic_Characteristics} <br />
                                    </p>
                                    <p>
                                        <strong>Lifestyle and Preferences:</strong> <br />
                                        {displayText.Lifestyle_and_Preferences} <br />
                                    </p>
                                    <p>
                                        <strong>Marketing Strategy Recommendations:</strong> <br />
                                        {displayText.Marketing_Strategy_Recommendations} <br />
                                    </p> */}
                                                    {/* {displayText} */}

                                <ReactMarkdown 
                                children={displayText} 
                                remarkPlugins={[gfm]} 
                                />


                            </p>

                        </div>
                    </div>

                    <HighImpactVariables segmentData= {segmentData}/>
                    <LocationPlanning segmentData= {segmentData} />
                    <OtherSegments segmentData= {segmentData} /> 
                    <ShareComponent segmentData= {segmentData} />
            </div>
            }


            
    </>
}

export default Report ; 