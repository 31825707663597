import React from 'react';

function ShareComponent() {
  return (
    <div className="justify-content-center text-center mt-4 mb-4">
      <div className="share-compoment-blue-box-with-text">
        <img src={'https://res.cloudinary.com/zipscores-collection/image/upload/v1713469737/qsjkibuurhe14rbftvsu.svg'} alt="Description of Image" className="share-compoment-image-size"/>
        <h1 className="share-compoment-header-text">Share it with your colleagues</h1>
        <p className="share-compoment-description-text">
          Gain insights with AI-analyzed respondent feedback to enhance your product experience. No more paying thousands to Consumer Panel companies.
        </p>
        <button className="share-compoment-call-to-action-button">Email Report</button>
      </div>
    </div>
  );
}

export default ShareComponent;
