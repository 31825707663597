import http from "./http";
// import { apiUrl_dev, apiUrl_prod  } from "../config/api.json";

import apis  from "../config/apis.json";
const api = process.env.REACT_APP_ENVIRON === "development" 
               ? apis.dev
               : apis.prod


export function renameWs(wsId, name, type) {


    let url;
    switch (type) {
        case 'Data':
            url = `${api}/data/rename`;
            break;
        case 'Fine-tune':
            url = `${api}/inference/rename`;
            break;
        case 'Co-Pilot':
            url = `${api}/copilot/rename`;
            break;
        default:
            throw new Error("Invalid type provided");
    }

    return http.put(url, {
        wsId: wsId,
        name: name
    });

}


export function deleteWs(wsId, type ){

    switch (type) {
        case 'Data':
            return http.delete(`${api}/data/delete?wsId=${wsId}`);
        case 'Fine-tune':
            return http.delete(`${api}/inference/delete?wsId=${wsId}`);
        case 'Co-Pilot':
            return http.delete(`${api}/copilot/delete?wsId=${wsId}`);
        default:
            // Optionally, you can handle other cases or throw an error here
            break;
    }    

    /*
    if (type === "data"){
        return http.delete(`${api}/data/delete?wsId=${wsId}`);
    } else if (type === "fine-tune"){
        return http.delete(`${api}/inference/delete?wsId=${wsId}`);
    }
    */
}