import React, { useCallback , useRef , useState , useEffect } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import { useDrop } from 'react-dnd';

import { nodes as initialNodes, edges as initialEdges } from './initial-elements';
// import CustomNode from './CustomNode';
import 'reactflow/dist/style.css';
import { ReactComponent as PlayIcon } from "../../asset/img/play.svg"
import { ReactComponent as SucceededIcon } from "../../asset/img/Succeeded.svg"
import { ReactComponent as FailedIconIcon } from "../../asset/img/FailedIcon.svg"
import { ReactComponent as InProgressIcon } from "../../asset/img/InProgress.svg"
import { ReactComponent as UploadDownIcon} from "../../asset/img/upload-down.svg"
import { ReactComponent as ParametersIcon } from "../../asset/img/parameters.svg"
import { ReactComponent as UploadIcon } from "../../asset/img/upload.svg"
import editIcon from "../../asset/img/editIcon-black.png" ; 

import Settings from "../settings" ; 


/*
const nodeTypes = {
  custom: CustomNode,
};
*/

const Diagrams = ({ showStatus, setShowStatus , showLogs , setShowLogs }) => {
  
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [logsAvailable, setLogsAvailable] = useState(false)

  const [flowKey, setFlowKey] = useState(0);

  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [openDrawer, setOpenDrawer] = useState(!true) ; 

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

  const createNodeAndEdge = useCallback((item) => {

    const { detail } = item;

    const newNode = {
      id: detail.id,
      type: detail.type,
      position : detail.position ,
      data: { ...detail.data , label: detail.data.label },
    };
    
    setNodes((nds) => nds.concat(newNode));
  
    // Create an edge from the new node to an existing node
    if (nodes.length > 0) {

      const newEdge = {
        id: `e${newNode.id}-${nodes[0].id}`,
        source: newNode.id,
        target: nodes[0].id,
        type: 'smoothstep', 
      };
      setEdges((eds) => eds.concat(newEdge));
    }
  }, [nodes, setNodes, setEdges]);


  const [, drop] = useDrop(() => ({
    accept: 'submenuItem',
    drop: (item, monitor) => {
      const position = monitor.getClientOffset();
      // const position = 2 ; 
      createNodeAndEdge(item, position);
     console.log('dropped ++++ ')
    },
  }));

  const onNodeDoubleClick = useCallback((event, node) => {
    console.log('Node double-clicked:', node);
    setOpenDrawer(true)
    // You can add additional logic here if needed
  }, []);
  
  const onNodeClick = useCallback((event, element) => {
    console.log('selected node +++++ ', element )
    if (element.id !== selectedNode) {
      setSelectedNode(element.id);
    }
  }, [selectedNode]);

  const onEdgeClick = useCallback((event, edge) => {
    setSelectedEdge(edge.id);
  }, []);

  const handleButtonClick = (e) => {
    console.log('click')
    e.stopPropagation(); // Prevent event from propagating to React Flow canvas
    runWorkflow();
  };
  


  const runWorkflow = async () => {
    setLogsAvailable(false);
    setShowStatus(true);
  
    const processedNodes = new Set();
    const processingPromises = [];
  
    const processNode = async (nodeId) => {
      if (processedNodes.has(nodeId)) return Promise.resolve();
      const node = nodes.find(n => n.id === nodeId);
      if (!node) return Promise.resolve();
  
      setNodes((nds) => nds.map((n) => n.id === nodeId ? { ...n, data: { ...n.data, label: <div className={`inneritem ${n.data.classes}`}>{n.data.name} ⏳</div>, isProcessing: true } } : n));
  
      const processPromise = new Promise(async resolve => {
        // Simulate processing
        await new Promise(r => setTimeout(r, 1000)); // Replace with real processing logic
  
        // Change node's label back and mark as processed
        setNodes((nds) => nds.map((n) => n.id === nodeId ? { ...n, data: { ...n.data, label: node.data.originalLabel || node.data.label, isProcessing: false } } : n));
  
        processedNodes.add(nodeId);
        const edgePromises = edges.filter(edge => edge.source === nodeId).map(edge => processNode(edge.target));
        await Promise.all(edgePromises);
        resolve();
      });
  
      processingPromises.push(processPromise);
      return processPromise;
    };
  
    // Store original labels before processing
    setNodes((nds) => nds.map((n) => ({ ...n, data: { ...n.data, originalLabel: n.data.label } })));
    const rootNodes = nodes.filter(node => edges.every(edge => edge.target !== node.id));
    const rootPromises = rootNodes.map(node => processNode(node.id));
    processingPromises.push(...rootPromises);
  
    // Wait for all processing to complete
    await Promise.all(processingPromises);
    setLogsAvailable(true);
  };
  
  
  useEffect(() => {
    const errorHandler = (e: any) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);
  
    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);
  
  
  const updatedNodes = nodes.map(node => {
    const className = node.id === selectedNode ? 'selected-node' : '';

    return {
      ...node,
      className: node.className ? `${node.className} ${className}` : className
    };
  });

  const updatedEdges = edges.map(edge => {
    const style = edge.id === selectedEdge ? { stroke: 'blue', strokeWidth: 1.8 } : {};

    return {
      ...edge,
      style
    };
  });


  return (
      <>  
        {
            !showLogs && <ReactFlow
              nodes={updatedNodes}
              edges={updatedEdges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              // nodeTypes={nodeTypes}
              onNodeDoubleClick={onNodeDoubleClick} 
              onNodeClick={onNodeClick}
              onEdgeClick={onEdgeClick} // Add this line
              ref={drop}
              key={flowKey}
             >
              {/* <MiniMap /> */}
              <Controls />
              <Background />

              {/* <div style={{ position: 'absolute', left: 740 , bottom: 13, zIndex: 4 }}>
                <button className="run-workflow-btn" onClick={handleButtonClick}>
                <PlayIcon /> Run
                </button>
              </div>  */}

              <div className='topBtn'>
                {/* <button className='btn saveBtn'>Save</button>  */}
                <button className='btn runBtn run-workflow-btn' onClick={handleButtonClick} ><PlayIcon /> Run</button>
              </div> 

              { logsAvailable && 
                <div className="loop-cta-middle-react-flow" > 
                  <button className="loop-cta-logs-button" style={{zIndex: '4 !important'}}  onClick={() => { setShowLogs(!showLogs) }}> 
                    View Logs
                    <span className="spinner-grow ml-2" style={{width: '0.3rem' ,  height: "0.3rem"}} role="status"  aria-hidden="true"></span>
                    <span className="spinner-grow ml-1" style={{width: '0.3rem' ,  height: "0.3rem"}} role="status"  aria-hidden="true"></span>
                  </button> 
                </div>
              }

              <Settings openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>
           </ReactFlow>
        }
        {
            showLogs &&  
            <div className='show_card_modal'>
              <div className='show_card_modal_body'>

                <div className='card_box input-connector'>
                  <div className='card_heading'>
                    <UploadDownIcon />
                    <h4 className='title'>Input</h4>
                  </div>
                  <div className='card_body'>
                    <p className='description'>

                    <ul class="loop-custom-list">
                      <li>
                        <span class="loop-strong">REST API </span><br/>
                        <p class="loop-paragraph">
                          Timestamp: 2024-02-01 10:45:00 UTC <br/>
                          Endpoint: https://api.texti.ai/agent <br/>
                          HTTP Method: POST <br/>
                          Request Body: {JSON.stringify({ 'prompt' : 'The delivery was much slower than promised, and the packaging was slightly damaged.'})} <br />
                          Status Code: 200 OK <br/>
                          Response: "Agent initiated" <br/>
                        </p>
                      </li>
                    </ul>

                    </p>
                  </div>
                  <div className='status succeeded'><SucceededIcon /> <span>Succeeded</span></div>
                </div>

                <div className='card_box finetunes-available'>
                  <div className='card_heading'>
                    <ParametersIcon />
                    <h4 className='title'>Fine-tunes</h4>
                  </div>
                  <div className='card_body'>
                    <p className='description'>

                    <ul class="loop-custom-list">
                      <li>
                        <span class="loop-strong">Sentiment Classification Model Log</span>
                        <p class="loop-paragraph">
                          Timestamp: 2024-02-01 11:00:04 UTC <br/>
                          Model: Sentiment Classification <br/>
                          Prompt: "The delivery was much slower than promised, and the packaging was slightly damaged." <br/>
                          Prompt Instruction : "" <br />
                          Retries: 1 <br />
                          Completion: Negative <br/>
                          Schema Similarity Score : 0.98 <br/>   
                        </p>
                      </li>
                      <li class="loop-paragraph mt-2">
                        <span class="loop-strong">Feedback Classification Model Log</span>
                        <p class="loop-paragraph">
                          Timestamp: 2024-02-01 11:00:10 UTC <br/>
                          Model: Feedback Classification <br/>
                          Prompt: "The delivery was much slower than promised, and the packaging was slightly damaged." <br/>
                          Prompt Instruction : "" <br />
                          Retries: 1 <br />
                          Completion: Delivery Delay, Packaging Issue <br/>
                          Schema Similarity Score : 0.87 <br/>   
                        </p>
                      </li>
                    </ul>
                    </p>
                  </div>
                  {/* <div className='status failed'><FailedIconIcon /> <span>Failed</span></div> */}
                  <div className='status succeeded'><SucceededIcon /> <span>Succeeded</span></div>
                </div>

                <div className='card_box output-connector'>

                  <div className='card_heading'>
                    <UploadIcon />
                    <h4 className='title'>Output </h4>
                  </div>
                  <div className='card_body'>
                    <p className='description'>

                      {/* === example EMAIL OUTPUT RESPONSE ===  */}
                      <ul class="loop-custom-list">
                        <li>
                          <span class="loop-strong">Email</span>
                          <p class="loop-paragraph">
                            Timestamp: 2024-02-01 11:00:12 <br />
                            Sender: hello@texti.ai <br />
                            Recipient: john@yourstore.com <br />
                            Status: Sent Successfully <br />
                            Subject: "AI Agent Alert - Actionable Item" <br />
                            Body: Take action now, contact customer xyz. <a href=""> Access Report </a>
                          </p>
                        </li>   
                        <li>
                          <span class="loop-strong">Database </span>
                          <p class="loop-paragraph">
                          Timestamp: 2024-02-01 11:00:12 <br />
                          Action: Insert Record <br />
                          Table: Dbo.TextFeedbacks <br />
                          Status: Insert Successful <br />
                          Updated Fields: Feedback, Status, Sentiment, Topic <br />
                          Database Server: MSSQL01 <br />
                            </p>
                        </li>   
                      </ul>

                    </p>
                  </div>
                  <div className='status succeeded'><SucceededIcon /> <span>Succeeded</span></div>

                </div>

              </div>

              <div class="loop-cta-middle"> 
                <button className="loop-cta-back-button" onClick={() => { setShowLogs(!showLogs) ; setLogsAvailable(false) }}> 
                  Edit Agent 
                </button> 
              </div>
              
            </div>
        }
      </>
  );
};

export default Diagrams;
