import React from 'react';
import { MarkerType, Position } from 'reactflow';

import InputIcon from "../../asset/img/InputIcon.svg"

import ActionaBleIcon from "../../asset/img/actionableIcon.svg"
import BugIcon from "../../asset/img/bug.svg"
import UsrGuideIcon from "../../asset/img/userGuideIcon.svg"
import OutPutIcon from "../../asset/img/outPutIcon.svg"

export const nodes = [
  {
    id: '1',
    type: 'input',
    data: {
      label: 
        <><img src={InputIcon} style={{marginRight:"11px",width:"20px",height:"20px"}} /> <span>Input</span></>,
    },
    position: { x: 100, y: 0 },
  },
  {
    id: '2',
    data: {
      label: <><img src={ActionaBleIcon} style={{marginRight:"11px",width:"20px",height:"20px"}}/><span>Fine-tune</span></>,
    },
    position: { x: 100, y: 100 },
  },
  {
    id: '3',
    data: {
      label: <><img src={BugIcon} style={{marginRight:"11px",width:"20px",height:"20px"}}/><span>Knowledge</span></>,
    },
    position: { x: 100, y: 200 },
  },
  {
    id: '4',
    type: 'output',
    data: {
      label:<><img src={OutPutIcon} style={{marginRight:"11px",width:"20px",height:"20px"}}/><span>Output</span></>,
    },
    position: { x: 100, y: 300 },
  },
];

export const edges = [
  { id: 'e1-1x', source: '1', target: '2', },
  { id: 'e1-1y', source: '2', target: '3', },
  { id: 'e1-1z', source: '3', target: '4', }
];
