import React, { useState , useEffect } from 'react';
import { Link , Redirect } from "react-router-dom" ;  
import '../../styles/dashboard.css';
import { Toggle , Checkbox } from 'rsuite';
import DashboardSidebar from "./dashboardSidebar"
import "../../styles/workOrder.css"
// import DetailMap from '../../staff-allocation/listing/listingdetail/detailmap' ;
import DistanceMap from './DistanceMap' ;

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import toast from 'react-hot-toast';

import { getProviderOffers , getCurrentProvider , getGeoCheckIn , getGeoCheckOut } from "../../../../services/staffingService";
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';

function formatJobDateTime(job) {
  const date = new Date(job.customFields.workOrderDate);
  const friendlyDate = date.toLocaleDateString('en-US', {
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
  });
  const [startTime, endTime] = job.customFields.shiftHours.split('-').map(time => `${parseInt(time)} Hrs`);
  return `${ friendlyDate } between ${startTime} to ${endTime}`;
}

function createStreetViewLink(lat, lng) {
  return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`;
}



const CountdownTicker = ({ shiftWhen, shiftHrs , checkInConfirmed }) => {
  const parseShiftHours = (shiftHrs) => {
    const [startHour, endHour] = shiftHrs.split('-');
    return { start: startHour.trim(), end: endHour.trim() };
  };

  const getTargetTimes = (shiftWhen, shiftHrs) => {
    const date = new Date(shiftWhen);
    const { start, end } = parseShiftHours(shiftHrs);

    const [startHours, startMinutes] = start.split(':').map(Number);
    const [endHours, endMinutes] = end.split(':').map(Number);

    const startTime = new Date(date);
    startTime.setHours(startHours, startMinutes, 0, 0);

    const endTime = new Date(date);
    endTime.setHours(endHours, endMinutes, 0, 0);

    return { startTime: startTime.getTime(), endTime: endTime.getTime() };
  };

  const { startTime, endTime } = getTargetTimes(shiftWhen, shiftHrs);

  const calculateTimeLeft = (targetTime) => {
    const now = new Date().getTime();
    const difference = targetTime - now;

    const timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };

    return difference > 0 ? timeLeft : { days: 0, hours: 0, minutes: 0, seconds: 0 };
  };

  const [startTimeLeft, setStartTimeLeft] = useState(calculateTimeLeft(startTime));
  const [endTimeLeft, setEndTimeLeft] = useState(calculateTimeLeft(endTime));

  useEffect(() => {
    if (startTimeLeft.days === 0 && startTimeLeft.hours === 0 && startTimeLeft.minutes === 0 && startTimeLeft.seconds === 0 &&
        endTimeLeft.days === 0 && endTimeLeft.hours === 0 && endTimeLeft.minutes === 0 && endTimeLeft.seconds === 0) return;

    const timerId = setInterval(() => {
      setStartTimeLeft(calculateTimeLeft(startTime));
      setEndTimeLeft(calculateTimeLeft(endTime));
    }, 1000);

    return () => clearInterval(timerId);
  }, [startTimeLeft, endTimeLeft]);

  return (
    <div className="decision-time-note mt-3">
      {
        !checkInConfirmed && 
        <div>
          * Your shift starts in
          <span style={{ color: '#c62828' }}>
            {startTimeLeft.days > 0 && ` ${startTimeLeft.days} days, `}
            {startTimeLeft.hours > 0 && ` ${startTimeLeft.hours} hrs, `}
            {` `}{startTimeLeft.minutes} mins, {startTimeLeft.seconds} secs
          </span>
        </div>
      }
      {
        checkInConfirmed &&
        <div>
          * Your shift ends in
          <span style={{ color: '#c62828' }}>
            {endTimeLeft.days > 0 && ` ${endTimeLeft.days} days, `}
            {endTimeLeft.hours > 0 && ` ${endTimeLeft.hours} hrs, `}
            {` `}{endTimeLeft.minutes} mins, {endTimeLeft.seconds} secs
          </span>
        </div>

      }

    </div>
  );
};




const OrderCard = ({ offer , checkIn, staff , checkOut }) => {

  const { ticketId:job } = offer ;
  return (
    <div className="staff-order-card">
      <div className="staff-googlestreetview">
        { console.log('offer accepted in assign +++', offer) }
        <DistanceMap source={staff.location} destination={job.location} />
      </div>
      <div className="staff-order-details">
        <div className="staff-order-content">
          <div className="staff-order-header d-flex justify-content-between">
            <div className="staff-order-name"> { formatJobDateTime(job) } </div>
            <div className="staff-order-id">#{job.customFields.orderId}</div>
          </div>
          <div className="staff-order-address">{ job.formatted_location }. <a href={createStreetViewLink(job.location.coordinates[1], job.location.coordinates[0])} target="_blank" rel="noreferrer" > Street View <OpenInNewIcon style={{fontSize:'16px', marginBottom:'5px'}} />  </a></div>
          {/* <div className="staff-order-total">{ job.customFields.customerName }</div> */}
          <div className="staff-order-total">  { job.customFields.serviceType.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') } </div>
          {/* <Checkbox className="staff-order-total" style={{marginLeft: '-10px'}}> Accept 5% less with the Early Payment Program. <Link> Terms & Conditions Apply.</Link></Checkbox> */}
        </div>

        { 
          offer.overallActiveStep === 1 && (
            <>
            {
              !offer.jobExecution.checkInConfirmed && 
              <>
                {/* <CountdownTicker when={job.customFields.workOrderDate} /> */}
                <CountdownTicker shiftWhen={job.customFields.workOrderDate} shiftHrs={job.customFields.shiftHours} checkInConfirmed={false}/>
                <div className="staff-restaurant-info mt-2" onClick={()=>{ checkIn(offer._id) }}>
                  <div className="staff-restaurant accept" style={{color:'#DB2677', backgroundColor: '#F8F9FD'}} >
                    <div className="staff-label" style={{color:'#DB2677', backgroundColor: '#F8F9FD'}}><WhereToVoteIcon /> Geo Check-In </div>
                  </div>
                </div>
              </>
            }
            {
              offer.jobExecution.checkInConfirmed && 
              <>
                <CountdownTicker shiftWhen={job.customFields.workOrderDate} shiftHrs={job.customFields.shiftHours} checkInConfirmed={true} />
                <div className="staff-restaurant-info mt-2" onClick={()=>{ checkOut(offer._id) }} >
                  <div className="staff-restaurant accept" style={{color:'#DB2677', backgroundColor: '#F8F9FD'}} >
                    <div className="staff-label" style={{color:'#DB2677', backgroundColor: '#F8F9FD'}}><WhereToVoteIcon /> Geo Check-Out </div>
                  </div>
                </div>
              </>
            }
            </>
          )
        }

      </div>

    </div>
  );
};


const NewOrdersTab = ({ offers , checkIn,  staff , checkOut }) => {
  return (
    <div className="staff-new-orders-tab">
       {/* <div className="staff-new-orders-header d-flex justify-content-between">
          <div>New</div>
          <div> <span> Next >> </span> </div>  
      </div>  */}
      <div className="staff-orders-container">


        { offers.length >=1 && offers.map(offer => (
          <OrderCard key={offer._id} offer={offer} staff={staff} checkIn={checkIn} checkOut={checkOut} />
        ))}

        {
          offers.length === 0 && <h6> No jobs are scheduled in the near future. </h6>
        }


      </div>
    </div>
  );
};



const StatusTabs = () => {

  const [openOffers , setOpenOffers] = useState([]) ;
  const [acceptedOffers , setAcceptedOffers] = useState([]) ;
  const [completedOffers , setCompletedOffers] = useState([]) ;
  const [staff , setStaff] = useState({}) ;

  useEffect(() => {
      getOffers();
  }
  , []);

  const getOffers = async () => {
    const resp= await getProviderOffers();
    const { data } = await getCurrentProvider();
    // setOffers(resp.data.offer) ;
    setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open'} )) ;
    setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted'} )) ;
    setCompletedOffers(resp.data.offer.filter((o) => { return o.status === 'completed'} )) ;
    setStaff({...data}) ;
  }

// if ( acceptedOffers.length === 0 ) return <Redirect to="/staffing/staff/dashboard/work-order/open" /> ; 

  const checkIn = async (offerId) => {
    try {
        const resp = await getGeoCheckIn(offerId);
        setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open'} )) ;
        setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted'} )) ;
        setCompletedOffers(resp.data.offer.filter((o) => { return o.status === 'completed'} )) ;
    } catch (error) {
        if (error.response && error.response.status === 400) {
            toast.error(error.response.data);   
        }
      
    } 
  } 

  const checkOut = async (offerId) => {
    try {
        const resp = await getGeoCheckOut(offerId);
        setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open'} )) ;
        setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted'} )) ;
        setCompletedOffers(resp.data.offer.filter((o) => { return o.status === 'completed'} )) ;
    } catch (error) {
        if (error.response && error.response.status === 400) {
            toast.error(error.response.data);   
        }
      
    } 
  } 


return (
            <>
              <div className="dashboard">
                  <DashboardSidebar />       
                  <>
                      <div className="main-content">
                        <div className="main-content-inner">
                            {/* ===== status tabs ===== */}
                            <div className="status-tabs">


                              <Link to='/staffing/staff/dashboard/work-order/open' style={{color: 'inherit'}}>     
                                <div className={`tab`}>
                                  <div className="count">{openOffers.length}</div>
                                  <div className="label">New </div>
                                </div>
                              </Link>
                              <Link to='/staffing/staff/dashboard/work-order/accepted' style={{color: 'inherit'}}>                              
                                  <div className={`tab active`}>
                                    <div className="count">{acceptedOffers.length}</div>
                                    <div className="label">Accepted</div>
                                  </div>
                              </Link>

                              <Link to='/staffing/staff/dashboard/work-order/completed' style={{color: 'inherit'}}>                              
                                  <div className={`tab`}>
                                    <div className="count">{completedOffers.length}</div>
                                    <div className="label">Completed</div>
                                  </div>
                              </Link>

                            </div>  
                            {/* ====== new orders ==== */}
                              {
                                Object.keys(staff).length > 0 && (
                                  <NewOrdersTab offers={ acceptedOffers } staff={ staff } checkIn={ checkIn } checkOut={checkOut} />
                                )
                              }
                        </div>
                      </div>
                  </>
              </div>
            </>
  );
};

export default StatusTabs;
