import http from "./http";
import { v4 as uuidv4 } from 'uuid';
import apis  from "../config/apis.json";
const api = process.env.REACT_APP_ENVIRON === "development" 
               ? apis.dev
               : apis.prod



export function addName( name ){
    return http.post(`${api}/data/add-name`, {
        name: name
    });
}

export function addTask( name, task ){
    return http.put(`${api}/data/add-task`, {
        name: name,
        task: task
    });
}

export function addConnector( name, connector ){
    return http.put(`${api}/data/add-connector`, {
        name: name,
        connector: connector
    });
}

export function importDataCsv(dt , name , task ){
    return http.put(`${api}/data/import/csv`, {
        data : dt,
        name: name, 
        task: task, 
        source : 'csv'
    });

}

export function importDataConnector(credentials, query , name, task , mapping , source ){
    return http.put(`${api}/data/import/connector/`, {
        credentials,
        query , 
        name , 
        task , 
        mapping,
        source,
    });
}


/*

export async function importDataInChunks(dt , name , task ){

    // const jsonData = JSON.stringify(dt);
    const jsonData = dt;
    const blob = new Blob([jsonData], { type: 'application/json' });
    
    const chunkSize = 1024 * 1024 * 20; // 10MB chunk size
    // const chunkSize = 1024 ; // chunk size
    
    const totalChunks = Math.ceil(blob.size / chunkSize);
    const requests = [];
    let start = 0;
    let index = 0;
    
    while (start < blob.size) {
        const chunk = blob.slice(start, start + chunkSize);
    
        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('index', index);
        formData.append('totalChunks', totalChunks);
        formData.append('name', name);
        formData.append('task', task);
        formData.append('blobsize', blob.size);
        
    
        // Send the chunk as a separate request
        // await http.post(`${api}/data/import/chunks`, formData);

        // send all requests in parallel and not sequentially ====>> 
        const request = http.post(`${api}/data/import/chunks`, formData , { headers: { 'chunksProcessing' : true } } );
        requests.push(request);    

        start += chunkSize;
        index += 1;
    }

    // only when parallel processing needs to be done 
    await Promise.all(requests); 

    return true;
}
*/ 

/*
export async function importDataInChunks(file, name, task) {
    const lines = file.split('\n');
    const totalChunks = lines.length;
  
    const chunkSize = 1024 * 1024 * 20; // 10MB chunk size
    const requests = [];
  
    for (let index = 0; index < totalChunks; index++) {
      const line = lines[index];
  
      const blob = new Blob([line], { type: 'text/plain' });
  
      const formData = new FormData();
      formData.append('chunk', blob);
      formData.append('index', index);
      formData.append('totalChunks', totalChunks);
      formData.append('name', name);
      formData.append('task', task);
  
      const request = http.post(
        `${api}/data/import/chunks`,
        formData,
        { headers: { 'chunksprocessing': true } }
      );
      requests.push(request);
    }
  
    await Promise.all(requests);
  
    return true;
}
*/

export async function importDataInChunks(file, name, task) {
    // const chunkSize = 1024 * 1024 * 10; // 10MB chunk size
    const chunkSize = 50 * 1024; // 5KB chunk size
    const totalChunks = Math.ceil(file.length / chunkSize);
  
    const requests = [];
  
    for (let index = 0; index < totalChunks; index++) {
    
      const start = index * chunkSize;
      const chunk = file.slice(start, start + chunkSize);
      const blob = new Blob([chunk], { type: 'text/plain' });
        
      const formData = new FormData();
      formData.append('chunk', blob);
      formData.append('index', index);
      formData.append('totalChunks', totalChunks);
      formData.append('name', name);
      formData.append('task', task);
    
      const request = http.post(
        `${api}/data/import/chunks`,
        formData,
        { headers: { 'chunksprocessing': true } }
      );
      requests.push(request);
    

      console.log('blob index', index)
      console.log('blob', blob )
    }
    await Promise.all(requests);
    return true;
  }

  export async function importPdfInChunks(files, name, task) {
    const requests = [];
    
    const chunkSize = 10 * 1024 * 1024; // this is just a placeholder
    // console.log('chunkSize ++', chunkSize)
    
    for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
        const uniqueId = uuidv4();
        const file = files[fileIndex];
        // const totalChunks = Math.ceil(file.fileSize / chunkSize); // change for testing on small chunks
        const totalChunks= file.chunks ;
        
        for (let index = 0; index < totalChunks; index++) {

            const start = index * chunkSize;
            const end = Math.min(file.fileSize, start + chunkSize);
            // const chunk = file.slice(start, end);
            const chunk = file.fileBlob.slice(start, end);

            const formData = new FormData();
            formData.append('chunk', chunk, `${uniqueId}-${index}`);
            formData.append('index', index);
            formData.append('totalChunks', totalChunks);
            formData.append('name', name);
            formData.append('task', task);
            formData.append('fileName', file.fileName);
            formData.append('fileId', uniqueId);
            formData.append('chunkIndex', index);

            const request = http.post(`${api}/data/import/chunks`, formData, {
                headers: { 'chunksprocessing': true }
            });
            requests.push(request);
        }
    }

    const responses = await Promise.all(requests);
    
    if (responses.every(response => response.data === 'processed')) {
        return true;
    }

    return false;
}


export function getDataList(){
    return http.get(`${api}/data/list`); 
}

export function getDataAttributes(name){
    return http.post(`${api}/data/attributes`, {
        name: name
    })
}


export function archiveData(wsId) {
    return http.put(`${api}/data/archive`, {
        wsId: wsId
    }); 
}

export function renameData(wsId, wsName) {
    return http.put(`${api}/data/rename`, {
        wsId: wsId, 
        wsName: wsName 
    }); 
}

export function updateDataFilesCount(dataId, fileCount , blobs, size) {
    return http.put(`${api}/data/file/count`, {
        dataId: dataId , 
        fileCount : fileCount, 
        blobs : blobs ,
        size: size , 
    }); 
}

/*
export function importDataDocs(dt , name , task ){
    console.log('call docs data import api ++ ')

    const blob = new Blob([dt], { type: 'application/json' });
    const jsonData = JSON.stringify(dt);
    const blob = new Blob([jsonData], { type: 'application/json' });

    const chunkSize = 1024 * 1024 * 5; // 5MB chunk size
    const totalChunks = Math.ceil(blob.size / chunkSize);

    let start = 0;
    let index = 0;

    while (start < blob.size) {
    const chunk = blob.slice(start, start + chunkSize);
    const formData = new FormData();
    formData.append('chunk', chunk);
    formData.append('index', index);
    formData.append('totalChunks', totalChunks);
    formData.append('name', name);
    formData.append('task', task);

    // Set the Content-Type header to multipart/form-data
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    // Send the chunk as a separate request
    await http.post(`${api}/data/import/chunks`, formData , config );

    start += chunkSize;
    index += 1;
    }

    return true;


}


/*

// const blob = new Blob([dt], { type: 'application/json' });
    const jsonData = JSON.stringify(dt);
    const blob = new Blob([jsonData], { type: 'application/json' });

    const chunkSize = 1024 * 1024 * 5; // 5MB chunk size
    const totalChunks = Math.ceil(blob.size / chunkSize);

    let start = 0;
    let index = 0;

    while (start < blob.size) {
    const chunk = blob.slice(start, start + chunkSize);
    const formData = new FormData();
    formData.append('chunk', chunk);
    formData.append('index', index);
    formData.append('totalChunks', totalChunks);
    formData.append('name', name);
    formData.append('task', task);

    // Set the Content-Type header to multipart/form-data
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    // Send the chunk as a separate request
    await http.post(`${api}/data/import/chunks`, formData , config );

    start += chunkSize;
    index += 1;
    }

    return true;

    */ 