import React from 'react';
import { useHistory } from 'react-router-dom';

const NotFoundPage = () => {
  const styles = {
    notFoundPage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#f8f8f8',
    },
    notFoundTitle: {
      fontSize: '2rem',
      marginBottom: '1rem',
    },
    notFoundMessage: {
      fontSize: '1.2rem',
      color: '#888',
    },
    backButton: {
      padding: '0.5rem 1rem',
      fontSize: '1rem',
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  };

  const history = useHistory();

  const handleBackToHome = () => {
    history.push('/');
  };

  return (
    <div style={styles.notFoundPage}>
      <h1 style={styles.notFoundTitle}>404 Page Not Found</h1>
      <p style={styles.notFoundMessage}>
        Oops! The page you're looking for does not exist.
      </p>
      <button className="mt-3" style={styles.backButton} onClick={handleBackToHome}>
        Go Back to Home
      </button>
    </div>
  );
};

export default NotFoundPage;
