import React from 'react'; 
// import './styles/listingitem.css' ; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddCardIcon from '@mui/icons-material/AddCard';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import GradeIcon from '@mui/icons-material/Grade';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LanguageIcon from '@mui/icons-material/Language';
import CallIcon from '@mui/icons-material/Call';

// import { useNavigate } from 'react-router-dom';
import { Tag, TagGroup } from 'rsuite';


import Scheduler from "../../../staffing//staff-allocation/dashboard/scheduler" ; 

import EmailIcon from '@mui/icons-material/Email';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';

const ListingDetails = ({places , hoveredPlace, setHoveredPlace }) => {

    const [modalIsOpen, setIsOpen] = React.useState(false) ;

    const handleMouseEnter = (place) => {
        setHoveredPlace(place);
    };


    function openModal() {
        setIsOpen(true);
      }
      
      function closeModal() {
        setIsOpen(false);
      }


    return (
            <div className='listingDetails_section'>
               
                {/* <div className="sort_by">
                    Sort by : <span>Recommendation <KeyboardArrowDownIcon /></span>
                </div> */}
                {/* <div className="zip_code">
                    <h2 className='title'>ZIP Codes nearby</h2>
                    <div className='zip_code_number'>
                        <span className='number active'>01701</span>
                        <span className='number'>01702</span>
                        <span className='number'>01704</span>
                        <span className='number'>01704</span>
                        <span className='number'>01704</span>
                    </div>
                </div> */}
                <div className="view_details_box mr-4">
                    {
                        places.length && places.map((m, i) => (
                            <div key={m.id} className="view_box" onMouseEnter={() => handleMouseEnter(m)} >
                                <div className="title">
                                    <h2>{`${m.displayName.text}`}</h2>
                                    <div className="available_box">
                                        {/* <FormControlLabel control={<Checkbox className='available_check' icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} />} label='Available' /> */}
                                        {/* <Checkbox className='favorite' icon={<img alt="fav icon" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675180766/free-tools/heart-border.svg" />} checkedIcon={<img alt="fav icon" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675180797/free-tools/heart.svg" />} /> */}
                                        <img width="18px" src={`${m.iconMaskBaseUri}.png`} alt=""  />
                                    </div>
                                </div>
                                <div className="address">
                                    <LocationOnIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                                    {`${m.formattedAddress}`}
                                </div>
                                <div className="daily_update">
                                    {
                                        m.rating ? 
                                        <div className="daily_impre">
                                            <GradeIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                                            {m.rating}  ({m.userRatingCount} Reviews)
                                        </div> : ''
                                    }

                                    {
                                        m.priceLevel ? 
                                        <div className="daily_approx">
                                            <AttachMoneyIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                                            {(m.priceLevel === "PRICE_LEVEL_INEXPENSIVE" || m.priceLevel === 'PRICE_LEVEL_FREE' || m.priceLevel === 'PRICE_LEVEL_MODERATE' ) && 'Budget Friendly'}
                                            {(m.priceLevel === "PRICE_LEVEL_EXPENSIVE" ) && 'Premium'}
                                            {(m.priceLevel === "PRICE_LEVEL_VERY_EXPENSIVE" ) && 'Luxury'}
                                        </div>  : ''
                                    }
                                </div>
                                <div className="daily_update mt-3">
                                    {
                                        m.nationalPhoneNumber ? 
                                        <div className="daily_impre">
                                            <CallIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                                            { m.nationalPhoneNumber }
                                        </div>  : ''
                                    }

                                    {
                                        m.websiteUri ? 
                                        <div className="daily_approx">
                                            <LanguageIcon style={{color:'#828282' , fontSize: '16px' , marginRight:'5px'}}/>
                                            <a href={`${m.websiteUri}`} target="_blank" rel="noreferrer"> Website </a>
                                        </div> : ''
                                        
                                    }
                                </div>
                                <div className="mt-3 ml-3">
                                    <TagGroup>
                                        {
                                            m.types.map((type, i) => <Tag key={i} size="sm" >{ type.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()) }</Tag>)
                                        }
                                        
                                    </TagGroup>
                                </div>
                                <hr className='hr' />
                                <div className="request_info">
                                    {/* <div className=""></div> */}

                                    <div className="d-flex">
                                        {/* <div className="info"> 
                                            <EmailIcon style={{ fontSize:'18px' , color:'#3e4784' }} />
                                        </div>
                                        <div className="info" > 
                                            <WifiCalling3Icon style={{ fontSize:'18px' , color:'#3e4784' }} />
                                        </div> */}
                                        <div className="info ml-3" onClick={openModal}  ><AddHomeWorkOutlinedIcon />
                                            Assign
                                        </div>
                                    </div>
                                    <Scheduler    
                                        isOpen={modalIsOpen}
                                        onRequestClose={closeModal}
                                        // style={customStyles}
                                        className='scheduler-modal'
                                        overlayClassName= 'scheduler-modal-overlay'
                                        ariaHideApp={false}
                                    />

                                    {/* <div className="info"><EmailOutlinedIcon />Request more info</div> */}
                                    {/* <div className="info"><a href={`/us/advertising/billboard/${m.ref}`} alt="show details of billboard" target='_blank' className="info" rel="noreferrer">  View Details  </a><ChevronRightOutlinedIcon /></div> */}
                                    {/* <div className="view_detail" onClick={() => navigate(`/listing-detail/${i + 1}`)}>View Details <ChevronRightOutlinedIcon /></div> */}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
    )
}


export default ListingDetails; 