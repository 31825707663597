import React, { useState , useEffect } from 'react';
import { Link , Redirect } from "react-router-dom" ;  
import '../../styles/dashboard.css';
import { Toggle , Checkbox } from 'rsuite';
import DashboardSidebar from "./dashboardSidebar"
import "../../styles/workOrder.css"
import DistanceMap from './DistanceMap' ;
import RejectionModal from "./RejectionModal" ; 

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { getProviderOffers , getCurrentProvider , getAcceptOffer } from "../../../../services/staffingService";
import toast from 'react-hot-toast';

function formatJobDateTime(job) {
  const date = new Date(job.customFields.workOrderDate);
  const friendlyDate = date.toLocaleDateString('en-US', {
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
  });
  const [startTime, endTime] = job.customFields.shiftHours.split('-').map(time => `${parseInt(time)} Hrs`);
  return `${ friendlyDate } between ${startTime} to ${endTime}`;
}

function createStreetViewLink(lat, lng) {
  return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`;
}

const CountdownTicker = ({ when }) => {
  const offsetDays = 0;
  const targetTime = new Date(new Date(when).getTime() + offsetDays * 24 * 60 * 60 * 1000).getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetTime - now;

    const timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };

    return difference > 0 ? timeLeft : { days: 0, hours: 0, minutes: 0, seconds: 0 };
  };

  const [time, setTime] = useState(calculateTimeLeft());

  useEffect(() => {
    if (time.days === 0 && time.hours === 0 && time.minutes === 0 && time.seconds === 0) return;

    const timerId = setInterval(() => {
      setTime(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerId);
  }, [time]);

  return (
    <div className="decision-time-note mt-3">
      * Respond in   
      <span style={{ color: '#c62828' }}>
        {time.days > 0 && ` ${time.days} days, `}
        {time.hours > 0 && ` ${time.hours} hrs, `}
        {` `}{time.minutes} mins, {time.seconds} secs 
      </span> to avoid auto-rejection.
    </div>
  );
};

const OrderCard = ({ offer , offerAccept, staff , openOffers , setOpenOffers , acceptedOffers , setAcceptedOffers }) => {

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
        setShowModal(true);
  };

  const closeModal = () => {
        setShowModal(false);
  };

  const { ticketId:job } = offer ;
  return (
    <div className="staff-order-card">
      <div className="staff-googlestreetview">
        <DistanceMap source={staff.location} destination={job.location} />
      </div>
      <div className="staff-order-details">
        <div className="staff-order-content">
          <div className="staff-order-header d-flex justify-content-between">
            <div className="staff-order-name"> { formatJobDateTime(job) } at ${ offer.negotiations.providerHourlyRate } per hour </div>
            <div className="staff-order-id">#{job.customFields.orderId}</div>
          </div>
          <div className="staff-order-address">{ job.formatted_location }. <a href={createStreetViewLink(job.location.coordinates[1], job.location.coordinates[0])} target="_blank" rel="noreferrer" > Street View <OpenInNewIcon style={{fontSize:'16px', marginBottom:'5px'}} />  </a></div>
          {/* <div className="staff-order-total">{ job.customFields.customerName }</div> */}
          <div className="staff-order-total">  { job.customFields.serviceType.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') } </div>
          <Checkbox className="staff-order-total" style={{marginLeft: '-10px', marginBottom:'-15px' , marginTop:'-10px'}}> Accept 5% less with the Early Payment Program. <Link> Terms & Conditions Apply.</Link></Checkbox>
        </div>

        <CountdownTicker when={offer.negotiations.expiredAt} />
        <div className="staff-restaurant-info mt-3">
          <div className="staff-restaurant accept">
            <div className="staff-label" onClick={()=>{ offerAccept(offer._id) }} ><CheckIcon /> Accept</div>
          </div>
          <div className="ml-2 staff-restaurant reject">
            <div className="staff-label" onClick={openModal} ><ClearIcon /> Reject</div>
              {
                showModal && (
                  <RejectionModal
                    isOpen={true}
                    onRequestClose={closeModal}
                    className='scheduler-modal'
                    overlayClassName='scheduler-modal-overlay'
                    ariaHideApp={false}
                    offerId={offer._id}
                    openOffers= { openOffers} 
                    setOpenOffers= { setOpenOffers }
                    acceptedOffers= { acceptedOffers }
                    setAcceptedOffers= { setAcceptedOffers }
                  />
                )
              }
          </div>
        </div>

      </div>

    </div>
  );
};


const NewOrdersTab = ({ offers , offerAccept,  staff , openOffers , setOpenOffers , acceptedOffers , setAcceptedOffers }) => {
  return (
    <div className="staff-new-orders-tab">

      <div className="staff-orders-container">

        {offers.length >=1 && offers.map(offer => (
          <OrderCard key={offer._id} offer={offer} staff={staff} offerAccept={offerAccept} 
          openOffers= { openOffers} 
          setOpenOffers= { setOpenOffers }
          acceptedOffers= { acceptedOffers }
          setAcceptedOffers= { setAcceptedOffers }   
          />
        ))}
        {
          offers.length === 0 && <h6> No new offers. </h6>
        }
      </div>
    </div>
  );
};

const StatusTabs = () => {

  const [openOffers , setOpenOffers] = useState([]) ;
  const [acceptedOffers , setAcceptedOffers] = useState([]) ;
  const [completedOffers , setCompletedOffers] = useState([]) ;
  const [staff , setStaff] = useState({}) ;

  useEffect(() => {
      getOffers();
  }
  , []);

  const getOffers = async () => {
    const resp= await getProviderOffers();
    const { data } = await getCurrentProvider();
    // setOffers(resp.data.offer) ;
    setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open'} )) ;
    setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted'} )) ;
    setCompletedOffers(resp.data.offer.filter((o) => { return o.status === 'completed'} )) ;
    setStaff({...data}) ;
  }

  const offerAccept = async (offerId) => {
    try {
        const resp = await getAcceptOffer(offerId);
        setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open'} )) ;
        setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted'} )) ;
        // redirect to this link here -  /staffing/staff/dashboard/work-order/accepted
        return <Redirect to="/staffing/staff/dashboard/work-order/accepted" />;
    } catch (error) {
        if (error.response && error.response.status === 400) {
            toast.error(error.response.data);   
        }
      
    } 
  }

  // if ( openOffers.length === 0 ) return <Redirect to="/staffing/staff/dashboard/work-order/accepted" /> ;

  return (
            <>
              <div className="dashboard">
                  <DashboardSidebar />       
                  <>
                      <div className="main-content">
                        <div className="main-content-inner">
                            {/* ===== status tabs ===== */}
                            <div className="status-tabs">
                              <Link to='/staffing/staff/dashboard/work-order/open' style={{color: 'inherit'}}>     
                                <div className={`tab active`}>
                                  <div className="count">{openOffers.length}</div>
                                  <div className="label">New </div>
                                </div>
                              </Link>
                              <Link to='/staffing/staff/dashboard/work-order/accepted' style={{color: 'inherit'}}>                              
                                  <div className={`tab`}>
                                    <div className="count">{acceptedOffers.length}</div>
                                    <div className="label">Accepted</div>
                                  </div>
                              </Link>
                              <Link to='/staffing/staff/dashboard/work-order/completed' style={{color: 'inherit'}}>                              
                                  <div className={`tab`}>
                                    <div className="count">{completedOffers.length}</div>
                                    <div className="label">Completed</div>
                                  </div>
                              </Link>

                            </div>  
                            {/* ====== new orders ==== */}
                              {
                                Object.keys(staff).length > 0 && (
                                  <NewOrdersTab 
                                    offers={ openOffers } 
                                    staff={ staff } 
                                    offerAccept={ offerAccept } 
                                    openOffers= { openOffers} 
                                    setOpenOffers= { setOpenOffers }
                                    acceptedOffers= { acceptedOffers }
                                    setAcceptedOffers= { setAcceptedOffers }

                                  />
                                )
                              }
                        </div>
                      </div>
                  </>
              </div>
            </>
  );
};

export default StatusTabs;
